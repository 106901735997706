import React, { useContext } from "react"
import { Tooltip } from "@mui/material"
import { WarningAmber as WarningAmberIcon } from "@mui/icons-material"

import BasicTooltip from "../items/BasicTooltip"
import BasicSwitch from "../items/Toggle"
import DiscreteSlider from "../items/Slider"
import { AbilityContext } from "../../helper/AbilityContext"

import ActionDrawerStyles from "../sections/Drawer/ActionDrawer.module.css"

const ModelParamsSection = ({
  enableTemperature,
  enableTopK,
  enableTopP,
  temperature,
  topK,
  topP,
  showTopK,
  onChangeSwitch,
  onChangeValue,
}) => {
  const ability = useContext(AbilityContext)

  return (
    <div>
      <div>
        <h4>
          Parameters
          <BasicTooltip tooltip="Toggle off to use model default settings; toggle on to customize with your preferred parameters." />
        </h4>
      </div>
      <div style={{ marginTop: 12 }}>
        <div className={ActionDrawerStyles.flex}>
          <div style={{ alignItems: "center", display: "flex", flex: 1, justifyContent: "space-between" }}>
            <h4>
              <BasicSwitch
                defaultChecked={enableTemperature}
                disabled={!ability.can("update", "Project")}
                handleSave={(value) => {
                  onChangeSwitch("temperature", value)
                }}
              />
              Temperature
              <BasicTooltip tooltip="Higher values will make the output more random, while lower values will make it more focused and deterministic." />
            </h4>
            {enableTemperature && (
              <span style={{ display: "flex", fontSize: "0.8rem", paddingRight: 16 }}>
                {temperature > 1 && (
                  <Tooltip
                    title={`The outcome will become increasingly unpredictable if set beyond "1", and exceptionally difficult to predict if set beyond "1.8".`}
                  >
                    <WarningAmberIcon
                      color="warning"
                      sx={{
                        cursor: "pointer",
                        fontSize: "0.8rem",
                        paddingRight: 0.5,
                      }}
                    />
                  </Tooltip>
                )}
                {temperature}
              </span>
            )}
          </div>
          <div style={{ marginRight: 4 }}>
            {enableTemperature && (
              <DiscreteSlider
                disabled={!ability.can("update", "Project")}
                initialValue={temperature}
                min={0}
                max={2}
                step={0.01}
                handleSave={(value) => {
                  onChangeValue("temperature", value)
                }}
              />
            )}
          </div>
        </div>
        <div className={ActionDrawerStyles.flex}>
          <div style={{ alignItems: "center", display: "flex", flex: 1, justifyContent: "space-between" }}>
            <h4>
              <BasicSwitch
                defaultChecked={enableTopP}
                disabled={!ability.can("update", "Project")}
                handleSave={(value) => {
                  onChangeSwitch("topP", value)
                }}
              />
              Top P
              <BasicTooltip tooltip="Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted options are considered." />
            </h4>
            {enableTopP && <span style={{ display: "flex", fontSize: "0.8rem", paddingRight: 16 }}>{topP}</span>}
          </div>
          <div style={{ marginRight: 4 }}>
            {enableTopP && (
              <DiscreteSlider
                disabled={!ability.can("update", "Project")}
                initialValue={topP}
                min={0.1}
                max={1}
                step={0.1}
                handleSave={(value) => {
                  onChangeValue("topP", value)
                }}
              />
            )}
          </div>
        </div>
        {showTopK && (
          <div className={ActionDrawerStyles.flex}>
            <div style={{ alignItems: "center", display: "flex", flex: 1, justifyContent: "space-between" }}>
              <h4>
                <BasicSwitch
                  defaultChecked={enableTopK}
                  disabled={!ability.can("update", "Project")}
                  handleSave={(value) => {
                    onChangeSwitch("topK", value)
                  }}
                />
                Top K
                <BasicTooltip tooltip="Limits the language model's choices to the top K highest probability words, improving text coherence and relevance." />
              </h4>
              {enableTopK && <span style={{ display: "flex", fontSize: "0.8rem", paddingRight: 16 }}>{topK}</span>}
            </div>
            <div style={{ marginRight: 4 }}>
              {enableTopK && (
                <DiscreteSlider
                  disabled={!ability.can("update", "Project")}
                  initialValue={topK}
                  min={1}
                  max={100}
                  step={1}
                  handleSave={(value) => {
                    onChangeValue("topK", value)
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ModelParamsSection
