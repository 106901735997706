import React from "react"
import { Chip } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"

const CustomChip = ({ label, tagProps }) => {
  return (
    <Chip
      color="primary"
      label={label}
      variant="outlined"
      size="small"
      sx={{
        bgcolor: "#fff",
        fontSize: "0.6rem",
        height: 17,
        "& .MuiChip-deleteIcon": {
          color: "#000",
          opacity: 1,
          fontSize: 10,
        },
      }}
      deleteIcon={<CloseIcon />}
      {...tagProps}
    />
  )
}

export default CustomChip
