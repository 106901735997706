import request from "../axios"

const requests = {
  createPortal: (params) => {
    return request.post("/stripes/create-customer-portal", {}, { params })
  },
  getUserSession: () => {
    return request.post("/stripes/customer-session")
  },
  getUserSubscription: () => {
    return request.get("/stripes/customer-subscription")
  },
}

export default requests
