import React from "react"
import { useNavigate } from "react-router-dom"
import { Drawer, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"

import ActionDrawerStyles from "./ActionDrawer.module.css"

const RelatedProjectDrawer = ({ tableData = [], onCloseDrawer, isOpen = false }) => {
  const navigate = useNavigate()

  const gotoProjectDetail = (appId) => {
    navigate(`/ai-projects/${appId}`)
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={onCloseDrawer}>
      <section className={ActionDrawerStyles.drawer}>
        <header className={ActionDrawerStyles.title}>
          <IconButton onClick={onCloseDrawer}>
            <CloseIcon />
          </IconButton>
          <p>Associated Projects</p>
        </header>
        <div style={{ padding: "1.5rem" }}>
          <TableContainer style={{ boxShadow: "0px 2px 4px -1px rgba(61,61,61,0.6)" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell style={{ width: 200 }}>Last Changed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length > 0 ? (
                  tableData.map((item) => (
                    <TableRow
                      key={item.app_id}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        gotoProjectDetail(item.app_id)
                      }}
                    >
                      <TableCell>{item.name}</TableCell>
                      <TableCell>
                        {new Date(item.last_changed).toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                      <i>No Project</i>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </section>
    </Drawer>
  )
}

export default RelatedProjectDrawer
