// Essential Imports
import React, { useEffect, useRef, useState } from "react"
import Prism from "prismjs"

// Library Imports
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { Snackbar, IconButton } from "@mui/material"

import BasicTooltip from "./BasicTooltip"

import "prismjs/components/prism-sql"

const MakeSnippet = ({
  children,
  canEdit = false,
  onChange = () => {},
  title,
  tooltipText = null,
  language = "markup",
}) => {
  const ref = useRef(null)
  const [clipboardText, setClipboardText] = useState(children)
  const [copySuccess, setCopySuccess] = useState(false)

  useEffect(() => {
    Prism.highlightAllUnder(ref.current, false)
  }, [children])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(clipboardText).then(
      function () {
        setCopySuccess(true)
      },
      function () {},
    )
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setCopySuccess(false)
  }
  const onInput = (event) => {
    setClipboardText(event.currentTarget.innerText)
    onChange(event)
  }

  return (
    <div className="vextCode" ref={ref}>
      <div className="head">
        <span>
          {title}
          {tooltipText ? (
            <span style={{ marginLeft: 4, verticalAlign: "middle" }}>
              <BasicTooltip tooltip={tooltipText} />
            </span>
          ) : null}
        </span>
        <span className="copy" onClick={copyToClipboard}>
          <ContentCopyIcon fontSize="1rem" />
          <span>Copy code</span>
        </span>
      </div>
      <pre className={`language-${language}`}>
        <code style={{ outline: "none" }} contentEditable={canEdit} onInput={onInput} suppressContentEditableWarning>
          {children}
        </code>
      </pre>
      <Snackbar
        open={copySuccess}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Code copied"
        action={<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}></IconButton>}
      />
    </div>
  )
}

export default MakeSnippet
