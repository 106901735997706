import React from "react"

const tableId = process.env.REACT_APP_STRIPE_TABLE
const stripeKey = process.env.REACT_APP_STRIPE_KEY

const PricingPage = ({ secret }) => {
  return (
    <stripe-pricing-table
      pricing-table-id={tableId}
      publishable-key={stripeKey}
      customer-session-client-secret={secret}
    />
  )
}

export default PricingPage
