import React from "react"
import { Bolt as BoltIcon, Info as InfoIcon, PriorityHigh as PriorityHighIcon } from "@mui/icons-material"

const styleMapping = {
  info: {
    borderColor: "#6BA0FF",
    bgColor: "#f5f8fb",
    icon: <InfoIcon />,
  },
  importantInfo: {
    borderColor: "#FF9900",
    bgColor: "#FFF4F2",
    icon: <InfoIcon />,
  },
  bolt: {
    borderColor: "#FF9900",
    bgColor: "#FFF4F2",
    icon: <BoltIcon />,
  },
  error: {
    borderColor: "#F44336",
    bgColor: "#fae7e6",
    icon: <PriorityHighIcon />,
  },
}
const MessageBox = ({ message, type = "info" }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderLeft: `0.3rem solid ${styleMapping[type]?.borderColor || "#757575"}`,
        padding: "1rem",
        borderRadius: "10px",
        backgroundColor: styleMapping[type]?.bgColor || "#f5f5f5",
        marginBottom: "1rem",
      }}
    >
      {styleMapping[type]?.icon || <InfoIcon />}
      <p style={{ display: "inline-block", marginLeft: "0.5rem", textAlign: "left" }}>{message}</p>
    </div>
  )
}

export default MessageBox
