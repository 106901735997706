// Essential Imports
import React, { useState, useEffect, useContext } from "react"

// Component Imports
import { ErrorContext } from "../../helper/AlertContext"
import request from "../../api/axios"
import Chips from "../items/Chips"

// Library Imports
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import Skeleton from "@mui/material/Skeleton"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function DataDetailSharepoint({
  localLoading,
  setLocalLoading,
  setIntegrationFileName,
  setIntegrationFileType,
  setIntegrationSiteId,
  setIntegrationFolderId,
  setIntegrationFileId,
  setIntegrationFilePath,
  setDataValid,
}) {
  const errorContext = useContext(ErrorContext)
  const [userId, setUserId] = useState(null)
  const [authenticating, setAuthenticating] = useState(true)
  const [buttonText, setButtonText] = useState("Connect Microsoft SharePoint")
  const [sites, setSites] = useState([])
  const [drives, setDrives] = useState([])
  const [files, setFiles] = useState([])
  const [pages, setPages] = useState([])
  const [nextPageToken, setNextPageToken] = useState(null)
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [inputSites, setInputSites] = useState(null)
  const [inputDrives, setInputDrives] = useState(null)
  const [inputFiles, setInputFiles] = useState(null)
  const [inputPages, setInputPages] = useState(null)
  const [selectedType, setSelectedType] = useState('page')

  let windowObjectReference = null
  let previousUrl = null

  const mapMimeTypeToDisplayText = (mimeType) => {
    switch (mimeType) {
      case "text/plain":
        return "Text"
      case "text/csv":
        return "CSV"
      case "application/json":
        return "JSON"
      case "application/xml":
        return "XML"
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "XLSX"
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "DOCX"
      case "application/pdf":
        return "PDF"
      default:
        return "Unknown"
    }
  }

  const mapMimeTypeToInternalValue = (mimeType) => {
    switch (mimeType) {
      case "text/plain":
        return "file"
      case "text/csv":
        return "csv"
      case "application/json":
        return "file"
      case "application/xml":
        return "file"
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlsx"
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx"
      case "application/pdf":
        return "pdf"
      default:
        return "Unknown"
    }
  }

  const stateMap = {
    sites: setSites,
    drives: setDrives,
    files: setFiles,
    pages: setPages,
  }

  const fetchSharepointSite = async (
    nextPageToken = null,
    newQuery = "",
    loadType,
    type = "sites",
    siteId = null,
    driveId = null
  ) => {
    const setData = stateMap[type]
    if (loadType === "newLoad") {
      setData([])
      setNextPageToken(null)
    }
    setLocalLoading(true)

    let endpoint = "/third_party/azure/sharepoint/sites/50";

    if (type === "pages" && (siteId || inputSites)) {
      endpoint = `/third_party/azure/sharepoint/sites/${siteId || inputSites.id}/pages`
    } else if (siteId) {
      endpoint += `/${siteId}/drives`
    } else if (inputSites) {
      endpoint += `/${inputSites.id}/drives`
    }

    if (driveId) {
      endpoint += `/${driveId}`
    }

    let params = []
    if (nextPageToken) {
      params.push(`next_page_token=${nextPageToken}`)
    }
    if (params.length > 0) {
      endpoint += `?${params.join("&")}`
    }

    try {
      const response = await request.get(endpoint)
      const data = type === "pages" ? response.data : response.data.text

      const strategies = {
        sites: (data) => {
          const sites = data.value[0].hitsContainers[0].hits
          setSites(
            sites.map((site) => ({
              id: site.resource.id,
              name: site.resource.displayName,
              url: site.resource.webUrl
            })),
          )
        },
        drives: (data) => {
          const drives = data.value
          setDrives(
            drives.map((drive) => ({
              id: drive.id,
              name: drive.name,
              url: drive.webUrl
            })),
          )
        },
        files: (data) => {
          const files = data.value
          setFiles(
            files.map((file) => ({
              id: file.id,
              name: file.name,
              type: file.file.mimeType,
              url: file.webUrl,
            }
            )),
          )
        },
        pages: (data) => {
          const pages = data?.pages
          setPages(
            pages.map((page) => ({
              id: page.id,
              name: page.title || page.name,
              url: page.webUrl,
              description: page.description,
              lastModified: page.lastModifiedDateTime,
              author: page.lastModifiedBy?.user?.displayName
            })),
          )
        },
      }

      if (strategies[type]) {
        strategies[type](data)
      } else {
        throw new Error(`Unsupported type: ${type}`)
      }
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      setLocalLoading(false)
    }
  }  

  // Infinite scroll for Autocomplete
  const handleScroll = (event, type) => {
    if (localLoading) return

    const { scrollTop, clientHeight, scrollHeight } = event.target
    const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight * 0.75
    if (isBottom && nextPageToken) {
      setIsSearchActive(false)
      fetchSharepointSite(nextPageToken, "", "continueLoad", type)
    }
  }

  // Handle search

  // Check if users already authenticated
  useEffect(() => {
    const fetchUserId = request.get(`/account`)
    const fetchOauthStatus = request.get(`/third_party/azure/is_need_azure_oauth`)

    Promise.all([fetchUserId, fetchOauthStatus])
      .then(([userIdResponse, oauthStatusResponse]) => {
        setUserId(userIdResponse.data.id)
        if (!oauthStatusResponse.data.text) {
          setButtonText("Authenticated")
          fetchSharepointSite(null, "", "newLoad")
        }
        setAuthenticating(false)
      })
      .catch((error) => {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      })

    return () => {
      clearTimeout(searchTimeout)
    }
  }, [])

  // Trigger receive message from pop up
  const receiveMessage = async (event) => {
    const { data, origin } = event

    // Verify the source and origin
    if (!data || data.source !== "Vext Authentication" || origin !== window.location.origin) {
      return
    }

    const params = new URLSearchParams(String(data.payload).slice(1))
    const code = params.get("code")
    window.removeEventListener("message", receiveMessage)

    try {
      const response = await request.post(`/third_party/azure/oauth`, {
        code: code,
      })

      if (response.data.text === "ok") {
        setButtonText("Authenticated")
        fetchSharepointSite(null, "", "newLoad")
      }
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    }
  }

  // Start Google authentication process
  const authenticateSharepoint = () => {
    window.removeEventListener("message", receiveMessage)
    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${encodeURIComponent(
        process.env.REACT_APP_MS_APP_ID,
      )}&response_type=code&redirect_uri=${encodeURIComponent(
        `${window.location.origin}/oauth-redirect`,
      )}&response_mode=query&scope=${encodeURIComponent(
        "openid profile User.Read offline_access Sites.Read.All Sites.Selected Files.Read Files.Read.All Files.Read.Selected",
      )}&state=sharepoint`
    const name = "Microsoft Authentication"
    const strWindowFeatures = "toolbar=no, menubar=no, width=980, height=720, top=100, left=100"

    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(authUrl, name, strWindowFeatures)
    } else if (previousUrl !== authUrl) {
      windowObjectReference = window.open(authUrl, name, strWindowFeatures)
      windowObjectReference.focus()
    } else {
      windowObjectReference.focus()
    }

    // add the listener for receiving a message from the popup
    window.addEventListener("message", receiveMessage, false)
    // assign the previous URL
    previousUrl = authUrl
  }


  return (
    <div style={{ display: "flex", width: "100%", gap: "1rem" }}>
      {buttonText === "Authenticated" ? (
        <div style={{ width: "100%" }}>
          {/* Site */}
          <h5>Site Collection</h5>
          <Autocomplete
            value={inputSites}
            options={sites}
            getOptionLabel={(option) => `${option.name || ""}`}
            isOptionEqualToValue={(option, value) => sites.some((site) => site.id === value.id)}
            renderOption={(props, option) => (
              <li {...props} key={`${option.id}`}>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>{option.name}</div>
                    <small 
                      style={{
                        color: "#3d3d3d80",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "22rem",
                      }}
                    >
                      {option.url}
                    </small>
                  </div>
                </div>
              </li>
            )}
            renderInput={(params) => <TextField {...params} placeholder="Select Site..." />}
            sx={{ width: "100%" }}
            ListboxProps={{
              onScroll: (event) => handleScroll(event, "sites"),
            }}
            // onInputChange={handleSearch}
            onChange={(event, value) => {
              if (value) {
                setDrives([])
                setFiles([])
                setPages([])
                setInputDrives(null)
                setInputFiles(null)
                setInputPages(null)
                if (selectedType === 'drive') {
                  fetchSharepointSite(null, "", "newLoad", "drives", value.id, null)
                } else {
                  fetchSharepointSite(null, "", "newLoad", "pages", value.id, null)
                }
                setInputSites(value)
              } else {
                fetchSharepointSite(null, "", "newLoad")
                setInputSites(null)
                setInputDrives(null)
                setInputFiles(null)
                setInputPages(null)
                setDataValid(false)
                setIntegrationSiteId("")
                setIntegrationFileId("")
                setIntegrationFileName("")
              }
            }}
            noOptionsText={
              localLoading ? (
                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                  <Skeleton variant="text" animation="wave" width="100%" height={30} />
                  <Skeleton variant="text" animation="wave" width="100%" height={20} />
                </div>
              ) : (
                "No Result"
              )
            }
          />
          <h5>Type</h5>
          <FormControl>
            <RadioGroup
              row
              value={selectedType}
              onChange={(e) => {
                setSelectedType(e.target.value);
                if (inputSites) {
                  setInputFiles(null);
                  setInputDrives(null);
                  setInputPages(null);
                  if (e.target.value === 'drive') {
                    fetchSharepointSite(null, "", "newLoad", "drives", inputSites.id, null);
                  } else {
                    fetchSharepointSite(null, "", "newLoad", "pages", inputSites.id, null);
                  }
                }
              }}
            >
              <FormControlLabel
                value="page"
                control={<Radio />}
                label="Page"
                disabled={!inputSites || localLoading}
              />
              <FormControlLabel
                value="drive"
                control={<Radio />}
                label="Drive"
                disabled={!inputSites || localLoading}
              />
            </RadioGroup>
          </FormControl>
          {selectedType === 'drive' && (
            <>
              <h5>Drive</h5>
              <Autocomplete
                value={inputDrives}
                options={drives}
                getOptionLabel={(option) => `${option.name || ""}`}
                isOptionEqualToValue={(option, value) => drives.some((drive) => drive.id === value.id)}
                renderOption={(props, option) => (
                  <li {...props} key={`${option.id}`}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>{option.name}</div>
                        <small style={{
                          color: "#3d3d3d80",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "22rem",
                        }}
                        >
                          {option.url}
                        </small>
                      </div>
                    </div>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} placeholder="Select Library..." />}
                sx={{ width: "100%" }}
                ListboxProps={{
                  onScroll: (event) => handleScroll(event, "drives"),
                }}
                // onInputChange={handleSearch}
                onChange={(event, value) => {
                  if (value) {
                    setInputFiles(null)
                    setFiles([])
                    fetchSharepointSite(null, "", "newLoad", "files", null, value.id)
                    setInputDrives(value)
                  } else {
                    setInputDrives(null)
                    setInputFiles(null)
                    setDataValid(false)
                    setIntegrationSiteId("")
                    setIntegrationFileId("")
                    setIntegrationFileName("")
                  }
                }}
                noOptionsText={
                  localLoading ? (
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                      <Skeleton variant="text" animation="wave" width="100%" height={30} />
                      <Skeleton variant="text" animation="wave" width="100%" height={20} />
                    </div>
                  ) : (
                    "No Result"
                  )
                }
                disabled={!inputSites || inputSites.length === 0}
              />
            </>
          )}
          {selectedType === 'drive' && (
            <>
              <h5>File</h5>
              <Autocomplete
                value={inputFiles}
                options={files}
                getOptionLabel={(option) => `${option.name || ""}`}
                isOptionEqualToValue={(option, value) => files.some((file) => file.id === value.id)}
                renderOption={(props, option) => (
                  <li {...props} key={`${option.id}`}>
                  <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div>{option.name}</div>
                      <small style={{
                        color: "#3d3d3d80",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "18rem",
                      }}
                      >
                        {option.id}
                      </small>
                    </div>
                    <Chips
                      customLabel={mapMimeTypeToDisplayText(option.type)}
                      customColor="contrast"
                      style={{ fontSize: "0.6rem", border: "1px solid #3d3d3d80" }}
                    />
                  </div>
                </li>
                )}
                renderInput={(params) => <TextField {...params} placeholder="Select File..." />}
                sx={{ width: "100%" }}
                ListboxProps={{
                  onScroll: (event) => handleScroll(event, "files"),
                }}
                // onInputChange={handleSearch}
                onChange={(event, value) => {
                  if (value) {
                    setInputFiles(value)
                    setDataValid(true)
                    setIntegrationFileName(value.name)
                    setIntegrationSiteId(inputSites.id)
                    setIntegrationFolderId(inputDrives.id)
                    setIntegrationFileId(value.id)
                    setIntegrationFilePath(value.url)
                    setIntegrationFileType(mapMimeTypeToInternalValue(value.type))
                  } else {
                    setDataValid(false)
                    setIntegrationSiteId("")
                    setIntegrationFileId("")
                    setIntegrationFileName("")
                    setInputFiles(null)
                  }
                }}
                noOptionsText={
                  localLoading ? (
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                      <Skeleton variant="text" animation="wave" width="100%" height={30} />
                      <Skeleton variant="text" animation="wave" width="100%" height={20} />
                    </div>
                  ) : (
                    "No Result"
                  )
                }
                disabled={!inputDrives || inputDrives.length === 0}
              />
            </>
          )}
          {selectedType === 'page' && (
            <>
              <h5>Page</h5>
              <Autocomplete
                value={inputPages}
                options={pages}
                getOptionLabel={(option) => `${option.name || ""}`}
                isOptionEqualToValue={(option, value) => pages.some((page) => page.id === value.id)}
                renderOption={(props, option) => (
                  <li {...props} key={`${option.id}`}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>{option.name}</div>
                        <small style={{
                          color: "#3d3d3d80",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "22rem",
                        }}
                        >
                          {option.url}
                        </small>
                      </div>
                    </div>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} placeholder="Select Page..." />}
                sx={{ width: "100%" }}
                ListboxProps={{
                  onScroll: (event) => handleScroll(event, "pages"),
                }}
                onChange={(event, value) => {
                  if (value) {
                    setInputPages(value)
                    setDataValid(true)
                    setIntegrationFileName(value.name)
                    setIntegrationSiteId(inputSites.id)
                    setIntegrationFileId(value.id)
                    setIntegrationFilePath(value.webUrl)
                    setIntegrationFileType('page')
                  } else {
                    setDataValid(false)
                    setIntegrationSiteId("")
                    setIntegrationFileId("")
                    setIntegrationFileName("")
                    setInputPages(null)
                  }
                }}
                noOptionsText={
                  localLoading ? (
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                      <Skeleton variant="text" animation="wave" width="100%" height={30} />
                      <Skeleton variant="text" animation="wave" width="100%" height={20} />
                    </div>
                  ) : (
                    "No Result"
                  )
                }
                disabled={!inputSites}
              />
            </>
          )}

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p
              onClick={() => {
                authenticateSharepoint()
                setIntegrationSiteId("")
                setIntegrationFileId("")
                setIntegrationFileName("")
              }}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                marginTop: "1rem",
                fontSize: "0.7rem",
                justifyContent: "flex-end",
              }}
            >
              Switch Account
            </p>
          </div>
        </div>
      ) : (
        <Button
          variant="contained"
          onClick={authenticateSharepoint}
          disabled={authenticating}
          style={{ width: "100%", marginTop: "1rem" }}
        >
          {authenticating ? <CircularProgress size={24} /> : buttonText}
        </Button>
      )}
    </div>
  )
}