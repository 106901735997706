import request from "../axios"

const requests = {
  checkOauth: (payload) => {
    return request.post("/oauth/auth", payload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
  },
}

export default requests
