import React, { useContext, useEffect, useState } from "react"
import { Button } from "@mui/material"
import { Launch as LaunchIcon } from "@mui/icons-material"
import styled from "@emotion/styled"

import { AccountContext } from "../../helper/AccountContext"
import useStripeSession from "../../helper/StripePortal"
import MessageBox from "./MessageBox"

const PlanContainer = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: baseline;
`

const BillingSection = ({ planName = "", planStartTime = 0, planEndTime = 0 }) => {
  const [planLogo, setPlanLogo] = useState("/images/free_plan.svg")
  const { setSubPrompt } = useContext(AccountContext)
  const { loading, createStripeSession } = useStripeSession()

  useEffect(() => {
    if (["Starter", "Pro", "Enterprise"].includes(planName)) {
      setPlanLogo(`/images/${planName.toLowerCase()}_plan.svg`)
    }
  }, [planName])

  return (
    <>
      <h4>Plan</h4>
      <PlanContainer>
        <div style={{ display: "flex", gap: "1rem", alignItems: "center", marginBottom: "1rem" }}>
          <img style={{ width: "2.5rem", height: "auto" }} src={planLogo} alt={`plan logo`} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ fontWeight: "700", marginBottom: "0.3rem" }}>{planName} Plan</p>
            {planName === "Free" ? null : (
              <>
                <p style={{ fontSize: "0.7rem" }}>
                  {"Start Date: "}
                  {new Date(planStartTime * 1000).toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
                <p style={{ fontSize: "0.7rem" }}>
                  {"End Date: "}
                  {new Date(planEndTime * 1000).toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
              </>
            )}
          </div>
        </div>
        <Button
          style={{ alignSelf: "flex-start" }}
          variant="contained"
          endIcon={!loading ? <LaunchIcon style={{ fontSize: "1rem" }} /> : ""}
          onClick={createStripeSession}
          disabled={loading}
        >
          {!loading ? "Billing & Subscription" : "Redirecting..."}
        </Button>
      </PlanContainer>
      {["Free", "Starter"].includes(planName) && (
        <MessageBox
          type="bolt"
          message={
            <>
              {`Upgrade plan to enable more powerful features for your LLM pipeline. `}
              <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => setSubPrompt(true)}>
                Upgrade plan
              </span>
            </>
          }
        />
      )}
    </>
  )
}

export default BillingSection
