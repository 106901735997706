// Essential Imports
import React, { useContext } from "react"
import { Link } from "react-router-dom"

// Component Imports
import { AuthContext } from "../helper/AuthProvider"

// Library Imports
import Button from "@mui/material/Button"

function NotFound() {
  const { isAuthenticated } = useContext(AuthContext)

  return (
    <div className="not-found">
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      {isAuthenticated ? (
        <Link to="/dashboard">
          <Button variant="contained" sx={{ mt: "1rem" }}>
            Return to Dashboard
          </Button>
        </Link>
      ) : (
        <Link to="/login">
          <Button variant="contained" sx={{ mt: "1rem" }}>
            Return to Login
          </Button>
        </Link>
      )}
    </div>
  )
}

export default NotFound
