import request from "../axios"

const requests = {
  createOrganization: (payload) => {
    return request.post("/organization", payload)
  },
  getOrganizationList: (headers) => {
    return request.get("/organization_list", headers)
  },
  getMemberList: () => {
    return request.get("/organization/users")
  },
  getRoleList: (id) => {
    return request.get(`/roles/organization/${id}`)
  },
  acceptInvitation: (token) => {
    return request.get(`/organization_invitation/${token}`)
  },
  removeMember: (id) => {
    return request.delete(`/organization/users/${id}`)
  },
  sendInvitationEmail: (payload) => {
    return request.post("/send_organization_invitation", payload)
  },
  updateRole: (payload) => {
    return request.put("/organization/users", payload)
  },
}

export default requests
