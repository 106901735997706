// Essential Imports
import React, { useState } from "react"

// Library Imports
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"

export default function InputField({
  autoComplete = "on",
  onChange,
  value,
  mLength,
  id,
  disabled,
  invalid,
  onPressEnter,
  onBlur,
  placeholder,
  type = "text",
  sx,
  onBackspaceOnEmpty,
  index,
  endAdornment,
  inputRef,
}) {
  const [isComposing, setIsComposing] = useState(false)

  const handleChange = (event) => {
    const inputValue = event.target.value
    if (typeof onChange === "function") {
      onChange(inputValue)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !isComposing) {
      // Prevent form submission if it's in a form
      event.preventDefault()
      if (typeof onPressEnter === "function") {
        onPressEnter(event)
      }
    }

    // Handle backspace on empty input without changing other behaviors
    if (event.key === "Backspace" && value === "" && typeof onBackspaceOnEmpty === "function") {
      onBackspaceOnEmpty(index)
    }
  }

  const handleBlur = () => {
    if (typeof onBlur === "function") {
      onBlur()
    }
  }

  return (
    <TextField
      autoComplete={autoComplete}
      id={id}
      inputRef={inputRef}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onCompositionStart={() => setIsComposing(true)}
      onCompositionEnd={() => setIsComposing(false)}
      onBlur={handleBlur}
      disabled={disabled}
      error={invalid}
      inputProps={{ maxLength: mLength }}
      placeholder={placeholder}
      type={type}
      sx={sx}
      InputProps={{
        endAdornment: <>{endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : null}</>,
      }}
    />
  )
}
