// Essential Imports
import React, { useContext, useEffect, useRef } from "react"

// Component Imports
import { WarningContext } from "../../helper/AlertContext"

// Library Imports
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Collapse from "@mui/material/Collapse"
import CloseIcon from "@mui/icons-material/Close"

export default function Warning() {
  const { warning, setWarning, warningMsg } = useContext(WarningContext)

  const timerRef = useRef(null) // Using useRef to hold the timer id

  useEffect(() => {
    // clear the previous timer if it exists
    if (timerRef.current) clearTimeout(timerRef.current)

    // Start a new timer only if warning is true
    if (warning) {
      timerRef.current = setTimeout(() => {
        setWarning(false)
      }, 5000)
    }

    // clear the timer when the component is unmounted
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [warning, setWarning]) // Restart the timer every time the `warning` changes

  return (
    <Box
      sx={{
        width: "50%",
        position: "fixed",
        bottom: 0,
        left: "1rem",
        zIndex: 9999,
      }}
    >
      <Collapse in={warning}>
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setWarning(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {warning && `${warningMsg}`}
        </Alert>
      </Collapse>
    </Box>
  )
}
