// Library Imports
import { createTheme } from "@mui/material/styles"

const Theme = createTheme({
  typography: {
    fontFamily:
      "Inter,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica,Arial,sans-serif",
    fontSize: 12.8,
    allVariants: {
      fontSize: 12.8,
    },
  },
  palette: {
    primary: {
      main: "#3d3d3d",
    },
    action: {
      hover: "#3d3d3d80",
    },
    warning: {
      main: "#FF9900",
      contrastText: "#fff",
    },
    success: {
      main: "#2EB666",
      contrastText: "#fff",
    },
    error: {
      main: "#F44336",
      contrastText: "#fff",
    },
    contrast: {
      main: "#fff",
      contrastText: "#3d3d3d",
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "initial",
          padding: 0,
          "&.Mui-expanded": {
            minHeight: "initial",
          },
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          backgroundColor: "#3d3d3d",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          marginTop: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          // "&.MuiButtonBase-root": {
          //   textAlign: "left",
          //   flexDirection: "row",
          //   justifyCointent: "flex-start",
          // },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "0.45rem 1rem",
          boxShadow: "none",
        },
        contained: {
          border: "1px solid transparent",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#3d3d3d",
          color: "#ffffff",
          maxWidth: 220,
          boxShadow: "0px 2px 4px -1px rgba(61,61,61,0.6)",
          fontSize: "0.8rem",
          padding: "0.5rem 0.8rem",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 2px 4px -1px rgba(61,61,61,0.6)",
          color: "#3d3d3d",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
          borderColor: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          padding: 0,
          width: "100%",
          backgroundColor: "#ffffff",
          "& .MuiInputBase-root": {
            // padding: "0",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#3d3d3d",
            },
            "&:focus-within": {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #3d3d3d",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#3d3d3d80",
            },
          },
          "& .MuiOutlinedInput-root": {
            padding: "0.65rem",
            "& .MuiInputBase-input": {
              padding: 0,
            },
          },
          "& .Mui-disabled": {
            backgroundColor: "#E5E5E5",
            "&:hover": {
              backgroundColor: "#E5E5E5",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: "0",
          width: "100%",
          boxSizing: "border-box",
          resize: "none",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textAlign: "left",
          backgroundColor: "#ffffff",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:focus-within": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #3d3d3d",
            },
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #3d3d3d",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3d3d3d80",
          },
          "& .Mui-disabled": {
            backgroundColor: "#E5E5E5",
            "&:hover": {
              backgroundColor: "#E5E5E5",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3d3d3d80",
              },
            },
          },
        },
        select: {
          padding: "0.65rem",
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          margin: "auto",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          color: "#3d3d3d",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#f1f1f1",
          },
        },
      },
    },
  },
})

export default Theme
