import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { Can } from "@casl/react"
import Button from "@mui/material/Button"

import { AbilityContext } from "../../helper/AbilityContext"

const NavigateButton = () => {
  const ability = useContext(AbilityContext)

  return (
    <Can I="create" a="Project" ability={ability}>
      <Link to="/ai-projects/create">
        <Button variant="contained">Create Project</Button>
      </Link>
    </Can>
  )
}

const EmptyState = ({ children = <NavigateButton /> }) => (
  <div className="empty-state">
    <img src="/images/empty.svg" alt="empty" />
    <h4>Nothing here yet.</h4>
    {children}
  </div>
)

export default EmptyState
