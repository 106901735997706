// Essential Imports
import React from "react"

export const LoadingContext = React.createContext(null)

export function LoadingProvider({ children }) {
  const [isLoading, setIsLoading] = React.useState(null)

  return <LoadingContext.Provider value={{ isLoading, setIsLoading }}>{children}</LoadingContext.Provider>
}
