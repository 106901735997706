// Essential Imports
import React from "react"

export const SuccessContext = React.createContext(null)
export const WarningContext = React.createContext(null)
export const ErrorContext = React.createContext(null)

export function SuccessProvider({ children }) {
  const [success, setSuccess] = React.useState(false)
  const [successMsg, setSuccessMsg] = React.useState("")

  return (
    <SuccessContext.Provider value={{ success, setSuccess, successMsg, setSuccessMsg }}>
      {children}
    </SuccessContext.Provider>
  )
}

export function WarningProvider({ children }) {
  const [warning, setWarning] = React.useState(null)
  const [warningMsg, setWarningMsg] = React.useState("")

  return (
    <WarningContext.Provider value={{ warning, setWarning, warningMsg, setWarningMsg }}>
      {children}
    </WarningContext.Provider>
  )
}

export function ErrorProvider({ children }) {
  const [error, setError] = React.useState(null)
  const [errorMsg, setErrorMsg] = React.useState("")

  return <ErrorContext.Provider value={{ error, setError, errorMsg, setErrorMsg }}>{children}</ErrorContext.Provider>
}
