// Essential Imports
import * as React from "react"
import { useNavigate } from "react-router-dom"

// Library Imports
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import { CardActionArea } from "@mui/material"

export default function Cards({ imgSrc, altText, heading, content, link, external = false }) {
  const navigate = useNavigate()

  const handleClick = () => {
    if (external) {
      window.open(link, "_blank")
    } else {
      navigate(link)
    }
  }

  return (
    <Card sx={{ maxWidth: 345 }} onClick={handleClick}>
      <CardActionArea>
        <CardMedia component="img" height="140" image={imgSrc} alt={altText} />
        <CardContent>
          <h5>{heading}</h5>
          <p>{content}</p>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
