import React, { useContext } from "react"
import { GoogleLogin as GoogleOAuthLogin } from "@react-oauth/google"
import { ErrorContext } from "../../helper/AlertContext"
import { LoadingContext } from "../../helper/LoadingContext"
import { accountService, organizationService } from "../../api/services"

const GoogleLogin = ({ url, finalizeLogin, getCountry }) => {
  const errorContext = useContext(ErrorContext)
  const loadingContext = useContext(LoadingContext)

  // Handle the error message and stop execution
  async function fetchWithErrors(url, options) {
    try {
      const response = await fetch(url, options)

      if (response.status >= 400 && response.status != 406) {
        errorContext.setError(true)
        errorContext.setErrorMsg("Server responded with an error.")
        throw new Error("Server responded with an error.")
      }
      return response
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message || "An error occurred.")
      loadingContext.setIsLoading(false)
      throw error
    }
  }

  // Clicking the google oauth button in the JSX section will initiate google authentication process
  // and will trigger this to acquire and initialize user information to exchange JWT with the server
  const handleGoogleLogin = async (accessToken) => {
    loadingContext.setIsLoading(true)
    const country = await getCountry()

    try {
      const profileResponse = await fetchWithErrors(`https://oauth2.googleapis.com/tokeninfo?id_token=${accessToken}`)
      const profile = await profileResponse.json()
      const user = {
        firstName: profile.given_name,
        lastName: profile.family_name,
        email: profile.email,
        profilePic: profile.picture,
        ipCountry: country,
      }
      const loginPayload = {
        email: user.email,
        password: null,
        account_type: "google",
        token: accessToken,
      }

      let response = await fetch(`${url}/check_account`, {
        method: "POST",
        body: JSON.stringify({ email: user.email }),
        headers: { "Content-Type": "application/json" },
        credentials: "omit",
      })

      if (response.status === 200) {
        // create account via google
        const payload = {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          avatar_url: user.profilePic,
          ip_country: user.ipCountry,
          time_zone: null,
          language: null,
          account_type: "google",
          password: null,
        }

        await accountService.createAccount(payload)
        await accountService.login(loginPayload)
        await organizationService.getOrganizationList()
        finalizeLogin(user)
      } else if (response.status === 406) {
        await accountService.login(loginPayload)
        await organizationService.getOrganizationList()
        finalizeLogin(user)
      } else {
        throw new Error("Unknown error has occurred.")
      }
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
      loadingContext.setIsLoading(false)
    }
  }

  return (
    <GoogleOAuthLogin
      onSuccess={(credentialResponse) => handleGoogleLogin(credentialResponse.credential)}
      onError={(error) => {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
        loadingContext.setIsLoading(false)
      }}
      useOneTap
      locale="en_us"
      cancel_on_tap_outside="false"
    />
  )
}

export default GoogleLogin
