// Essential Imports
import React, { useContext, useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import { Button } from "@mui/material"

// Component Imports
import { AuthContext } from "../helper/AuthProvider"
import { LoadingContext } from "../helper/LoadingContext"
import { ErrorContext } from "../helper/AlertContext"
import InputField from "../components/items/InputField"
import GoogleLoginComponent from "../components/items/GoogleLogin"
import { accountService, organizationService } from "../api/services"
import { isEnterpriseUser } from "../utils"
import { CUSTOM_ERR_MSG } from "../utils/constants"

// Stylesheet Imports
import "../styles/Login.css"

// Get country name using ipapi free api for user profiling
async function getCountry(retries = 3) {
  while (retries > 0) {
    try {
      const response = await fetch("https://ipapi.co/json/")
      if (!response.ok) throw new Error(`HTTP status ${response.status}`)
      const data = await response.json()
      return data.country_name
    } catch (error) {
      console.error(`Failed to fetch country: ${error.message}`)
      retries--
      if (retries === 0) return null
    }
  }
}

export default function Login({ setProfile, url }) {
  const { setAuth } = useContext(AuthContext)
  const errorContext = useContext(ErrorContext)
  const loadingContext = useContext(LoadingContext)
  const [userEmail, setUserEmail] = useState(localStorage.getItem("email") || "")
  const [emailTouched, setEmailTouched] = useState(false)
  const [emailIsValid, setEmailIsValid] = useState(true)
  const [password, setPassword] = useState("")
  const navigate = useNavigate()

  // Email validation
  const emailValidation = (email) => {
    let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return reg.test(email)
  }

  // Adjust handleEmailChange to also validate the email
  const handleEmailChange = (newEmail) => {
    setUserEmail(newEmail)
    localStorage.setItem("email", newEmail)
    setEmailIsValid(emailValidation(newEmail))
  }

  // Validate on blur
  const handleEmailBlur = () => {
    setEmailTouched(true)
  }

  // Handle password input
  const handlePasswordChange = (newPassword) => setPassword(newPassword)

  // If login is successful
  const finalizeLogin = (user) => {
    localStorage.setItem("profile", JSON.stringify(user))
    document.cookie = "userLoggedIn=true;path=/;"
    setProfile(user)
    setAuth(true)

    const oauthUrl = sessionStorage.getItem("oauthUrl")
    if (oauthUrl) {
      navigate(oauthUrl)
      sessionStorage.removeItem("oauthUrl")
    } else {
      navigate("/dashboard")
    }

    loadingContext.setIsLoading(false)
  }

  // Login using email and password
  const handleEmailLogin = async () => {
    const loginData = {
      email: userEmail,
      password: password,
      account_type: "email",
    }
    let modified_error = ""

    loadingContext.setIsLoading(true)
    try {
      await accountService.checkAccount(loginData)
      errorContext.setError(true)
      errorContext.setErrorMsg("No account associated with this email.")
    } catch (error) {
      if (error.status !== 406) {
        modified_error = error.message
      }

      // login process continues
      try {
        if (isEnterpriseUser()) {
          localStorage.setItem("vext_enterprise", isEnterpriseUser())
        }
        await accountService.login(loginData)
        await organizationService.getOrganizationList() // [2024-02-23] to get personal organization id
        const accountInfo = await accountService.getAccount()
        const user = {
          firstName: accountInfo.data.first_name,
          lastName: accountInfo.data.last_name,
          email: accountInfo.data.email,
          profilePic: accountInfo.data.avatar_url,
          ipCountry: accountInfo.data.ip_country,
        }

        finalizeLogin(user)
        localStorage.removeItem("email")
      } catch (error) {
        if (error.response?.data?.text === "account is not activated") {
          modified_error = CUSTOM_ERR_MSG.login_not_activated
        } else if (
          error.response?.data?.text?.non_field_errors?.[0] === "Unable to log in with provided credentials."
        ) {
          modified_error = CUSTOM_ERR_MSG.login_invalid_cred
        } else {
          modified_error = error.message
        }
        errorContext.setError(true)
        errorContext.setErrorMsg(modified_error)
      }
    } finally {
      loadingContext.setIsLoading(false)
    }
  }

  return (
    <div className="portal-container">
      <img className="login-logo" src="/images/vext_logo.png" alt="vext logo" />
      <div className="login-wrap">
        <div className="login-container">
          <p style={{ marginBottom: "1.5rem", fontSize: "1.2rem", fontWeight: "700" }}>Welcome Back</p>
          {!isEnterpriseUser() || isEnterpriseUser() === "test" ? (
            <div className="sso-wrap login">
              <GoogleLoginComponent url={url} finalizeLogin={finalizeLogin} getCountry={getCountry} />
              <div className="login-title">
                <hr />
                <p>or log in with email</p>
              </div>
            </div>
          ) : null}
          <div
            style={{
              width: "100%",
              textAlign: "left",
              marginBottom: "1.5rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div>
              <InputField
                placeholder={"Email"}
                value={userEmail}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                type="email"
                invalid={emailTouched && !emailIsValid}
                disabled={loadingContext.isLoading}
              />
            </div>
            <div>
              <InputField
                placeholder={"Password"}
                value={password}
                onChange={handlePasswordChange}
                onPressEnter={() => {
                  if (userEmail && password && emailIsValid && !loadingContext.isLoading) {
                    handleEmailLogin()
                  }
                }}
                type="password"
                disabled={loadingContext.isLoading}
              />
            </div>
            <Button
              variant="contained"
              style={{ width: "100%" }}
              disabled={!userEmail || !password || !emailIsValid || loadingContext.isLoading}
              onClick={handleEmailLogin}
            >
              {loadingContext.isLoading ? "Loading..." : "Continue"}
            </Button>
          </div>
          <div
            style={{
              fontSize: "0.7rem",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              gap: "0.5rem",
            }}
          >
            {isEnterpriseUser() ? null : (
              <span>
                <Link to="/signup" style={{ marginTop: "1rem", fontSize: "0.7rem", textDecoration: "none" }}>
                  {`Don't have an account yet? Sign up.`}
                </Link>
              </span>
            )}
            <span>
              <Link to="/forgot-password" style={{ marginTop: "1rem", fontSize: "0.7rem", textDecoration: "none" }}>
                Forgot your password?
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
