// Library Imports
import React, { useEffect, useState } from "react"
import { FormControl, InputLabel, MenuItem, Select, Chip, Box, Checkbox } from "@mui/material"

export default function SelectField({ options = [], value, disabled, onChange, sx, label, divStyle }) {

  const handleChange = (event) => {
    const {target: { value }} = event
    onChange(value)
  }

  return (
    <div style={divStyle}>
      <FormControl variant="outlined" disabled={disabled}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value.map((item) => item.name)}
          onChange={handleChange}
          sx={sx}
          label={label}
          multiple
          displayEmpty={true}
          renderValue={(value) => {
            if (value && value.length > 0) {
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {value.map((value, index) => (
                    <Chip key={index} label={value} />
                  ))}
                </Box>
              )
            } else {
              return <em>Select...</em>
            }
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.name} disabled={option.disabled}>
              <Checkbox checked={value.indexOf(option) > -1} />
              <span>{option.name}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
