import * as React from "react"

function Label({ type }) {
  const labels = {
    new: {
      text: "NEW",
      backgroundColor: "#FF9900",
      color: "#fff",
      marginLeft: "0.2rem",
    },
    beta: {
      text: "BETA",
      backgroundColor: "#3d3d3d",
      color: "#fff",
      marginLeft: "0.2rem",
    },
    comingsoon: {
      text: "COMING SOON",
      backgroundColor: "#3d3d3d",
      color: "#fff",
      marginLeft: "0.2rem",
    },
  }

  const currentLabel = labels[type]

  if (!currentLabel) return null

  const style = {
    backgroundColor: currentLabel.backgroundColor,
    color: currentLabel.color,
    fontSize: "0.6rem",
    padding: "0.1rem 0.3rem",
    borderRadius: "4px",
    ...(currentLabel.marginLeft && { marginLeft: currentLabel.marginLeft }),
  }

  return <span style={style}>{currentLabel.text}</span>
}

export default Label
