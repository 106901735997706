// Essential Imports
import React, { useLayoutEffect, useRef, useState } from "react"

// Library Imports
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"

export default function TextArea({
  onChange,
  onSelect = () => {},
  value,
  mLength,
  id,
  disabled = false,
  onPressEnter,
  onBlur,
  style,
  placeholder,
  rows,
  error,
  inputComponent = "input",
  hideEndAdornment = false,
  startAdornment = <></>,
}) {
  const [replacedValue, setReplacedValue] = useState("")
  const [isComposing, setIsComposing] = useState(false)
  const inputRef = useRef(null)
  const position = useRef({
    beforeStart: 0,
    beforeEnd: 0,
  })

  const handleSelect = () => {
    onSelect(inputRef?.current.selectionStart, inputRef?.current.selectionEnd)
  }
  const handleChange = (event) => {
    const inputValue = event.target.value
    const replacedInputValue = inputValue.replace(/[\u4e00-\u9fff]/g, "ff") // match unicode

    if (typeof onChange === "function" && replacedInputValue.length <= mLength) {
      onChange(inputValue)
      setReplacedValue(replacedInputValue)
    }
    position.current = {
      beforeStart: event.target.selectionStart,
      beforeEnd: event.target.selectionEnd,
    }
  }

  const handleBlur = () => {
    if (typeof onBlur === "function") {
      onBlur()
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !isComposing) {
      if (!event.shiftKey) {
        // Enter pressed without Shift, trigger onPressEnter
        event.preventDefault()
        if (typeof onPressEnter === "function") {
          onPressEnter()
        }
      }
    }
  }

  useLayoutEffect(() => {
    const replacedInputValue = value.replace(/[\u4e00-\u9fff]/g, "ff") // match unicode

    setReplacedValue(replacedInputValue)
    inputRef?.current.setSelectionRange(position.current.beforeStart, position.current.beforeEnd)
  }, [value])

  return (
    <TextField
      multiline
      inputRef={inputRef}
      id={id}
      disabled={disabled}
      value={value}
      inputProps={{ maxLength: mLength }}
      onChange={handleChange}
      onCompositionStart={() => setIsComposing(true)}
      onCompositionEnd={() => setIsComposing(false)}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onSelect={handleSelect}
      style={style}
      placeholder={placeholder}
      error={error}
      InputProps={{
        rows,
        inputComponent,
        startAdornment,
        endAdornment: (
          <>
            {!hideEndAdornment && (
              <InputAdornment position="end">
                {replacedValue.length} / {mLength}
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  )
}
