import React, { useContext, useEffect, useRef } from "react"

// Component Imports
import { ErrorContext } from "../../helper/AlertContext"
import { CUSTOM_ERR_MSG } from "../../utils/constants"

// Library Imports
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Collapse from "@mui/material/Collapse"
import CloseIcon from "@mui/icons-material/Close"

export default function Error() {
  const { error, setError, errorMsg } = useContext(ErrorContext)

  const timerRef = useRef(null) // Using useRef to hold the timer id

  useEffect(() => {
    // Clear the previous timer if it exists
    if (timerRef.current) clearTimeout(timerRef.current)

    // Start a new timer only if error is true
    if (error) {
      timerRef.current = setTimeout(() => {
        setError(false)
      }, 5000)
    }

    // Clear the timer when the component is unmounted
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [error, setError]) // Restart the timer every time the `error` changes

  // Custom error message for specific error types
  const getErrorMessage = () => {
    if (errorMsg === "Request failed with status code 403") {
      return `Error: ${CUSTOM_ERR_MSG.general_forbidden}`
    }
    return `Error: ${errorMsg}`
  }

  return (
    <Box
      sx={{
        width: "50%",
        position: "fixed",
        bottom: 0,
        left: "1rem",
        zIndex: 9999,
      }}
    >
      <Collapse in={error}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {getErrorMessage()}
        </Alert>
      </Collapse>
    </Box>
  )
}
