import React, { useEffect, useState } from "react"
import Switch from "@mui/material/Switch"

export default function BasicSwitch({ disabled, handleSave, defaultChecked = false }) {
  const [isChecked, setIsChecked] = useState(defaultChecked)

  const handleToggle = (event) => {
    const newState = event.target.checked
    setIsChecked(newState)
    if (handleSave) {
      handleSave(newState)
    }
  }
  useEffect(() => {
    setIsChecked(defaultChecked)
  }, [defaultChecked])

  return (
    <div>
      <Switch checked={isChecked} disabled={disabled} onChange={handleToggle} />
    </div>
  )
}
