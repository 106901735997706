import React, { useState } from "react"
import dayjs from "dayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

export default function DateRange({ startDate, setStartDate, endDate, setEndDate }) {
  const [timerStart, setTimerStart] = useState(null)
  const [timerEnd, setTimerEnd] = useState(null)
  const today = dayjs()

  const disableDates = (date) => {
    const targetDate = dayjs(date)
    return targetDate.isAfter(today)
  }

  const updateStartDate = (newValue) => {
    if (timerStart) {
      clearTimeout(timerStart)
    }
    setTimerStart(
      setTimeout(() => {
        setStartDate(newValue)
        const potentialEnd = dayjs(newValue).add(7, "day")
        if (potentialEnd.isBefore(endDate)) {
          setEndDate(potentialEnd)
        }
      }, 2000),
    )
  }

  const updateEndDate = (newValue) => {
    if (timerEnd) {
      clearTimeout(timerEnd)
    }
    setTimerEnd(
      setTimeout(() => {
        setEndDate(newValue)
        const potentialStart = dayjs(newValue).subtract(7, "day")
        if (potentialStart.isAfter(startDate)) {
          setStartDate(potentialStart)
        }
      }, 500),
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ display: "flex", gap: "1rem" }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={updateStartDate}
            maxDate={endDate} // endDate as the maxDate
            shouldDisableDate={disableDates}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={updateEndDate}
            minDate={startDate} // startDate as the minDate
            maxDate={today}
            shouldDisableDate={disableDates}
          />
        </div>
      </div>
    </LocalizationProvider>
  )
}
