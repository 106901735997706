// Essential Imports
import * as React from "react"
import { useContext } from "react"

// Component Imports
import { LoadingContext } from "../../helper/LoadingContext"

// Library Imports
import Box from "@mui/material/Box"
import LinearProgress from "@mui/material/LinearProgress"

export default function GlobalLoadingBar() {
  const { isLoading } = useContext(LoadingContext)

  return isLoading ? (
    <Box sx={{ width: "100vw", display: "block", position: "absolute", zIndex: "9", top: 0, left: 0 }}>
      <LinearProgress />
    </Box>
  ) : null
}
