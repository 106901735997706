export const ROLE_PERMISSIONS = {
  owner: [
    { action: "create", subject: "Workspace" },
    { action: "manage", subject: "Member" },
    { action: "share", subject: "Project" },
    { action: "read", subject: "Project" },
    { action: "create", subject: "Project" },
    { action: "update", subject: "Project" },
    { action: "delete", subject: "Project" },
    { action: "share", subject: "Dataset" },
    { action: "read", subject: "Dataset" },
    { action: "create", subject: "Dataset" },
    { action: "update", subject: "Dataset" },
    { action: "delete", subject: "Dataset" },
    { action: "create", subject: "DataSource" },
    { action: "delete", subject: "DataSource" },
    { action: "read", subject: "DataSourceOriginalFile" },
    { action: "create", subject: "ApiKey" },
    { action: "delete", subject: "ApiKey" },
  ],
  admin: [
    { action: "manage", subject: "Member" },
    { action: "share", subject: "Project" },
    { action: "read", subject: "Project" },
    { action: "create", subject: "Project" },
    { action: "update", subject: "Project" },
    { action: "delete", subject: "Project" },
    { action: "share", subject: "Dataset" },
    { action: "read", subject: "Dataset" },
    { action: "create", subject: "Dataset" },
    { action: "update", subject: "Dataset" },
    { action: "delete", subject: "Dataset" },
    { action: "create", subject: "DataSource" },
    { action: "delete", subject: "DataSource" },
    { action: "read", subject: "DataSourceOriginalFile" },
    { action: "create", subject: "ApiKey" },
    { action: "delete", subject: "ApiKey" },
  ],
  editor: [
    { action: "share", subject: "Project" },
    { action: "read", subject: "Project" },
    { action: "create", subject: "Project" },
    { action: "update", subject: "Project" },
    { action: "share", subject: "Dataset" },
    { action: "read", subject: "Dataset" },
    { action: "create", subject: "Dataset" },
    { action: "update", subject: "Dataset" },
    { action: "create", subject: "DataSource" },
    { action: "delete", subject: "DataSource" },
    { action: "read", subject: "DataSourceOriginalFile" },
    { action: "create", subject: "ApiKey" },
  ],
  read: [
    { action: "read", subject: "Project" },
    { action: "read", subject: "Dataset" },
  ],
}
