import React, { useContext } from "react"
import { TablePagination } from "@mui/material"

import { LoadingContext } from "../../helper/LoadingContext"
import { ErrorContext } from "../../helper/AlertContext"

export default function CustomTablePagination({ page, setPage, setData, dataCount = 10, callFunction }) {
  const loadingContext = useContext(LoadingContext)
  const errorContext = useContext(ErrorContext)

  const handleChangePage = async (_, newPage) => {
    if (callFunction) {
      loadingContext.setIsLoading(true)
      try {
        const { results } = await callFunction({ page: newPage + 1 })

        setData(results)
        setPage(newPage + 1)
      } catch (error) {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      } finally {
        loadingContext.setIsLoading(false)
      }
    } else {
      setPage(newPage)
    }
  }

  return (
    <TablePagination
      rowsPerPageOptions={[10]}
      component="div"
      count={dataCount}
      rowsPerPage={10}
      page={page - 1}
      onPageChange={handleChangePage}
    />
  )
}
