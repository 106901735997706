// Essential Imports
import React from "react"
import { Link as RouterLink } from "react-router-dom"

// Library Imports
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"

const breadcrumbStyle = {
  display: "block",
  maxWidth: "15rem",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}

const Breadcrumb = ({ breadcrumbArray = [] }) => (
  <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
    {breadcrumbArray.map((breadcrumb, index) => {
      const isLastItem = index === breadcrumbArray.length - 1
      const breadcrumbItem = isLastItem ? (
        <Typography color="textPrimary" key={breadcrumb.name} style={breadcrumbStyle}>
          {breadcrumb.name}
        </Typography>
      ) : (
        <Link color="inherit" component={RouterLink} to={breadcrumb.link} key={breadcrumb.name} style={breadcrumbStyle}>
          {breadcrumb.name}
        </Link>
      )

      return breadcrumbItem
    })}
  </Breadcrumbs>
)

export default Breadcrumb
