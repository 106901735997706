import React, { useEffect, useRef } from "react"
import Prism from "prismjs"
import "prismjs/components/prism-json"

const JsonSnippet = ({ children, canEdit = false }) => {
  const ref = useRef(null)

  useEffect(() => {
    Prism.highlightAllUnder(ref.current, false)
    if (canEdit) {
      const codeElement = ref.current.querySelector("code")
      const observer = new MutationObserver(() => {
        Prism.highlightElement(codeElement, false)
      })
      observer.observe(codeElement, { childList: true, subtree: true })
      return () => observer.disconnect()
    }
  }, [children, canEdit])



  return (
    <div
      className="vextCode"
      ref={ref}
      style={{ marginTop: "0.5rem", marginBottom: 0, overflow: "hidden", maxWidth: "100%" }}
    >
      <pre className={`language-json`} style={{margin: 0}}>
        <code
          className={`language-json`}
          contentEditable={canEdit}
          role={canEdit ? "textbox" : undefined}
          aria-multiline={canEdit ? "true" : undefined}
        >
          {children}
        </code>
      </pre>
    </div>
  )
}

export default JsonSnippet
