import React from "react"
import { getAppVersionText } from "../../utils"

// Stylesheet Imports
import "../../styles/Footer.css"

function Footer() {
  const year = new Date().getFullYear()

  return (
    <footer>
      <p style={{ fontSize: "0.7rem" }}>© {year} Vext Technologies. {getAppVersionText()}</p>
    </footer>
  )
}

export default Footer
