// Essential Imports
import React, { useState, useEffect, useContext } from "react"
import { useParams } from "react-router-dom"

// Component Imports
import { LoadingContext } from "../helper/LoadingContext"
import { ErrorContext } from "../helper/AlertContext"
import { TitleContext } from "../helper/TitleContext"
import fetchRequest from "../api/fetch"

// Stylesheet Imports
import "../styles/DataSourceDetail.css"

export default function SiteDetail() {
  const loadingContext = useContext(LoadingContext)
  const errorContext = useContext(ErrorContext)
  const { setTitle } = useContext(TitleContext)
  const { dataframeId } = useParams()
  const [content, setContent] = useState("")

  const getSuffix = (fullString, separator) => {
    const index = fullString.indexOf(separator)
    return fullString.slice(index)
  }

  // Load the content to the body
  // Load the content to the body
  useEffect(() => {
    loadingContext.setIsLoading(true)

    // Extract the site info
    const contextData = JSON.parse(sessionStorage.getItem("site"))
    const sitePart = getSuffix(contextData.context.content, "html/")
    const page = contextData.context.context
    setTitle("Page: " + `${page}`)

    // This is your provided function, moved here
    function processData(response) {
      let binary = ""
      const bytes = new Uint8Array(response)
      bytes.forEach((byte) => (binary += String.fromCharCode(byte)))
      const base64String = window.btoa(binary)
      const resultObject = JSON.parse(atob(base64String))
      const decodedString = resultObject.text
      return decodedString
    }

    const fetchSite = async () => {
      try {
        const getPageResponse = await fetchRequest(`/upload?file_url=${sitePart}`, {
          method: "POST",
          credentials: "include",
        })

        const arrayBufferData = await getPageResponse.arrayBuffer()

        // Use processData to decode ArrayBuffer data
        const final = processData(arrayBufferData)
        setContent(final)
      } catch (error) {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      }
      loadingContext.setIsLoading(false)
    }
    fetchSite()
  }, [dataframeId, setTitle])

  return (
    <div className="dataSourceItem">
      <textarea className="vextSourceResult" value={content} disabled />
    </div>
  )
}
