import React, { useState } from "react"
import { Button, Dialog, DialogContent, DialogActions } from "@mui/material"

import TextArea from "./TextArea"

const InputDialog = ({
  title = "",
  inputTitle = "",
  inputMaxLength = 30,
  isLoading = false,
  isOpen = false,
  onClose,
  onSubmit,
  onLoadSubmitButtonText = "",
  submitButtonText = "",
  hideEndAdornment = false,
}) => {
  const [name, setName] = useState("")
  const handleOnSubmit = () => {
    if (name) {
      onSubmit({ name: name })
    }
  }
  const handleOnClose = () => {
    setName("")
    onClose()
  }

  return (
    <Dialog onClose={handleOnClose} open={isOpen}>
      <div style={{ padding: "2rem", display: "flex", gap: "2rem", flexDirection: "column", textAlign: "center" }}>
        <DialogContent sx={{ display: "flex", flexDirection: "column", width: 400, gap: "1.5rem", padding: 0 }}>
          <h3>{title}</h3>
          <div>
            <h4 style={{ marginTop: 0 }}>{inputTitle}</h4>
            <TextArea
              onChange={setName}
              onPressEnter={handleOnSubmit}
              value={name}
              mLength={inputMaxLength}
              hideEndAdornment={hideEndAdornment}
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ m: "auto" }}>
          <Button variant="outlined" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleOnSubmit} disabled={!name || isLoading}>
            {isLoading ? onLoadSubmitButtonText : submitButtonText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default InputDialog
