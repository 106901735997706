import React, { useContext } from "react"
import { AccountContext } from "../../helper/AccountContext"

const statusMapping = {
  starter: { label: "STARTER" },
  pro: { label: "PRO" },
  ent: { label: "ENTERPRISE" },
}

export default function PlanChip({ plan = "" }) {
  const { isFreePlan, isStarterPlan, isEntPlan } = useContext(AccountContext)
  const currentStatus = statusMapping[plan]

  return (
    <>
      {currentStatus && (isFreePlan || isStarterPlan) ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.1rem 0.4rem",
            backgroundColor: "#3d3d3d",
            color: "#fff",
            marginLeft: "0.5rem",
            borderRadius: "4px",
            fontSize: "0.6rem",
          }}
        >
          {currentStatus.label}
        </div>
      ) : currentStatus && currentStatus.label === "ENTERPRISE" && !isEntPlan ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.1rem 0.4rem",
            backgroundColor: "#3d3d3d",
            color: "#fff",
            marginLeft: "0.5rem",
            borderRadius: "4px",
            fontSize: "0.6rem",
          }}
        >
          {currentStatus.label}
        </div>
      ) : null}
    </>
  )
}
