// Library Imports
import React, { useState, useEffect, useContext } from "react"
import { useParams } from "react-router-dom"
import { Button } from "@mui/material"

// Component Imports
import { LoadingContext } from "../helper/LoadingContext"
import { SuccessContext, ErrorContext } from "../helper/AlertContext"
import { TitleContext } from "../helper/TitleContext"
import RelatedProjectDrawer from "../components/sections/Drawer/RelatedProjectDrawer"
import FileDecoder from "../components/items/FileDecoder"
import LocalLoadingBar from "../components/items/LocalLoadingBar"
import BackdropComponent from "../components/items/BackdropComponent"
import SelectField from "../components/items/SelectField"
import ConfirmationDialog from "../components/items/ConfirmationDialog"
import Label from "../components/items/Label"
import DataDetailTable from "../components/children/DataDetailTable"
import DataDetailText from "../components/children/DataDetailText"
import DataDetailFile from "../components/children/DataDetailFile"
import DataDetailCrawler from "../components/children/DataDetailCrawler"
import DataDetailGoogleDrive from "../components/children/DataDetailGoogleDrive"
import DataDetailConfluence from "../components/children/DataDetailConfluence"
import DataDetailNotion from "../components/children/DataDetailNotion"
import DataDetailSharepoint from "../components/children/DataDetailSharepoint"
import DataDetailMedia from "../components/children/DataDetailMedia"
import request from "../api/axios"
import { datasetService } from "../api/services"
import { createSource } from "../utils"

// Stylesheet Imports
import "../styles/Items.css"

export default function DataDetail({ url }) {
  const { setIsLoading } = useContext(LoadingContext)
  const successContext = useContext(SuccessContext)
  const errorContext = useContext(ErrorContext)
  const { setTitle } = useContext(TitleContext)
  const { dataframeId } = useParams()

  const [rows, setRows] = useState([])
  const [selected, setSelected] = useState([])
  const [dataValid, setDataValid] = useState(false)
  const [dataType, setDataType] = useState("")
  const [dataWorkspace, setDataWorkspace] = useState({})
  const [popupLoading, setPopupLoading] = useState(false)
  const [selectedSource, setSelectedSource] = useState(null)
  const [sourceUrl, setSourceUrl] = useState("")
  const [urlValid, setUrlValid] = useState()
  const [selectedFile, setSelectedFile] = useState([])
  const [dataframeName, setDataframeName] = useState("")
  const [fileName, setFileName] = useState([])
  const [crawlerType, setCrawlerType] = useState(null)
  const [checkedStates, setCheckedStates] = useState({})
  const [uploadedFile, setUploadedFile] = useState(null)
  const [open, setOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [textTitle, setTextTitle] = useState("")
  const [textData, setTextData] = useState("")
  const [exception, setException] = useState(false)

  // Google, Confluence, SharePoint, Notion
  const [integrationFileName, setIntegrationFileName] = useState("")
  const [integrationFileId, setIntegrationFileId] = useState("")
  const [integrationFilePath, setIntegrationFilePath] = useState("")
  const [integrationSiteId, setIntegrationSiteId] = useState("")
  const [integrationFolderId, setIntegrationFolderId] = useState("")
  const [integrationFileType, setIntegrationFileType] = useState("")

  const [isAdding, setIsAdding] = useState(false)
  const [isLocalLoading, setIsLocalLoading] = useState(false)

  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [relatedProjects, setRelatedProjects] = useState([])

  const sourceComponents = {
    google: DataDetailGoogleDrive,
    confluence: DataDetailConfluence,
    notion: DataDetailNotion,
    sharepoint: DataDetailSharepoint,
  }

  const additionalProps = {
    google: { setIntegrationFileType },
    confluence: { setIntegrationSiteId },
    notion: {},
    sharepoint: { setIntegrationSiteId, setIntegrationFolderId, setIntegrationFileType, setIntegrationFilePath },
  }

  const commonProps = {
    url,
    localLoading: popupLoading,
    setLocalLoading: setPopupLoading,
    setIntegrationFileName: setIntegrationFileName,
    setIntegrationFileId: setIntegrationFileId,
    setDataValid: setDataValid,
  }

  const renderSourceDetailComponent = (selectedSource, commonProps) => {
    const SourceDetailComponent = sourceComponents[selectedSource]
    const specificProps = additionalProps[selectedSource] || {}

    if (!SourceDetailComponent) {
      return null
    }

    return <SourceDetailComponent {...commonProps} {...specificProps} />
  }

  const sourceOptions = [
    { value: "text", label: "Plain Text", disabled: false },
    { value: "upload", label: "Upload File", disabled: false },
    { value: "crawler_webpage", label: "Page Crawler", disabled: false },
    {
      value: "crawler_website",
      label: (
        <>
          Site Crawler <Label type="comingsoon" />
        </>
      ),
      disabled: true,
    },
    {
      value: "media",
      label: (
        <>
          Media Link
          <Label type="beta" />
        </>
      ),
      disabled: false,
    },
    { value: "confluence", label: <>Confluence</>, disabled: false },
    { value: "google", label: <>Google Drive</>, disabled: false },
    {
      value: "sharepoint",
      label: (
        <>
          Microsoft SharePoint <Label type="beta" />
        </>
      ),
      disabled: false,
    },
    {
      value: "notion",
      label: (
        <>
          Notion <Label type="beta" />
        </>
      ),
      disabled: false,
    },
  ]

  // Function that helps extract file name
  const getFilename = (urlString) => {
    try {
      const url = new URL(urlString)
      let path = url.pathname
      path = decodeURIComponent(path)
      let segments = path.split("/")
      return segments[segments.length - 1]
    } catch (_) {
      return urlString
    }
  }

  // Get data frame detail
  const fetchDataframe = async () => {
    setIsLoading(true)
    setTitle("")
    try {
      let sources = []
      const { data } = await datasetService.getDataset(dataframeId)

      setDataWorkspace(data.organization)
      setRelatedProjects(data.app)
      setTitle(data.name)
      setDataframeName(data.name)
      // Need to set dataframe name to display on the breadcrumb
      sessionStorage.setItem("dataName", data.name)
      if (data.sources?.length) {
        sources = data.sources.map((source) => {
          const detail = source.detail
          return {
            id: source.id,
            source: source.source,
            name: source.name,
            context:
              source.source === "file" || source.source === "text" || source.source === "csv"
                ? getFilename(detail.context)
                : detail.context,
            context_unfiltered: detail.context,
            datafreshness: detail.datafreshness,
            status: source.status,
            scrapy_website_paths: source.scrapy_website_paths,
            is_schedule_job: source.is_schedule_job,
            update_time: source.updated_time,
            fail_reason: source.fail_reason,
            is_public: source.is_public,
            raw_path: detail.raw_path,
          }
        })
        setDataType("import")
      } else if (data.database && data.database.length > 0) {
        sources = data.database.map((database) => {
          return {
            id: database.id,
            source: "database",
            uri: database.database_uri,
            type: database.type,
            name: database.name,
            update_time: database.update_time,
          }
        })
        setDataType("database")
      }
      setRows(sources)
      const fetchedCheckedStates = sources.reduce((acc, source) => {
        acc[source.id] = source.is_public
        return acc
      }, {})
      setCheckedStates(fetchedCheckedStates)
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle source change
  const handleSourceChange = (event) => {
    setSourceUrl("")
    setException(false)
    setDataValid(false)
    setUploadedFile(null)
    setTextTitle("")
    setTextData("")
    setSelectedFile([])
    setFileName([])
    const newSource = event.target.value
    const newSelectedSource = sourceOptions.find((option) => option.value === newSource)

    if (!newSelectedSource) {
      return
    }

    setSelectedSource(newSource)

    if (newSource === "crawler_webpage" || newSource === "crawler_website") {
      setCrawlerType(newSource)
    }
  }

  // // Handle file selection
  const handleFileSelection = (event) => {
    if (event.target.files.length > 0) {
      const maxFiles = 5
      const filesArray = Array.from(event.target.files)
      if (filesArray.length > maxFiles) {
        errorContext.setError(true)
        errorContext.setErrorMsg(`You can only select up to ${maxFiles} files.`)
        return
      }
      setFileName(filesArray.map((file) => file.name))
      setSelectedFile(event.target.files)
    } else {
      setSelectedFile([])
      setFileName([])
    }
    // setIsUploadComplete(false);
    setDataValid(false)
  }

  // Handle success if the FileDecoder.js decode and upload successful
  const handleUploadSuccess = (uploadedData) => {
    // Check if uploadedData and fileName exist
    if (uploadedData && uploadedData.fileName) {
      setUploadedFile(uploadedData)
      // setIsUploadComplete(true);
      setDataValid(true)
      setPopupLoading(false)
      if (uploadedData.autoAdd === true) {
        handleCreateSource(uploadedData)
      }
    }
  }

  // Handle failure if the FileDecoder.js decode and upload failed
  const handleUploadFailure = (error) => {
    errorContext.setError(true)
    if (error.response?.status === 413) {
      errorContext.setErrorMsg("File too large.")
    } else {
      errorContext.setErrorMsg(error.response?.data?.text || error.message)
    }
    setPopupLoading(false)
  }

  // Handle open "add source" backdrop
  const handleOpen = () => {
    setOpen(true)
    setSelectedSource(null)
    setSelectedFile(null)
    setSourceUrl("")
    setCrawlerType(null)
    setUrlValid()
    setFileName(null)
    setTextTitle("")
    setTextData("")
    setDataValid(false)
  }

  // Handle close "add source" backdrop
  const handleClose = async () => {
    setOpen(false)
  }

  // Confirm delete
  const handleConfirmDelete = () => {
    setDeleteDialogOpen(true)
  }

  // Cancel delete
  const handleCancelDelete = () => {
    setDeleteDialogOpen(false)
  }

  const handleCreateSource = async (uploadedFile) => {
    setIsAdding(true)
    setPopupLoading(true)

    let source

    try {
      source = createSource(
        selectedSource,
        dataframeId,
        integrationFileName,
        integrationFileType,
        integrationFileId,
        integrationSiteId,
        integrationFolderId,
        integrationFilePath,
        uploadedFile,
        sourceUrl,
      )
      await datasetService.createSource(source)
      successContext.setSuccess(true)
      successContext.setSuccessMsg("Source added successfully.")
      setIsAdding(false)
      fetchDataframe()
      setOpen(false)
      setPopupLoading(false)
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
      setPopupLoading(false)
      setIsAdding(false)
    }
  }

  const toggleDrawer = () => {
    setIsOpenDialog(!isOpenDialog)
  }

  // Handle delete
  const deleteSelectedItems = async () => {
    setIsLocalLoading(true)
    try {
      if (dataType === "import") {
        await Promise.all(selected.map((id) => datasetService.removeSource(id)))
      } else if (dataType === "database") {
        await Promise.all(selected.map((id) => request.delete(`/databaseinfo/${id}}`)))
        setDataType("")
      }
      const remainingRows = rows.filter((row) => !selected.includes(row.id))
      setRows(remainingRows)
      setSelected([])
      setDeleteDialogOpen(false)
      successContext.setSuccess(true)
      successContext.setSuccessMsg("Data source successfully deleted.")
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      fetchDataframe()
      setIsLocalLoading(false)
    }
  }

  // Get all data sources via the parent dataframe
  useEffect(() => {
    fetchDataframe()
  }, [dataframeId])

  return (
    <div>
      {/* Table */}
      <DataDetailTable
        fetchDataframe={fetchDataframe}
        dataframeId={dataframeId}
        dataframeName={dataframeName}
        handleConfirmDelete={handleConfirmDelete}
        toggleDrawer={toggleDrawer}
        handleOpen={handleOpen}
        dataType={dataType}
        checkedStates={checkedStates}
        setCheckedStates={setCheckedStates}
        selected={selected}
        setSelected={setSelected}
        rows={rows}
        workspace={dataWorkspace}
      />

      {/* Add source pop up */}
      <BackdropComponent open={open}>
        <div>
          <LocalLoadingBar localLoading={popupLoading} />
          <h3>Add Source</h3>
          <h4>Data Source</h4>
          <SelectField options={sourceOptions} value={selectedSource || ""} onChange={handleSourceChange} />
        </div>

        {/* Text upload */}
        {selectedSource === "text" && (
          <DataDetailText
            textTitle={textTitle}
            setTextTitle={setTextTitle}
            textData={textData}
            setTextData={setTextData}
          />
        )}

        {/* File upload */}
        {selectedSource === "upload" && (
          <DataDetailFile
            fileName={fileName}
            selectedFile={selectedFile}
            dataValid={dataValid}
            localLoading={popupLoading}
            exception={exception}
            handleFileSelection={handleFileSelection}
            setLocalLoading={setPopupLoading}
            handleUploadSuccess={handleUploadSuccess}
            handleUploadFailure={handleUploadFailure}
            url={url}
          />
        )}

        {/* Crawler */}
        {(crawlerType === "crawler_webpage" || crawlerType === "crawler_website") &&
          selectedSource !== "upload" &&
          selectedSource !== "text" &&
          selectedSource !== "google" &&
          selectedSource !== "confluence" &&
          selectedSource !== "sharepoint" &&
          selectedSource !== "notion" &&
          selectedSource !== "media" && (
            <DataDetailCrawler
              sourceUrl={sourceUrl}
              setSourceUrl={setSourceUrl}
              setDataValid={setDataValid}
              setUrlValid={setUrlValid}
              urlValid={urlValid}
              dataValid={dataValid}
              setPopupLoading={setPopupLoading}
            />
          )}

        {/* Media / Video Link */}
        {selectedSource === "media" && (
          <DataDetailMedia
            sourceUrl={sourceUrl}
            setSourceUrl={setSourceUrl}
            setUrlValid={setUrlValid}
            urlValid={urlValid}
            setDataValid={setDataValid}
            dataValid={dataValid}
            setPopupLoading={setPopupLoading}
          />
        )}

        {/* Integration Data Source*/}
        {(selectedSource === "google" ||
          selectedSource === "confluence" ||
          selectedSource === "notion" ||
          selectedSource === "sharepoint") && <>{renderSourceDetailComponent(selectedSource, commonProps)}</>}

        <p style={{ display: "block", textAlign: "left", marginTop: "1rem", fontSize: "0.6rem" }}>
          {`At Vext, data security is critical; we encrypt all imported data for maximum safety. By importing data,
              you consent to its use for `}
          <a
            href="https://vextapp.com/privacy"
            target="_blank"
            rel="noreferrer noopener"
            style={{ cursor: "pointer", textDecoration: "underline", fontSize: "0.6rem" }}
          >
            LLM functionalities within the Service.
          </a>
        </p>
        {/* Action Buttons */}
        <div className="apiAction">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          {/* Except text and file */}
          {selectedSource !== "text" && selectedSource !== "upload" && (
            <Button
              variant="contained"
              onClick={() => handleCreateSource(uploadedFile)}
              disabled={isAdding || !dataValid || popupLoading}
            >
              {isAdding ? "Processing..." : "Add Source"}
            </Button>
          )}
          {/* Only text and file */}
          {(selectedSource === "text" || selectedSource === "upload") && (
            <FileDecoder
              selectedSource={selectedSource}
              exception={exception}
              textTitle={textTitle}
              textData={textData}
              selectedFile={selectedFile}
              fileName={fileName}
              localLoading={popupLoading}
              isAdding={isAdding}
              setLocalLoading={setPopupLoading}
              onUploadSuccess={handleUploadSuccess}
              onUploadFailure={handleUploadFailure}
              url={url}
            />
          )}
        </div>
      </BackdropComponent>
      <RelatedProjectDrawer tableData={relatedProjects} isOpen={isOpenDialog} onCloseDrawer={toggleDrawer} />
      <ConfirmationDialog
        open={deleteDialogOpen}
        handlePrimary={deleteSelectedItems}
        handleSecondary={handleCancelDelete}
        title="Confirm Delete"
        content="Are you sure you want to delete? This action cannot be undone."
        primaryButtonText={isLocalLoading ? "Deleting..." : "Delete"}
        primaryButtonColor="error"
        primaryButtonDisabled={isLocalLoading}
      />
    </div>
  )
}
