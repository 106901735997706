// Essential Imports
import React from "react"

// Stylesheet Imports
import "../styles/Login.css"

export default function SignupSuccess() {
  return (
    <div className="login-wrap">
      <img className="login-logo" src="/images/vext_logo.png" alt="vext logo" />
      <div className="login-container">
        <div
          style={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <p style={{ margin: "auto", fontSize: "1.2rem", fontWeight: "700" }}>Please Verify Your Email</p>
          <p>
            {`You're almost there! We sent an email to your inbox. Click on the link in that email to complete sign up.`}
          </p>
          <p>{`If you don't see it, you may need to check your spam folder.`}</p>
        </div>
      </div>
    </div>
  )
}
