import React, { useCallback, useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Button, InputAdornment, Skeleton, TextField } from "@mui/material"
import { Search as SearchIcon } from "@mui/icons-material"
import { Can } from "@casl/react"
import { debounce } from "lodash"

import { LoadingContext } from "../helper/LoadingContext"
import { ErrorContext } from "../helper/AlertContext"
import { AbilityContext } from "../helper/AbilityContext"
import DataItem from "../components/items/DataItem"
import CustomPagination from "../components/items/Pagination"
import EmptyState from "../components/items/EmptyState"
import InputDialog from "../components/items/InputDialog"
import { datasetService } from "../api/services"

import "../styles/Create.css"

const PER_PAGE = 11

function determineStatus(dataFrame) {
  // check if sources is defined and is an array
  if (dataFrame.sources && Array.isArray(dataFrame.sources)) {
    const sourceStatuses = dataFrame.sources.map((source) => source.status)
    if (sourceStatuses.every((status) => status === "r")) {
      return "r"
    } else if (sourceStatuses.every((status) => status === "f")) {
      return "f"
    } else if (sourceStatuses.every((status) => status === "p")) {
      return "p"
    } else {
      return "u"
    }
  }
  // if sources is undefined, not an array, or none of the above conditions are met
  return "unknown"
}

export default function Data() {
  const [dataFrames, setDataFrames] = useState([])
  const [dataResults, setDataResults] = useState([])
  const [isCreatingData, setIsCreatingData] = useState(false)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [page, setPage] = useState(1)
  const [searchInput, setSearchInput] = useState("")
  const loadingContext = useContext(LoadingContext)
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const ability = useContext(AbilityContext)
  const navigate = useNavigate()

  // Get all dataframe onload, and desc sort by date
  const getData = async (params) => {
    loadingContext.setIsLoading(true)
    try {
      const response = await datasetService.getDatasetList({ per_page: PER_PAGE, ...params })
      const sortedDataframes = response.data.results
        .map((dataFrames) => {
          const created_time = parseInt(dataFrames.created_time)
          const updated_time = parseInt(dataFrames.updated_time)
          return { ...dataFrames, created_time, updated_time }
        })
        .sort((a, b) => b.updated_time - a.updated_time)

      setDataFrames(response.data)
      setDataResults(sortedDataframes)
      setIsDataLoaded(true)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      loadingContext.setIsLoading(false)
    }
  }
  const handleCreateDataset = async (payload) => {
    setIsCreatingData(true)
    try {
      const { data } = await datasetService.createDataset(payload)
      navigate("/data/" + data.id)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsCreatingData(false)
    }
  }
  const handleSearchQuery = async (event) => {
    const query = event.target.value
    debouncedGetData({ query })
  }
  const debouncedGetData = useCallback(
    debounce((params) => {
      setSearchInput(params.query)
      setPage(1)
      getData({ ...params, page: 1 })
    }, 500),
    [],
  )
  const toggleDialog = () => {
    setIsOpenDialog(!isOpenDialog)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div>
      {dataResults.length > 0 || searchInput ? (
        <div style={{ marginBottom: "1rem", textAlign: "right" }}>
          <TextField
            autoComplete="off"
            onChange={handleSearchQuery}
            placeholder="Search Project"
            sx={{ width: "18rem" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      ) : null}
      {loadingContext.isLoading ? (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
          <Skeleton variant="rounded" animation="wave" height={180} sx={{ width: "100%" }} />
          <Skeleton variant="rounded" animation="wave" height={180} sx={{ width: "100%" }} />
          <Skeleton variant="rounded" animation="wave" height={180} sx={{ width: "100%" }} />
        </div>
      ) : dataResults.length > 0 || searchInput ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "1rem",
              marginBottom: "2rem",
            }}
          >
            <Can I="create" a="Dataset" ability={ability}>
              <div className="responsiveListCard">
                <div className="addData" id="addButton">
                  {/* <p>+ Add a Data Set</p> */}
                  <Button variant="outlined" sx={{ backgroundColor: "#fff" }} onClick={toggleDialog}>
                    Add a Data Set
                  </Button>
                </div>
              </div>
            </Can>
            {dataResults.map((dataFrame) => {
              const sourceStatus = determineStatus(dataFrame)
              return (
                <div className="responsiveListCard" key={dataFrame.id}>
                  <DataItem dataFrame={dataFrame} fetchDataList={getData} status={sourceStatus} />
                </div>
              )
            })}
          </div>
          <CustomPagination
            page={page}
            setPage={setPage}
            count={Math.ceil(dataFrames.count / PER_PAGE) || 1}
            setData={setDataFrames}
            callFunction={(params) => getData({ ...params, query: searchInput })}
          />
        </>
      ) : (
        isDataLoaded && (
          <EmptyState>
            <Can I="create" a="Dataset" ability={ability}>
              <Button onClick={toggleDialog} variant="contained" disabled={isCreatingData} data-testid="CreateDataset">
                Create Dataset
              </Button>
            </Can>
          </EmptyState>
        )
      )}
      <InputDialog
        title="Create Data Set"
        inputTitle="Data Set Name"
        isLoading={isCreatingData}
        isOpen={isOpenDialog}
        onClose={toggleDialog}
        onSubmit={handleCreateDataset}
        onLoadSubmitButtonText="Creating..."
        submitButtonText="Create"
      />
    </div>
  )
}
