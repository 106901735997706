// Essential Imports
import React from "react"

// Library Imports
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"

export default function TestActionStatus({ msg, status }) {

  return (
    <Box
      // sx={{
      //   width: "50%",
      //   position: "fixed",
      //   bottom: 0,
      //   left: "1rem",
      // }}
    >
      <Alert
        severity={status}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
          >
          </IconButton>
        }
        sx={{ mb: 2, textAlign: "left" }}
      >
        {msg}
      </Alert>
    </Box>
  )
}
