// Essential Imports
import * as React from "react"

// Library Imports
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import LinearProgress from "@mui/material/LinearProgress"

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 2,
  borderRadius: 5,
}))

export default function LocalLoadingBar({ localLoading, setLocalLoading }) {
  return localLoading ? (
    <Box
      sx={{ width: "100%", top: "0", left: "0", display: "block", position: "absolute", zIndex: "10", borderRadius: 5 }}
    >
      <BorderLinearProgress />
    </Box>
  ) : null
}
