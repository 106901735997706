import React, { useContext } from "react"
import { Box, Button } from "@mui/material"
import { Bolt as BoltIcon } from "@mui/icons-material"

import { AccountContext } from "../../helper/AccountContext"
import useStripeSession from "../../helper/StripePortal"
import BackdropComponent from "./BackdropComponent"
import PricingPage from "./StripeTable"

function PlanSelection({ open, requiredPlan }) {
  const { accountInfo, setSubPrompt, secret } = useContext(AccountContext)
  const { loading, createStripeSession } = useStripeSession()
  const handleClose = async () => {
    setSubPrompt(false)
  }

  const isFreeUser = accountInfo.subscriptionPlanRaw === "free"
  const isEnterprise = requiredPlan === "ent"

  const renderContent = () => {
    // Enterprise plan request
    if (isEnterprise) {
      return (
        <>
          <h3>Contact Us</h3>
          <p style={{ justifyContent: "center" }}>
            This model is only available on the Enterprise plan. Please contact us for more information.
          </p>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              variant="contained"
              onClick={() => {
                window.open('https://docs.google.com/forms/d/e/1FAIpQLScFz4wGr4Xv-YSi84HZcnQLQcWubaJSvc5HtYB0vGNLvjCtQA/viewform', '_blank');
                handleClose();
              }}
            >
              Contact Us
            </Button>
          </Box>
        </>
      )
    }

    // Free user viewing pricing
    if (isFreeUser) {
      return (
        <>
          <PricingPage secret={secret} />
          <Box sx={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}>
            <Button onClick={handleClose}>Cancel</Button>
          </Box>
        </>
      )
    }

    // Paid user upgrading
    return (
      <>
        <h3>Upgrade Plan</h3>
        <p style={{ justifyContent: "center" }}>Please upgrade your plan via the billing portal.</p>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={async () => {
              await createStripeSession("update")
              handleClose()
            }}
            disabled={loading}
            startIcon={!loading ? <BoltIcon style={{ fontSize: "1rem" }} /> : ""}
          >
            {!loading ? "Upgrade" : "Redirecting..."}
          </Button>
        </Box>
      </>
    )
  }

  return (
    <BackdropComponent open={open} width={"fit-content"}>
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          pl: "2rem",
          pr: "2rem",
          width: isFreeUser && !isEnterprise ? "60rem" : "20rem",
          textAlign: "center",
          gap: "1rem",
        }}
      >
        {renderContent()}
      </Box>
    </BackdropComponent>
  )
}

export default PlanSelection
