import React from "react"

// Library Imports
import Backdrop from "@mui/material/Backdrop"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

function ConfirmationDialog({
  open,
  handlePrimary,
  handleSecondary,
  title,
  content,
  primaryButtonText,
  secondaryButtonText = "Cancel",
  primaryButtonVariant = "outlined",
  secondaryButtonVariant = "contained",
  primaryButtonColor = "primary",
  secondaryButtonColor = "primary",
  primaryButtonDisabled = false,
}) {
  return (
    <Backdrop open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: "2rem",
          width: "20rem",
          textAlign: "center",
          gap: "2rem",
        }}
      >
        <h3>{title}</h3>
        <div style={{ fontSize: "0.8rem" }}>{content}</div>
        <div style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}>
          <Button variant={secondaryButtonVariant} color={secondaryButtonColor} onClick={handleSecondary}>
            {secondaryButtonText}
          </Button>
          <Button
            variant={primaryButtonVariant}
            color={primaryButtonColor}
            onClick={handlePrimary}
            disabled={primaryButtonDisabled}
          >
            {primaryButtonText}
          </Button>
        </div>
      </Box>
    </Backdrop>
  )
}

export default ConfirmationDialog
