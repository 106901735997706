import React, { useContext, useEffect, useState } from "react"
import { Button, Stack, Skeleton } from "@mui/material"

import LocalLoadingBar from "../../items/LocalLoadingBar"
import { appService } from "../../../api/services"
import request from "../../../api/axios"
import { SuccessContext, ErrorContext } from "../../../helper/AlertContext"
import SelectField from "../../items/SelectField"
import MultiSelectField from "../../items/MultiSelectField"

import ActionDrawerStyles from "./ActionDrawer.module.css"

const SlackConfigDrawer = () => {
  const errorContext = useContext(ErrorContext)
  const successContext = useContext(SuccessContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingForm, setIsLoadingForm] = useState(false)
  const [apps, setApps] = useState([])
  const [slackTeam, setSlackTeam] = useState({})
  const [slackEmail, setSlackEmail] = useState("")
  const [slackChannels, setSlackChannels] = useState([])
  const [selectedProject, setSelectedProject] = useState("")
  const [selectedChannels, setSelectedChannels] = useState([])
  const [initialChannel, setInitialChannel] = useState([])
  const [initialProject, setInitialProject] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      let channelOptions = []
      try {
        const appResponse = await appService.getProjectList()
        setApps(appResponse.data.results)

        const userResponse = await request.get("third_party/slack/me", {})
        setSlackTeam(userResponse.data.text.profile.team)
        setSlackEmail(userResponse.data.text.profile.email)

        const channelResponse = await request.get("third_party/slack/channels", {})
        if (channelResponse.data && channelResponse.data.text.channels) {
          channelOptions = channelResponse.data.text.channels.map((channel) => ({
            id: channel.id,
            name: channel.name,
            value: channel.name,
          }))
          setSlackChannels(channelOptions)
        } else {
          setSlackChannels([])
        }

        const bindingResponse = await request.get("third_party/slack/binding_data", {})
        if (bindingResponse.data && bindingResponse.data.text) {
          const selectedChannelOptions = bindingResponse.data.text
            .map((binding) => {
              return channelOptions.find((option) => option.id === binding.channel_id)
            })
            .filter((option) => option !== null)
          setSelectedChannels(selectedChannelOptions)
          setInitialChannel(selectedChannelOptions)

          const firstBinding = bindingResponse.data.text[0]
          if (firstBinding && appResponse.data.results.some((app) => app.id === firstBinding.app_id)) {
            setSelectedProject(firstBinding.app_id)
            setInitialProject(firstBinding.app_id)
          } else if (appResponse.data.results.length > 0) {
            setSelectedProject("")
          }
        }

      } catch (error) {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleChangeProject = (event) => {
    setSelectedProject(event.target.value)
  }

  const handleChangeChannel = (selection) => {
    const selectedOptions = slackChannels.filter((channel) => selection.includes(channel.value))
    setSelectedChannels(selectedOptions)
  }

  const handleSaveClick = async () => {
    setIsLoadingForm(true)
    const channelIds = selectedChannels.map((channel) => channel.id)

    try {
      const addBotPromises = channelIds.map((channelId) => request.post(`third_party/slack/add/bot/${channelId}`))
      const addBotResponses = await Promise.all(addBotPromises)
      
      const payload = {
        channel_ids: channelIds,
        app_id: selectedProject,
        slack_account_email: slackEmail,
        slack_team_id: slackTeam.id,
        slack_team_name: slackTeam.name,
      }
      const bindingResponse = await request.post("third_party/slack/binding_data", payload)
      setInitialChannel(selectedChannels)
      setInitialProject(selectedProject)

      successContext.setSuccess(true)
      successContext.setSuccessMsg("Your AI Project has been successfully assigned to your Slack channels.")
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      setIsLoadingForm(false)
    }
  }

  const canSave = () => {
    const currentChannelValues = selectedChannels.map((channel) => channel.id).sort()
    const initialChannelValues = initialChannel.map((channel) => channel.id).sort()

    const channelsChanged = JSON.stringify(currentChannelValues) !== JSON.stringify(initialChannelValues)
    const projectChanged = selectedProject !== initialProject

    return (
      selectedChannels.length > 0 && 
      selectedProject && 
      slackEmail && 
      !isLoading && 
      !isLoadingForm && 
      (channelsChanged || projectChanged)
    )
  }
  
  return (
    <>
      <section style={{ position: "relative" }}>
        <LocalLoadingBar localLoading={isLoading || isLoadingForm} />
      </section>
      {isLoading ? (
        <>
          <Stack spacing={2} sx={{ padding: "1.5rem 2rem" }}>
            <Skeleton variant="rounded" animation="wave" height={50} />
            <Skeleton variant="rounded" animation="wave" height={50} />
            <Skeleton variant="rounded" animation="wave" height={50} />
          </Stack>
        </>
      ) : (
        <>
          <Button
            sx={{ position: "absolute", top: "1.25rem", right: "1.5rem", zIndex: 2 }}
            variant="contained"
            disabled={!canSave()}
            onClick={handleSaveClick}
          >
            Save
          </Button>
          <section style={{ position: "relative" }}>
            <LocalLoadingBar localLoading={isLoading || isLoadingForm} />
          </section>
          <div className={ActionDrawerStyles.main}>
            <div className={ActionDrawerStyles.flex}>
              <h4>Slack Workspace</h4>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                <span style={{ fontWeight: "700", fontStyle: "italic" }}>{slackTeam.name}</span>
                <span style={{ color: "#3d3d3d80", fontStyle: "italic", fontSize: "0.8rem" }}>
                  {slackTeam.domain + ".slack.com"}
                </span>
              </div>
            </div>
            <div className={ActionDrawerStyles.flex}>
              <h4>Slack Channel</h4>
              <MultiSelectField
                options={slackChannels}
                value={selectedChannels}
                onChange={handleChangeChannel}
                multiple={true}
                disabled={isLoading || isLoadingForm}
              />
            </div>
            <div className={ActionDrawerStyles.flex}>
              <h4>Vext AI Project</h4>
              <div style={{ display: "flex", gap: "1rem" }}>
                <SelectField
                  options={apps.map((app) => ({
                    value: app.id,
                    label: app.name,
                  }))}
                  value={selectedProject}
                  divStyle={{ width: "100%" }}
                  onChange={handleChangeProject}
                  disabled={isLoading || isLoadingForm || selectedChannels.length === 0}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default SlackConfigDrawer