import React, { useEffect, useState } from "react"
import { TextField } from "@mui/material"

import BasicTooltip from "../items/BasicTooltip"

const CreateOrgMemberForm = ({ onChange = () => {} }) => {
  const [userEmail, setUserEmail] = useState("")

  useEffect(() => {
    onChange({ user_emails: userEmail })
  }, [userEmail])

  return (
    <div>
      <h4 style={{ marginTop: 0 }}>
        Email(s)
        <BasicTooltip tooltip="Invite multiple members at the same time by separating email addresses with a comma." />
      </h4>
      <TextField
        value={userEmail}
        placeholder="e.g. hello@email.com, world@email.com"
        onChange={(event) => setUserEmail(event.target.value)}
      />
    </div>
  )
}

export default CreateOrgMemberForm
