import React, { useEffect } from "react"

const OAuthRedirect = () => {
  useEffect(() => {
    // get the URL parameters which will include the auth token
    const params = window.location.search
    if (window.opener) {
      // send them to the opening window
      window.opener.postMessage({ source: "Vext Authentication", payload: params })
      // close the popup
      window.close()
    }
  })

  return <p>Please wait...</p>
}

export default OAuthRedirect
