import * as React from "react"
import { Chip, Stack, CircularProgress } from "@mui/material"

const statusMapping = {
  p: { label: "In Progress", color: "warning" },
  r: { label: "Ready", color: "success" },
  f: { label: "Failed", color: "error" },
  w: { label: "Warning", color: "warning" },
  u: { label: "Usable", color: "warning" },
  pro: { label: "Pro", color: "primary" },
  enabled: { label: "Enabled", color: "success" },
  beta: { label: "Beta", color: "primary" },
}

export default function Chips({ status, icon, style, customLabel, customColor }) {
  let currentStatus

  if (customLabel && customColor) {
    currentStatus = { label: customLabel, color: customColor }
  } else if (statusMapping[status]) {
    currentStatus = statusMapping[status]
  } else {
    currentStatus = { label: status, color: "primary" }
  }

  return (
    <Stack direction="row" spacing={1} sx={{ fontSize: "0.8rem" }}>
      <Chip
        label={currentStatus.label}
        color={currentStatus.color}
        size="small"
        icon={icon ? icon : status === "p" ? <CircularProgress color="inherit" size="0.8rem" thickness={8} /> : null}
        style={style}
      />
    </Stack>
  )
}
