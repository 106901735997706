import React, { useState } from "react"
import { Button, TextField } from "@mui/material"
import { UploadFile as UploadFileIcon } from "@mui/icons-material"

const UploadFileDialog = ({ isLoading = false, onFetchUrl = () => {}, onUpload = () => {} }) => {
  const [url, setUrl] = useState("")

  return (
    <div
      style={{
        backgroundColor: "#fff",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
        boxSizing: "border-box",
        padding: "0.5rem",
        position: "absolute",
        bottom: 16,
        left: 16,
        width: 300,
        zIndex: 1,
      }}
    >
      <div style={{ textAlign: "center" }}>
        <div style={{ display: "flex", gap: 8 }}>
          <TextField
            value={url}
            inputProps={{ maxLength: 200 }}
            onChange={(event) => {
              setUrl(event.target.value)
            }}
            placeholder="Paste File Link Here"
          />
          <Button variant="contained" color="primary" onClick={() => onFetchUrl(url)}>
            Fetch
          </Button>
        </div>
        <i style={{ color: "#9e9e9e", display: "block", marginTop: 8 }}>OR</i>
        <input
          id="file"
          type="file"
          onChange={onUpload}
          accept=".txt, .csv, .xlsx, .png, .jpeg, .webp"
          multiple
          hidden
        />
        <label htmlFor="file" style={{ display: "block", width: "100%" }}>
          <Button
            component="div"
            startIcon={<UploadFileIcon />}
            sx={{ padding: "0.5rem", width: "100%" }}
            disabled={isLoading}
          >
            <span style={{ textTransform: "capitalize" }}>{isLoading ? "Uploading..." : "Upload From Computer"}</span>
          </Button>
        </label>
      </div>
    </div>
  )
}

export default UploadFileDialog
