import React, { useEffect, useRef } from "react"
import Prism from "prismjs"
import "prismjs/components/prism-sql"

const BasicSnippet = ({ children, canEdit = false, language = "markup" }) => {
  const ref = useRef(null)

  useEffect(() => {
    Prism.highlightAllUnder(ref.current, false)
  }, [children])

  const codeStyle = {
    display: "block",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    overflowWrap: "break-word",
    maxHeight: "100%",
    overflowY: "auto",
    outline: "none",
  }

  return (
    <div className="vextCode" ref={ref} style={{ overflow: "hidden", maxWidth: "100%" }}>
      <pre className={`language-${language}`} style={{ marginBottom: 0 }}>
        <code style={codeStyle} contentEditable={canEdit}>
          {children}
        </code>
      </pre>
    </div>
  )
}

export default BasicSnippet
