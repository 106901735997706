// Library Imports
import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import unidecode from "unidecode"
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Skeleton,
  Switch,
  Snackbar,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import {
  Autorenew as AutorenewIcon,
  InfoOutlined as InfoOutlinedIcon,
  Refresh as RefreshIcon,
  ContentCopy as ContentCopyIcon,
  Launch as LaunchIcon,
  LockOutlined as LockIcon,
  PeopleOutline as PeopleIcon,
  FindInPageOutlined as FindInPageOutlinedIcon,
} from "@mui/icons-material"
import { Can } from "@casl/react"

import { AccountContext } from "../../helper/AccountContext"
import { SuccessContext, ErrorContext, WarningContext } from "../../helper/AlertContext"
import { LoadingContext } from "../../helper/LoadingContext"
import { AbilityContext } from "../../helper/AbilityContext"
import SelectField from "../items/SelectField"
import CustomTablePagination from "../items/TablePagination"
import Chips from "../items/Chips"
import ConfirmationDialog from "../items/ConfirmationDialog"
import LocalLoadingBar from "../items/LocalLoadingBar"
import BasicTooltip from "../items/BasicTooltip"
import ShareResourceForm from "../children/ShareResourceForm"
import request from "../../api/axios"
import { datasetService } from "../../api/services"
import { DATA_SOURCES, ALLOW_SYNC_SOURCES, FRESHNESS_OPTIONS } from "../../utils/constants"

// Stylesheet Imports
import DataDetailStyles from "../../styles/DataDetail.module.css"

const SourceNameTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
})

export default function DataDetailTable({
  fetchDataframe,
  dataframeId,
  dataframeName,
  handleConfirmDelete,
  toggleDrawer,
  handleOpen,
  dataType,
  checkedStates,
  setCheckedStates,
  selected,
  setSelected,
  rows,
  workspace = {},
}) {
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { setSuccess, setSuccessMsg } = useContext(SuccessContext)
  const { setWarning, setWarningMsg } = useContext(WarningContext)
  const { isLoading } = useContext(LoadingContext)
  const { fetchAccountStatus, setSubPrompt } = useContext(AccountContext)
  const ability = useContext(AbilityContext)
  const [page, setPage] = useState(0)
  const [canShareDataset, setCanShareDataset] = useState(false)
  const [createButtonEnabled, setCreateButtonEnabled] = useState(false)
  const [buttonStatus, setButtonStatus] = useState("")
  const [freshnessLimit, setFreshnessLimit] = useState(24)
  const [copySuccess, setCopySuccess] = useState(false)
  const [dialogState, setDialogState] = useState({ show: false, type: "" })
  const [shareFormData, setShareFormData] = useState({})
  const [shareDatasetPayload, setShareDatasetPayload] = useState(null)
  const [tableLoading, setTableLoading] = useState(false)
  const [isActionAreaLoading, setIsActionAreaLoading] = useState(true)
  const [isDialogLoading, setIsDialogLoading] = useState(false)
  const [isShared, setIsShared] = useState(false)
  const navigate = useNavigate()
  const dataName = sessionStorage.getItem("dataName") ? `“${sessionStorage.getItem("dataName")}”` : "Dataset"
  const formatName = (name) => name.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, "_")

  const viewSource = (contextData) => {
    navigate(`/data/${dataframeId}/${contextData.sanitizedName}`, {
      state: {
        contextData,
        dataframeName: dataframeName,
        dataSourceName: contextData.name,
      },
    })
  }

  const handleSyncStateChange = async (event, sourceId) => {
    const datafreshness = event.target.value

    if (datafreshness < freshnessLimit && datafreshness > 0) {
      setSubPrompt(true)
    } else {
      const newSelectedFreshness = FRESHNESS_OPTIONS.find((option) => option.value === datafreshness)

      if (newSelectedFreshness) {
        setTableLoading(true)
        try {
          await datasetService.updateSource(sourceId, {
            detail: { datafreshness },
          })
          fetchDataframe()
          setSuccess(true)
          setSuccessMsg("Auto-sync updated.")
        } catch (error) {
          setError(true)
          setErrorMsg(error.message)
        } finally {
          setTableLoading(false)
        }
      }
    }
  }

  const handleTogglePublic = async (event, sourceId) => {
    const isActive = event.target.checked
    setCheckedStates((prev) => ({ ...prev, [sourceId]: isActive }))
    setTableLoading(true)
    try {
      await datasetService.updateSource(sourceId, { is_public: isActive })
      fetchDataframe()
      setSuccess(true)
      setSuccessMsg(`Data source file is now ${isActive ? "accessible with the link" : "inaccessible with the link"}.`)
    } catch (error) {
      setCheckedStates((prev) => ({ ...prev, [sourceId]: !isActive }))
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setTableLoading(false)
    }
  }

  const handleLaunchIconClick = (url) => {
    window.open(url, "_blank")
  }

  const handleCopyIconClick = async (url) => {
    navigator.clipboard.writeText(url)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  const handleRowClick = (event, row) => {
    if (event.nativeEvent.target.type === "checkbox") return

    const { status, source, name, id, context_unfiltered, scrapy_website_paths } = row

    if (status === "p") {
      setWarning(true)
      setWarningMsg("Data sync in progress, please try again later.")
      return
    }

    if (status === "f") {
      setError(true)
      setErrorMsg("Data unavailable.")
      return
    }

    if (status === "r") {
      const sanitizedName = formatName(unidecode(name))
      const baseContextData = { id, name, sanitizedName }
      let contextData

      if (["file", "text", "csv", "google", "confluence", "notion", "sharepoint", "media"].includes(source)) {
        contextData = {
          ...baseContextData,
          context: context_unfiltered,
          type: source,
          source: "import",
        }
      } else if (["cp", "cs"].includes(source)) {
        contextData = { ...baseContextData, context: scrapy_website_paths, type: source, source: "import" }
      } else if (source === "database") {
        contextData = {
          ...baseContextData,
          type: "database",
          source: "database",
        }
      }

      if (contextData) {
        viewSource(contextData)
      }
    }
  }

  const handleSyncClick = async (event, source_id) => {
    event.stopPropagation()
    try {
      const response = await request.post(`/source_background_jobs/${source_id}/${dataframeId}`, {})
      if (response.status === 200) {
        setSuccess(true)
        setSuccessMsg("Data source sync request sent.")
        fetchDataframe()
      } else {
        setError(true)
        setErrorMsg("Something went wrong.")
      }
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    }
  }

  const handleCloseCandyBar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setCopySuccess(false)
  }

  const handleShareDataset = async () => {
    try {
      setIsDialogLoading(true)
      await datasetService.grantDatasetAccess2Users(dataframeId, shareDatasetPayload)
      setSuccess(true)
      setSuccessMsg("User added to the dataset.")
      fetchDataframe()
      toggleDialog()
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsDialogLoading(false)
    }
  }
  const handleGetAccessUsers = async () => {
    setIsActionAreaLoading(true)
    try {
      const { data } = await datasetService.getDatasetAccessUsers(dataframeId)
      setCanShareDataset(true)
      setIsShared(data.users.length > 0 || data.all)
      setShareFormData(data)
    } catch (error) {
      setCanShareDataset(false)
      if (error.response.status !== 403) {
        setError(true)
        setErrorMsg(error.message)
      }
    } finally {
      setIsActionAreaLoading(false)
    }
  }

  const toggleDialog = (type = "") => {
    const { show } = dialogState
    setDialogState({ show: !show, type })
  }

  useEffect(() => {
    const isDatabaseSource = dataType === "database"

    fetchAccountStatus((account) => {
      const isUnderQuota = account.currentStorage < account.maxStorage

      setFreshnessLimit(account.freshnessLimit)
      setCreateButtonEnabled(isUnderQuota && dataType !== "database")
      if (!isUnderQuota && isDatabaseSource) {
        setButtonStatus("both")
      } else if (!isUnderQuota) {
        setButtonStatus("quota")
      } else if (isDatabaseSource) {
        setButtonStatus("database")
      } else {
        setButtonStatus("")
      }
    })
  }, [rows])
  useEffect(() => {
    if (workspace.type === "p") {
      setCanShareDataset(false)
      setIsActionAreaLoading(false)
    } else if (workspace.type) {
      handleGetAccessUsers()
    }
  }, [workspace])

  return (
    <>
      {isLoading || isActionAreaLoading ? (
        <div className={DataDetailStyles.actionArea}>
          <Skeleton variant="rounded" animation="wave" width={180} height={39} />
          <Skeleton variant="rounded" animation="wave" width={40} height={39} />
          <Skeleton variant="rounded" animation="wave" width={118} height={39} />
        </div>
      ) : (
        <div className={DataDetailStyles.actionArea}>
          {selected.length > 0 && (
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <p>
                {selected.length} {selected.length > 1 ? "sources" : "source"} selected.
              </p>
              <Button variant="outlined" color="error" onClick={handleConfirmDelete}>
                Delete
              </Button>
            </div>
          )}
          <Button variant="outlined" onClick={toggleDrawer}>
            Associated Projects
          </Button>
          <Button
            variant="outlined"
            sx={{ maxWidth: "fit-content", minWidth: "fit-content", pl: "0.5rem", pr: "0.5rem" }}
            onClick={() => {
              fetchDataframe()
            }}
          >
            <RefreshIcon />
          </Button>
          {canShareDataset ? (
            <Can I="share" a="Dataset" ability={ability}>
              <Button
                onClick={() => toggleDialog("shareDataset")}
                variant="outlined"
                startIcon={isShared ? <PeopleIcon /> : <LockIcon />}
              >
                {isShared ? "Shared" : "Share"}
              </Button>
            </Can>
          ) : null}
          <Can I="create" a="DataSource" ability={ability}>
            <div>
              <Tooltip
                title={
                  !createButtonEnabled && buttonStatus === "quota" ? (
                    <>
                      You have reached the maximum data source limit.{" "}
                      <span
                        style={{ color: "#fff", cursor: "pointer", textDecoration: "underline" }}
                        onClick={() => setSubPrompt(true)}
                      >
                        Upgrade plan
                      </span>
                    </>
                  ) : !createButtonEnabled && buttonStatus === "database" ? (
                    <>You can only have one database as a source for your project at this time.</>
                  ) : null
                }
              >
                <span>
                  <Button variant="contained" onClick={handleOpen} disabled={!createButtonEnabled}>
                    Add Source
                  </Button>
                </span>
              </Tooltip>
            </div>
          </Can>
        </div>
      )}
      {isLoading ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 8, marginTop: "1rem" }}>
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={50} />
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={50} />
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={50} />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 2px 4px -1px rgba(61, 61, 61, 0.6)", position: "relative" }}
        >
          <LocalLoadingBar localLoading={tableLoading} />
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <Can I="delete" a="DataSource" ability={ability}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < rows.length}
                      checked={rows.length > 0 && selected.length === rows.length}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                </Can>
                <TableCell>Data Source</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Sync Status</TableCell>
                <TableCell>Auto-Sync</TableCell>
                <TableCell>
                  <p style={{ width: 100 }}>
                    Public
                    <BasicTooltip tooltip="Determine whether the file can be shared via the link or not." />
                  </p>
                </TableCell>
                <TableCell>Updated Time</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows.slice(page * 10, page * 10 + 10).map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                  >
                    <Can I="delete" a="DataSource" ability={ability}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selected.indexOf(row.id) !== -1}
                          onChange={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                    </Can>
                    <TableCell style={{ minWidth: 100, maxWidth: 300 }} className={DataDetailStyles.ellipsis}>
                      <>
                        <SourceNameTooltip title={dataType === "database" ? row.uri : row.name}>
                          <span>{dataType === "database" ? row.uri : row.name}</span>
                        </SourceNameTooltip>
                      </>
                    </TableCell>
                    <TableCell style={{ width: 150, textTransform: "capitalize" }}>
                      {DATA_SOURCES[row.source] || row.source}
                    </TableCell>
                    <TableCell style={{ minWidth: 100 }}>
                      <div style={{ display: "flex" }}>
                        <Tooltip placement="top" title={row.fail_reason}>
                          <IconButton>
                            <Chips
                              icon={row.status === "f" ? <InfoOutlinedIcon /> : undefined}
                              status={row.status || "r"}
                            />
                          </IconButton>
                        </Tooltip>
                        {((["f", "r"].includes(row.status) && !["csv", "file", "text"].includes(row.source)) ||
                          (row.status === "f" && ["csv", "file", "text"].includes(row.source))) && (
                          <Tooltip placement="top" title="Sync and update data source manually.">
                            <IconButton
                              onClick={(e) => {
                                handleSyncClick(e, row.id)
                              }}
                            >
                              <AutorenewIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell style={{ minWidth: 120 }}>
                      {dataType === "import" && ALLOW_SYNC_SOURCES.includes(row.source) ? (
                        <SelectField
                          options={FRESHNESS_OPTIONS}
                          value={row.datafreshness}
                          onChange={(event) => handleSyncStateChange(event, row.id)}
                          disabled={tableLoading || ["f", "p"].includes(row.status)}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell key={row.id} style={{ minWidth: 50 }}>
                      {dataType === "import" && !ALLOW_SYNC_SOURCES.includes(row.source) ? (
                        <Switch
                          checked={checkedStates[row.id]}
                          onChange={(event) => handleTogglePublic(event, row.id)}
                          disabled={tableLoading || ["f", "p"].includes(row.status)}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell style={{ minWidth: 180 }}>
                      {new Date(parseInt(row.update_time)).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </TableCell>
                    <TableCell style={{ minWidth: 72 }}>
                      <div className={DataDetailStyles.actionCell}>
                        <Tooltip title="See the processed data.">
                          <IconButton onClick={(event) => handleRowClick(event, row)}>
                            <FindInPageOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Copy the original file URL.">
                          <span>
                            <IconButton
                              onClick={() => row.raw_path && handleCopyIconClick(row.raw_path) && setCopySuccess(true)}
                              disabled={!row.raw_path || !ability.can("read", "DataSourceOriginalFile")}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Open the original file in a new window.">
                          <span>
                            <IconButton
                              onClick={() => handleLaunchIconClick(row.raw_path)}
                              disabled={!row.raw_path || !ability.can("read", "DataSourceOriginalFile")}
                            >
                              <LaunchIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                    <i>No Data Source</i>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <CustomTablePagination page={page + 1} setPage={setPage} dataCount={rows.length} />
        </TableContainer>
      )}
      <Snackbar
        open={copySuccess}
        autoHideDuration={2000}
        onClose={handleCloseCandyBar}
        message="URL copied"
        action={<IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseCandyBar}></IconButton>}
      />
      {workspace.type !== "p" && dialogState.show && dialogState.type === "shareDataset" ? (
        <ConfirmationDialog
          open={true}
          handlePrimary={handleShareDataset}
          handleSecondary={toggleDialog}
          title={`Share ${dataName}`}
          content={
            <ShareResourceForm
              formData={shareFormData}
              onChange={(payload) => {
                setShareDatasetPayload(payload)
              }}
              refresh={fetchDataframe}
            />
          }
          primaryButtonVariant="contained"
          secondaryButtonVariant="outlined"
          primaryButtonText={isDialogLoading ? "Sharing..." : "Share"}
          primaryButtonDisabled={isDialogLoading}
        />
      ) : null}
    </>
  )
}
