import { useEffect } from "react"
import { driver } from "driver.js"
import "driver.js/dist/driver.css"
import "../../styles/PopoverHint.css"

const PopoverHint = ({ elementSelector, side = "top", align = "center", title = "", description }) => {
  useEffect(() => {
    const driverObj = driver({
      popoverClass: "driverjs-theme",
      overlayOpacity: 0.1,
      stagePadding: 5,
      disableActiveInteraction: false,
    })

    driverObj.highlight({
      element: elementSelector,
      popover: {
        title,
        description,
        side,
        align,
      },
    })

    const handleClickOutside = () => {
      document.querySelector(elementSelector)
      driverObj.destroy()
    }

    document.addEventListener("click", handleClickOutside)
  }, [])
}

export default PopoverHint
