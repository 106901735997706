// Essential Imports
import React, { useState, useEffect, useContext } from "react"

// Component Imports
import { ErrorContext } from "../../helper/AlertContext"
import Chips from "../items/Chips"
import request from "../../api/axios"

// Library Imports
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import Skeleton from "@mui/material/Skeleton"

export default function DataDetailGoogleDrive({
  localLoading,
  setLocalLoading,
  setIntegrationFileId,
  setIntegrationFileType,
  setIntegrationFileName,
  setDataValid,
}) {
  const errorContext = useContext(ErrorContext)
  const [userId, setUserId] = useState(null)
  const [authenticating, setAuthenticating] = useState(true)
  const [buttonText, setButtonText] = useState("Connect Google Drive")
  const [items, setItems] = useState([])
  const [nextPageToken, setNextPageToken] = useState(null)
  const [query, setQuery] = useState("")
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [inputValue, setInputValue] = useState(null)

  let windowObjectReference = null
  let previousUrl = null

  const mapMimeTypeToDisplayText = (mimeType) => {
    switch (mimeType) {
      case "text/plain":
        return "Text"
      case "application/vnd.google-apps.document":
        return "Docs"
      case "application/vnd.google-apps.spreadsheet":
        return "Sheets"
      case "text/csv":
        return "CSV"
      case "application/json":
        return "JSON"
      case "application/xml":
        return "XML"
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "XLSX"
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "DOCX"
      case "application/pdf":
        return "PDF"
      default:
        return "Unknown"
    }
  }

  const mapMimeTypeToInternalValue = (mimeType) => {
    switch (mimeType) {
      case "text/plain":
        return "file"
      case "application/vnd.google-apps.document":
        return "google_docs"
      case "application/vnd.google-apps.spreadsheet":
        return "google_sheets"
      case "text/csv":
        return "csv"
      case "application/json":
        return "file"
      case "application/xml":
        return "file"
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlsx"
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx"
      case "application/pdf":
        return "pdf"
      default:
        return "Unknown"
    }
  }

  // Function to fetch Google Drive data
  const fetchGoogleDriveData = async (nextPageToken = null, newQuery = "", loadType) => {
    if (loadType === "newLoad") {
      setItems([])
      setNextPageToken(null)
    }
    setLocalLoading(true)
    try {
      let endpoint = `/third_party/google/50`
      let params = []
      if (nextPageToken) {
        params.push(`next_page_token=${nextPageToken}`)
      }
      if (newQuery) {
        params.push(`q=${newQuery}`)
      }
      if (params.length > 0) {
        endpoint += `?${params.join("&")}`
      }
      const response = await request.get(endpoint)
      if (response.data.text) {
        if (isSearchActive) {
          setItems(response.data.text.items)
        } else {
          setItems((prevItems) => [...prevItems, ...response.data.text.items])
        }
        setNextPageToken(response.data.text.next_page_token)
      }
    } catch (error) {
      errorContext.setError(true)
      if (error.response && error.response.status === 400) {
        errorContext.setErrorMsg("Special characters not allowed.")
      } else {
        errorContext.setErrorMsg(error.message)
      }
    } finally {
      setLocalLoading(false)
    }
  }

  // Infinite scroll for Autocomplete
  const handleScroll = (event) => {
    if (localLoading) return

    const { scrollTop, clientHeight, scrollHeight } = event.target
    const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight * 0.75
    if (isBottom && nextPageToken) {
      setIsSearchActive(false)
      fetchGoogleDriveData(nextPageToken, "", "continueLoad")
    }
  }

  // Handle search
  const handleSearch = (event, value) => {
    clearTimeout(searchTimeout)

    // Check for partial matches in existing items
    const partialMatch = items.some((item) => item.name.toLowerCase().startsWith(value.toLowerCase()))

    if (partialMatch) {
      return // Don't fetch if there's a partial or full match
    }

    setQuery(value)

    const newTimeout = setTimeout(() => {
      setIsSearchActive(true)
      if (value === "") {
        setIsSearchActive(false)
        fetchGoogleDriveData(null, "", "newLoad")
      } else {
        fetchGoogleDriveData(null, value, "newLoad")
      }
    }, 500)

    setSearchTimeout(newTimeout)
  }

  // Check if users already authenticated
  useEffect(() => {
    const fetchUserId = request.get(`/account`)
    const fetchOauthStatus = request.get(`/third_party/google/is_need_google_oauth`)

    Promise.all([fetchUserId, fetchOauthStatus])
      .then(([userIdResponse, oauthStatusResponse]) => {
        setUserId(userIdResponse.data.id)
        if (!oauthStatusResponse.data.text) {
          setButtonText("Authenticated")
          fetchGoogleDriveData(null, "", "newLoad")
        }
        setAuthenticating(false)
      })
      .catch((error) => {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      })

    return () => {
      clearTimeout(searchTimeout)
    }
  }, [])

  // Trigger receive message from pop up
  const receiveMessage = async (event) => {
    const { data, origin } = event

    // Verify the source and origin
    if (!data || data.source !== "Vext Authentication" || origin !== window.location.origin) {
      return
    }

    const params = new URLSearchParams(String(data.payload).slice(1))
    const code = params.get("code")
    window.removeEventListener("message", receiveMessage)

    try {
      const response = await request.post(`/third_party/google/oauth`, {
        code: code,
      })

      if (response.data.text === "ok") {
        setButtonText("Authenticated")
        fetchGoogleDriveData(null, "", "newLoad")
      }
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    }
  }

  // Start Google authentication process
  const authenticateGoogle = () => {
    window.removeEventListener("message", receiveMessage)

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${process.env.REACT_APP_GOOGLE_DOC_CLIENT_ID}&redirect_uri=${window.location.origin}/oauth-redirect&response_type=code&scope=https://www.googleapis.com/auth/spreadsheets.readonly%20https://www.googleapis.com/auth/drive.readonly%20https://www.googleapis.com/auth/documents.readonly&access_type=offline&state=google&prompt=consent`
    const name = "Google Authentication"
    const strWindowFeatures = "toolbar=no, menubar=no, width=980, height=720, top=100, left=100"

    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(authUrl, name, strWindowFeatures)
    } else if (previousUrl !== authUrl) {
      windowObjectReference = window.open(authUrl, name, strWindowFeatures)
      windowObjectReference.focus()
    } else {
      windowObjectReference.focus()
    }

    // add the listener for receiving a message from the popup
    window.addEventListener("message", receiveMessage, false)
    // assign the previous URL
    previousUrl = authUrl
  }

  return (
    <div style={{ display: "flex", width: "100%", gap: "1rem" }}>
      {buttonText === "Authenticated" ? (
        <div style={{ width: "100%" }}>
          <h5>Select File</h5>
          <Autocomplete
            value={inputValue}
            options={items}
            getOptionLabel={(option) => `${option.name || ""}`}
            isOptionEqualToValue={(option, value) => items.some((item) => item.id === value.id)}
            renderOption={(props, option) => (
              <li {...props} key={`${option.id}`}>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>{option.name}</div>
                    <small style={{ color: "#3d3d3d80" }}>{option.id}</small>
                  </div>
                  <Chips
                    customLabel={mapMimeTypeToDisplayText(option.mimeType)}
                    customColor="contrast"
                    style={{ fontSize: "0.6rem", border: "1px solid #3d3d3d80" }}
                  />
                </div>
              </li>
            )}
            renderInput={(params) => <TextField {...params} placeholder="Search File..." />}
            sx={{ width: "100%" }}
            ListboxProps={{
              onScroll: handleScroll,
            }}
            onInputChange={handleSearch}
            onChange={(event, value) => {
              if (value) {
                setInputValue(value)
                setDataValid(true)
                setIntegrationFileId(value.id)
                setIntegrationFileName(value.name)
                setIntegrationFileType(mapMimeTypeToInternalValue(value.mimeType))
              } else {
                fetchGoogleDriveData(null, "", "newLoad")
                setInputValue(null)
                setDataValid(false)
                setIntegrationFileId("")
                setIntegrationFileName("")
                setIntegrationFileType("")
              }
            }}
            noOptionsText={
              localLoading ? (
                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                  <Skeleton variant="text" animation="wave" width="100%" height={30} />
                  <Skeleton variant="text" animation="wave" width="100%" height={20} />
                </div>
              ) : (
                "No Result"
              )
            }
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p
              onClick={() => {
                authenticateGoogle()
                setInputValue(null)
              }}
              style={{ cursor: "pointer", textDecoration: "underline", marginTop: "1rem", fontSize: "0.7rem" }}
            >
              Switch Account
            </p>
          </div>
        </div>
      ) : (
        <Button
          variant="contained"
          onClick={authenticateGoogle}
          disabled={authenticating}
          style={{ width: "100%", marginTop: "1rem" }}
        >
          {authenticating ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <img src="/images/integrations/googledrive.svg" style={{ marginRight: "0.5rem" }} />
              {buttonText}
            </>
          )}
        </Button>
      )}
    </div>
  )
}
