import React from "react"

const VariableSection = ({ chipList = [] }) => {
  return (
    <>
      <div style={{ display: "flex", fontSize: "0.8rem", gap: "0.3rem", flexWrap: "wrap", alignContent: "flex-start" }}>
        {chipList.join(", ")}
      </div>
    </>
  )
}

export default VariableSection
