// Essential Imports
import { useContext, useState } from "react"

import { ErrorContext } from "./AlertContext"
import { stripesService } from "../api/services"

const useStripeSession = () => {
  const [loading, setLoading] = useState(false)
  const { setError, setErrorMsg } = useContext(ErrorContext)

  const createStripeSession = async (param) => {
    const stripeTypes = ["update", "cancel"]
    let query

    if (stripeTypes.includes(param)) {
      query = { s: param }
    }
    setLoading(true)
    try {
      const response = await stripesService.createPortal(query)
      const stripeUrl = response.data.data
      window.open(stripeUrl, "_blank")
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setLoading(false)
    }
  }

  return { loading, createStripeSession }
}

export default useStripeSession
