// Essential Imports
import * as React from "react"

// Library Imports
import { Box, Slider } from "@mui/material"

export default function DiscreteSlider({
  label = "Value",
  disabled = false,
  min,
  max,
  step,
  initialValue,
  setState,
  handleSave,
  type,
  marks,
  ref,
}) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleSliderChange = (event, newValue) => {
    setValue(newValue)
    if (type === "temperature") {
      setState((prevState) => ({
        ...prevState,
        temperature: newValue,
      }))
    } else if (type === "top_k") {
      setState((prevState) => ({
        ...prevState,
        top_k: newValue,
      }))
    }
  }

  const handleSliderChangeCommitted = (event, newValue) => {
    if (handleSave) {
      handleSave(newValue)
    }
  }

  return (
    <Box sx={{ width: "100%", maxWidth: "20rem", gap: "1rem", alignItems: "center" }}>
      <Slider
        aria-label={label}
        disabled={disabled}
        valueLabelDisplay="auto"
        step={step}
        min={min}
        max={max}
        marks={marks}
        value={value}
        onChange={handleSliderChange}
        onChangeCommitted={handleSliderChangeCommitted}
        ref={ref}
      />
    </Box>
  )
}
