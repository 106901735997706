import request from "../axios"

const requests = {
  inviteMember: (id, payload) => {
    return request.post(`/government/${id}/users`, payload)
  },
  getGovernment: () => {
    return request.get("/government")
  },
  getMemberList: (id) => {
    return request.get(`/government/${id}/users`)
  },
  removeMember: (id, userId) => {
    return request.delete(`/government/${id}/users/${userId}`)
  },
  updateRole: (id, userId, payload) => {
    return request.put(`/government/${id}/users/${userId}`, payload)
  },
  createWorkspace: (id, payload) => {
    return request.post(`/government/${id}/workspace`, payload)
  },
  removeWorkspace: (id, workspaceId) => {
    return request.delete(`/government/${id}/workspace/${workspaceId}`)
  },
  updateWorkspace: (id, workspaceId, payload) => {
    return request.put(`/government/${id}/workspace/${workspaceId}`, payload)
  },
}

export default requests
