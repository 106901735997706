import React from "react"
import { Button } from "@mui/material"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import { INPUT_ACCEPT_MIME_TYPE } from "../../utils/constants"

export default function DataDetailFile({ fileName, handleFileSelection }) {
  return (
    <>
      <div
        style={{
          overflow: "hidden",
          textAlign: "left",
          fontSize: "0.8rem",
        }}
      >
        {fileName && fileName.length > 0 ? (
          <div style={{ marginTop: "1rem" }}>
            <h5>Selected Files:</h5>
            <label
              htmlFor="file"
              style={{
                display: "block",
                cursor: "pointer",
                padding: "0.5rem",
                border: "1px dashed #3d3d3d80",
                borderRadius: "4px",
                marginTop: "0.5rem",
              }}
            >
              {fileName.join(", ")}
            </label>
          </div>
        ) : null}
        <input
          id="file"
          type="file"
          accept={INPUT_ACCEPT_MIME_TYPE.join(",")}
          multiple
          onChange={handleFileSelection}
          hidden
        />
      </div>
      {!fileName || fileName.length === 0 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Button
            component="label"
            variant="outlined"
            startIcon={<UploadFileIcon />}
            style={{
              width: "100%",
              textTransform: "none",
              border: "1px dashed #3d3d3d80",
            }}
          >
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Select Up to 5 Files (10 MB each)
            </span>
            <input
              id="file"
              type="file"
              accept={INPUT_ACCEPT_MIME_TYPE.join(",")}
              multiple
              onChange={handleFileSelection}
              hidden
            />
          </Button>
        </div>
      ) : null}
    </>
  )
}
