import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"

// Component Imports
import { SuccessContext, ErrorContext } from "../helper/AlertContext"
import LocalLoadingBar from "../components/items/LocalLoadingBar"
import { oauthService } from "../api/services"

// Library Imports
import Button from "@mui/material/Button"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"

// Stylesheet Imports
import "../styles/Login.css"

export default function OAuthHandler({ isAuthenticated }) {
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const successContext = useContext(SuccessContext)
  const errorContext = useContext(ErrorContext)
  const [localLoading, setLocalLoading] = useState(false)
  const [isInvalidRequest, setIsInvalidRequest] = useState(false)
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [appName, setAppName] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/").filter(Boolean)
    const appNameRaw = pathSegments[1]
    sessionStorage.setItem("oauth", appNameRaw)
    const formattedAppName = appNameRaw.charAt(0).toUpperCase() + appNameRaw.slice(1)
    setAppName(formattedAppName)
    sessionStorage.setItem("oauthUrl", window.location.pathname + window.location.search)
    const urlParams = new URLSearchParams(window.location.search)

    if (!urlParams.toString()) {
      setIsInvalidRequest(true)
      return
    }

    // Set an expiration time for the session storage data
    const expirationDate = new Date().getTime() + 60000 // 1 minute from now
    sessionStorage.setItem("m4fu6g6ru0", expirationDate.toString())

    // Check if the session is expired
    const checkExpiration = () => {
      const current = new Date().getTime()
      const expired = current > Number(sessionStorage.getItem("m4fu6g6ru0"))

      if (expired) {
        setIsSessionExpired(true)
        sessionStorage.clear()
      }
    }

    // Run every second
    const intervalId = setInterval(checkExpiration, 1000)

    for (const [key, value] of urlParams) {
      sessionStorage.setItem(key, value)
    }

    setIsDataLoaded(true)

    return () => clearInterval(intervalId)
  }, [])

  // Handle allow clicks
  const handleAllowClick = async () => {
    setLocalLoading(true)

    const payloadKeys = [
      "client_id",
      "code_challenge",
      "response_type",
      "code_challenge_method",
      "redirect_uri",
      "oauth",
    ]
    let payload = payloadKeys.map((key) => `${key}=${encodeURIComponent(sessionStorage.getItem(key) || "")}`).join("&")

    const encodedRedirectUri = encodeURIComponent(sessionStorage.getItem("redirect_uri") || "")
    const decodedRedirectUri = decodeURIComponent(encodedRedirectUri)
    payload = payload.replace(encodedRedirectUri, decodedRedirectUri)
    payload += "&allow=true"

    try {
      const response = await oauthService.checkOauth(payload)
      const newUrl = new URL(response.data.text)
      const state = sessionStorage.getItem("state")
      if (state) {
        newUrl.searchParams.append("state", state)
      }

      successContext.setSuccess(true)
      successContext.setSuccessMsg("Authentication Successful.")
      setLocalLoading(false)
      window.location.href = newUrl.toString()
      sessionStorage.clear()
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
      setLocalLoading(false)
    }
  }

  return isDataLoaded && !isSessionExpired && !isInvalidRequest && sessionStorage.getItem("oauth") ? (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        <img style={{ width: "6rem", marginBottom: "2rem" }} src="/images/vext_logo.png" alt="vext logo" />
        <div className="login-container">
          <LocalLoadingBar localLoading={localLoading} />
          <div
            style={{
              width: "100%",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <p style={{ margin: "auto", fontSize: "1rem", textAlign: "center" }}>
              Would you like to give the following application access to your account?
            </p>
            <p style={{ margin: "auto", fontSize: "1.2rem", fontWeight: "700" }}>{appName}</p>
            <p>{appName} will be able to use your account until you disable it.</p>
            <p>
              {appName} is not affiliated with Vext, and by permitting access to your Vext account you assume all
              related risks and liabilities.
            </p>
            <p style={{ fontWeight: "700" }}>{appName} will be able to:</p>
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              <li style={{ display: "flex", alignItems: "center" }}>
                <CheckIcon style={{ marginRight: "8px", color: "#2EB666" }} />
                Read your name and username
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <CheckIcon style={{ marginRight: "8px", color: "#2EB666" }} />
                Access to your AI projects
              </li>
            </ul>
            <p style={{ fontWeight: "700" }}>{appName} will not be able to:</p>
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              <li style={{ display: "flex", alignItems: "center" }}>
                <CloseIcon style={{ marginRight: "8px", color: "#F44336" }} />
                Read your email address
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <CloseIcon style={{ marginRight: "8px", color: "#F44336" }} />
                See your password
              </li>
            </ul>
            {isAuthenticated ? (
              <Button variant="contained" onClick={handleAllowClick}>
                Allow
              </Button>
            ) : (
              <Button variant="contained" onClick={() => navigate("/login")}>
                Login
              </Button>
            )}
            <p style={{ justifyContent: "center", fontSize: "0.7rem" }}>
              {`Vext's `}
              <a
                style={{ textDecoration: "underline", cursor: "pointer", fontSize: "0.7rem" }}
                onClick={(event) => {
                  event.preventDefault()
                  window.open("https://vextapp.com/privacy", "_blank")
                }}
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  ) : null
}
