import React, { useContext, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Box, Button, Skeleton, TextField } from "@mui/material"

import { ErrorContext, SuccessContext } from "../../helper/AlertContext"
import LinearProgress from "../items/Progress"
import BasicTooltip from "../items/BasicTooltip"
import MemberRow from "../items/MemberRow"
import WorkspaceRow from "../items/WorkspaceRow"
import BillingSection from "../items/BillingSection"
import ConfirmationDialog from "../items/ConfirmationDialog"
import CreateOrgMemberForm from "./CreateOrgMemberForm"
import CreateWorkspaceForm from "./CreateWorkspaceForm"
import TabPanel from "../template/TabPanel"
import { governmentService } from "../../api/services"
import { TABS } from "../../utils/constants"

import AccountTabsStyles from "../../styles/AccountTabs.module.css"

export default function OrganizationTabs() {
  const [value, setValue] = useState(0)
  const [availableCredit, setAvailableCredit] = useState(0)
  const [memberList, setMemberList] = useState([])
  const [workspaceList, setWorkspaceList] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const [selectedWorkspace, setSelectedWorkspace] = useState({})
  const [dialogState, setDialogState] = useState({ show: false, type: "" })
  const [isLocalLoading, setIsLocalLoading] = useState(false)
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [isCreatingButtonDisabled, setIsCreatingButtonDisabled] = useState(false)
  const [currentOrganization, setCurrentOrganization] = useState({})
  const [membersPayload, setMembersPayload] = useState({})
  const [usage, setUsage] = useState({})
  const [workspacePayload, setWorkspacePayload] = useState({})
  const [workspacePayloadErrors, setWorkspacePayloadErrors] = useState({
    creditLimit: false,
    owner: false,
    workspaceName: false,
  })
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { setSuccess, setSuccessMsg } = useContext(SuccessContext)
  const [searchParams] = useSearchParams()

  const handleCreateMember = async () => {
    let receivers = membersPayload.user_emails.split(",")

    receivers = receivers.map((r) => r.trim())
    setIsLocalLoading(true)
    try {
      const { data } = await governmentService.inviteMember(currentOrganization.id, {
        role: "member",
        user_emails: receivers,
      })
      const userList = []

      data.map((item) => {
        userList.push({
          id: item.user.id,
          name: `${item.user.first_name} ${item.user.last_name}`,
          email: item.user.email,
          role: item.role,
          is_active: item.is_active,
          is_owner: item.role === "owner",
        })
      })

      setMemberList(userList)
      setSuccess(true)
      setSuccessMsg("Invitation email has sent.")
      toggleDialog()
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const toggleDialog = (type = "") => {
    const { show } = dialogState
    setDialogState({ show: !show, type })
  }
  const handleGetMemberList = async () => {
    try {
      setIsLoadingTable(true)
      const { data } = await governmentService.getMemberList(currentOrganization.id)
      const userList = []

      data.map((item) => {
        userList.push({
          id: item.user.id,
          name: `${item.user.first_name} ${item.user.last_name}`,
          email: item.user.email,
          role: item.role,
          is_active: item.is_active,
          is_owner: item.role === "owner",
        })
      })
      setMemberList(userList)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLoadingTable(false)
    }
  }
  const handleCreateWorkspace = async () => {
    const payload = {
      ...workspacePayload,
      credit_limit: Number(workspacePayload.credit_limit),
    }
    let hasError = false

    setWorkspacePayloadErrors(Object.fromEntries(Object.keys(workspacePayloadErrors).map((key) => [key, false])))
    if (!payload.name) {
      setWorkspacePayloadErrors((prevValue) => ({ ...prevValue, workspaceName: true }))
      hasError = true
    }
    if (!payload.owner) {
      setWorkspacePayloadErrors((prevValue) => ({ ...prevValue, owner: true }))
      hasError = true
    }
    if (isNaN(payload.credit_limit) || payload.credit_limit < 0 || payload.credit_limit > availableCredit) {
      setWorkspacePayloadErrors((prevValue) => ({ ...prevValue, creditLimit: true }))
      hasError = true
      if (payload.credit_limit > availableCredit) {
        setError(true)
        setErrorMsg("The number entered exceeds the remaining available credit.")
      }
    }
    if (hasError) return

    try {
      setIsLocalLoading(true)
      await governmentService.createWorkspace(currentOrganization.id, payload)
      await fetchOrganization()
      toggleDialog()
    } catch (error) {
      setError(true)
      setErrorMsg(error.response?.data?.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const handleEditWorkspace = async () => {
    if (!Number(workspacePayload.credit_limit) || Number(workspacePayload.credit_limit) > availableCredit) {
      setError(true)
      setErrorMsg("The number entered exceeds the remaining available credit.")
      return
    }

    const payload = {
      ...workspacePayload,
      credit_limit: Number(workspacePayload.credit_limit),
    }

    try {
      setIsLocalLoading(true)
      await governmentService.updateWorkspace(currentOrganization.id, selectedWorkspace.id, payload)
      await fetchOrganization()
      toggleDialog()
    } catch (error) {
      setError(true)
      setErrorMsg(error.response?.data?.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const handleDeleteWorkspace = async () => {
    setIsLocalLoading(true)
    try {
      await governmentService.removeWorkspace(currentOrganization.id, selectedWorkspace.id)
      await fetchOrganization()
      toggleDialog()
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const handleUpdateUser = async ({ userId, roleId }) => {
    setIsLocalLoading(true)
    try {
      await governmentService.updateRole(currentOrganization.id, userId, { role: roleId })
      // [2024-10-27] TODO: refetch member list from api response, instead of requesting handleGetMemberList
      handleGetMemberList()
      setSuccess(true)
      setSuccessMsg("User role has changed.")
    } catch (error) {
      setError(true)
      setErrorMsg(error.response?.data?.message?.role[0])
    } finally {
      setIsLocalLoading(false)
    }
  }
  const handleDeleteUser = async () => {
    setIsLocalLoading(true)
    try {
      const { data } = await governmentService.removeMember(currentOrganization.id, selectedUser.id)
      setSelectedUser({})
      handleGetMemberList()
      toggleDialog()
      setSuccess(true)
      setSuccessMsg(data.message)
    } catch (error) {
      setError(true)
      if (error.response.status === 403) {
        setErrorMsg(error.message)
      } else {
        setErrorMsg(error.response?.data?.message)
      }
    } finally {
      setIsLocalLoading(false)
    }
  }
  const onClickRemoveUser = (id) => {
    setSelectedUser({ id })
    toggleDialog("removeMember")
  }
  const onClickWorkspace = (id, action) => {
    setSelectedWorkspace({ id })
    toggleDialog(action)
  }
  const fetchOrganization = async () => {
    setIsLocalLoading(true)
    try {
      const { data } = await governmentService.getGovernment()

      setCurrentOrganization({ id: data.id, name: data.name })
      setWorkspaceList(data.workspaces)
      setAvailableCredit(data.available_credits)
      setUsage(data.usage)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }

  useEffect(() => {
    setIsCreatingButtonDisabled(Object.values(membersPayload).some((value) => !value))
  }, [membersPayload])
  useEffect(() => {
    if (currentOrganization?.id) {
      handleGetMemberList()
    }
  }, [currentOrganization])
  useEffect(() => {
    const tabParam = searchParams.get("tab")

    if (tabParam && TABS.organization.includes(tabParam)) {
      const tabIndex = TABS.organization.indexOf(tabParam)
      setValue(TABS.organization[tabIndex])
    }
  }, [searchParams])
  useEffect(() => {
    fetchOrganization()
  }, [])

  return (
    <>
      {isLocalLoading ? (
        <Box className={AccountTabsStyles.accountContainer} style={{ height: 282 }}>
          <div className={AccountTabsStyles.loadingCard}>
            <Skeleton variant="rounded" animation="wave" height={26} width={200} />
            {[...Array(2)].map((_, index) => (
              <div key={index}>
                <Skeleton variant="rounded" animation="wave" height={20} width={120} />
                <Skeleton variant="rounded" animation="wave" height={40} style={{ marginTop: "0.5rem" }} />
              </div>
            ))}
          </div>
        </Box>
      ) : (
        <Box className={AccountTabsStyles.accountContainer}>
          <TabPanel value={value} index={TABS.organization[0]}>
            <div className={AccountTabsStyles.tabPanelCard}>
              <h3>Organization Info</h3>
              <h4>Organization Name</h4>
              <TextField value={currentOrganization.name} disabled />
              <h4>Organization ID</h4>
              <TextField value={currentOrganization.id} disabled />
            </div>
          </TabPanel>
          <TabPanel value={value} index={TABS.organization[1]}>
            <div className={AccountTabsStyles.tabPanelCard}>
              <h3>Usage & Billing</h3>
              <BillingSection
                planName={usage.subscription?.name}
                planStartTime={usage.start_time}
                planEndTime={usage.stop_time}
              />
              <h4>Usage</h4>
              <div className={AccountTabsStyles.usageContainer}>
                <div>
                  <div className={AccountTabsStyles.usageItem}>
                    <p>
                      Credit Usage
                      <BasicTooltip
                        tooltip={
                          <span>
                            {`Total credits consumed in the current billing cycle. Credits are applied exclusively to LLM usage, with varying consumption rates depending on the specific LLM. `}
                            <a
                              style={{ color: "#fff" }}
                              href="https://river-bottle-af7.notion.site/What-is-Credit-and-How-Is-It-Calculated-15847600424a80f585c3f4b062734e46"
                              target="blank"
                            >
                              Learn more.
                            </a>
                          </span>
                        }
                      />
                    </p>
                    <i style={{ fontSize: "0.7rem" }}>
                      {`${usage.current_credit?.toLocaleString()} / ${usage.subscription?.credit_limit?.toLocaleString()} Credits`}
                    </i>
                  </div>
                  <LinearProgress count={(usage.current_credit / usage.subscription?.credit_limit) * 100} />
                </div>
                <div>
                  <div className={AccountTabsStyles.usageItem}>
                    <p>
                      Data Storage
                      <BasicTooltip tooltip="The total amount of data storage used within this billing cycle. This is calculated across all of your AI projects/data." />
                    </p>
                    <i style={{ fontSize: "0.7rem" }}>
                      {`${usage.current_storage_gb_usage?.toLocaleString()} GB / ${usage.subscription?.storage_gb_limit?.toLocaleString()} GB`}
                    </i>
                  </div>
                  <LinearProgress
                    count={(usage.current_storage_gb_usage / usage.subscription?.storage_gb_limit) * 100}
                  />
                </div>
              </div>
              <h4>Manage Workspaces</h4>
              <div style={{ display: "flex", justifyContent: "space-between", margin: "0.5rem 0" }}>
                <Button variant="contained" onClick={() => toggleDialog("createWorkspace")}>
                  Create Workspace
                </Button>
                <div style={{ alignItems: "center", display: "flex", fontSize: "0.8rem", gap: "0.5rem" }}>
                  <strong>Available Credits:</strong>
                  <span>{availableCredit ? availableCredit.toLocaleString() : "-"}</span>
                </div>
              </div>
              <div className={AccountTabsStyles.workspaceTableLike}>
                {workspaceList.map((item, index) => (
                  <WorkspaceRow
                    key={index}
                    creditLimit={item.credit_limit}
                    currentCredit={item.current_credit}
                    name={item.name}
                    handleEdit={() => onClickWorkspace(item.id, "editWorkspace")}
                    handleRemove={() => onClickWorkspace(item.id, "removeWorkspace")}
                  />
                ))}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={TABS.organization[2]}>
            <div className={AccountTabsStyles.tabPanelCard}>
              <h3>Members</h3>
              <div style={{ marginTop: "1rem" }}>
                <Button variant="contained" onClick={() => toggleDialog("addMember")}>
                  Add member
                </Button>
              </div>
              {isLoadingTable ? (
                <div>
                  <Skeleton variant="rounded" animation="wave" height={40} />
                </div>
              ) : (
                <div className={AccountTabsStyles.memberTableLike}>
                  {memberList.map((user, index) => (
                    <MemberRow
                      key={index}
                      name={user.name}
                      email={user.email}
                      isOwner={user.is_owner}
                      ownerLabel="Super Admin"
                      status={user.is_active ? "active" : "inactive"}
                      userId={user.id}
                      role={user.role}
                      roleOptions={[
                        { label: "Admin", value: "admin" },
                        { label: "User", value: "member" },
                      ]}
                      handleUpdateUser={handleUpdateUser}
                      handleRemoveUser={() => onClickRemoveUser(user.id)}
                    />
                  ))}
                </div>
              )}
            </div>
          </TabPanel>
          <ConfirmationDialog
            open={dialogState.show && dialogState.type === "addMember"}
            handlePrimary={handleCreateMember}
            handleSecondary={toggleDialog}
            title="Add Member"
            content={<CreateOrgMemberForm onChange={setMembersPayload} />}
            primaryButtonText={isLocalLoading ? "Adding..." : "Add member"}
            primaryButtonVariant="contained"
            secondaryButtonVariant="outlined"
            primaryButtonDisabled={isLocalLoading || isCreatingButtonDisabled}
          />
          <ConfirmationDialog
            open={dialogState.show && dialogState.type === "removeMember"}
            handlePrimary={handleDeleteUser}
            handleSecondary={toggleDialog}
            title="Confirm Remove"
            content={
              <>
                <div>Are you sure you want to remove this member?</div>
                <div>This action cannot be undone.</div>
              </>
            }
            primaryButtonColor="error"
            primaryButtonText={isLocalLoading ? "Removing..." : "Remove"}
            primaryButtonDisabled={isLocalLoading}
          />
          <ConfirmationDialog
            open={dialogState.show && dialogState.type === "removeWorkspace"}
            handlePrimary={handleDeleteWorkspace}
            handleSecondary={toggleDialog}
            title="Confirm Delete"
            content={
              <>
                <div>Are you sure you want to delete this workspace?</div>
                <div>This action cannot be undone.</div>
              </>
            }
            primaryButtonColor="error"
            primaryButtonText={isLocalLoading ? "Deleting..." : "Delete"}
            primaryButtonDisabled={isLocalLoading}
          />
          <ConfirmationDialog
            open={dialogState.show && dialogState.type === "createWorkspace"}
            handlePrimary={handleCreateWorkspace}
            handleSecondary={toggleDialog}
            title="Create Workspace"
            content={
              <CreateWorkspaceForm
                errors={workspacePayloadErrors}
                memberList={memberList}
                totalCredit={availableCredit}
                onChange={setWorkspacePayload}
              />
            }
            primaryButtonText={isLocalLoading ? "Creating..." : "Create"}
            primaryButtonVariant="contained"
            secondaryButtonVariant="outlined"
            primaryButtonDisabled={isLocalLoading}
          />
          <ConfirmationDialog
            open={dialogState.show && dialogState.type === "editWorkspace"}
            handlePrimary={handleEditWorkspace}
            handleSecondary={toggleDialog}
            title="Edit Workspace"
            content={
              <CreateWorkspaceForm
                formData={workspaceList.find((w) => w.id === selectedWorkspace.id)}
                memberList={memberList}
                totalCredit={availableCredit}
                onChange={setWorkspacePayload}
              />
            }
            primaryButtonText={isLocalLoading ? "Saving..." : "Save"}
            primaryButtonVariant="contained"
            secondaryButtonVariant="outlined"
            primaryButtonDisabled={isLocalLoading}
          />
        </Box>
      )}
    </>
  )
}
