import React, { useEffect, useState, useContext } from "react"
import { Button, IconButton, TextField } from "@mui/material"
import { Delete as DeleteIcon } from "@mui/icons-material"
import { Can } from "@casl/react"

import { AbilityContext } from "../../../helper/AbilityContext"
import { ErrorContext } from "../../../helper/AlertContext"
import BasicTooltip from "../../items/BasicTooltip"
import MessageBox from "../../items/MessageBox"
import LocalLoadingBar from "../../items/LocalLoadingBar"
import ActionDrawerStyles from "./ActionDrawer.module.css"

const VARIABLE_LIMIT = 4

const VariableColumn = ({ cid, column, handleDelete, handleEdit }) => {
  const ability = useContext(AbilityContext)

  return (
    <div style={{ display: "flex", gap: 8 }}>
      <TextField
        value={column.key}
        inputProps={{ maxLength: 50 }}
        onChange={(event) => handleEdit({ key: event.target.value })}
        placeholder={`Key ${cid}`}
        error={column.key_error}
        style={{ flex: "1 0 180px" }}
        disabled={!ability.can("update", "Project")}
      />
      <TextField
        value={column.value}
        inputProps={{ maxLength: 200 }}
        onChange={(event) => handleEdit({ value: event.target.value })}
        placeholder="Optional"
        disabled={!ability.can("update", "Project")}
      />
      <Can I="update" a="Project" ability={ability}>
        <div style={{ alignItems: "center", display: "flex", flex: "1 0 32px" }}>
          <IconButton onClick={() => handleDelete(column.key)} value={column.value}>
            <DeleteIcon style={{ fontSize: 16 }} />
          </IconButton>
        </div>
      </Can>
    </div>
  )
}

const QueryDrawer = ({ customVariables, isLoadingForm, onSubmit = () => {} }) => {
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const [variableList, setVariableList] = useState([])
  const [initialVariableList, setInitialVariableList] = useState([])
  const [isDatachanged, setIsDataChanged] = useState(false)
  const ability = useContext(AbilityContext)

  const isValidKey = (key) => {
    return /^[A-Za-z0-9_]+$/.test(key)
  }

  const variableAction = {
    add: () => {
      setVariableList((prevValue) => [...prevValue, { key: "", value: "" }])
    },
    edit: (index, input) => {
      setVariableList(
        variableList.map((item, idx) => {
          if (idx === index) return { ...item, ...input }
          return item
        }),
      )
    },
    remove: (key) => {
      setVariableList((prevValue) => {
        return prevValue.filter((item) => item.key !== key)
      })
    },
  }
  const handleOnSubmit = () => {
    let hasError = false
    let isEmpty = false
    const payload = {}
    const newArray = variableList.map((v) => {
      const invalidKey = !isValidKey(v.key)
      const keyEmpty = !v.key

      if (keyEmpty) {
        isEmpty = true
      } else if (invalidKey) {
        hasError = true
      }
      return { ...v, key_error: keyEmpty || invalidKey ? true : false }
    })

    setVariableList(newArray)

    if (hasError || isEmpty) {
      if (hasError) {
        setError(true)
        setErrorMsg("Spaces and special characters are not allowed.")
      } else if (isEmpty) {
        setError(true)
        setErrorMsg("Input cannot be empty.")
      }
    } else {
      variableList.map((v) => {
        if (v.key) {
          payload[v.key] = v.value
        }
      })
      onSubmit(payload)
      setError(false)
    }
  }

  useEffect(() => {
    const initialVarListStr = JSON.stringify(initialVariableList)
    const currentVarListStr = JSON.stringify(variableList)

    if (currentVarListStr !== initialVarListStr) {
      setIsDataChanged(true)
    } else {
      setIsDataChanged(false)
    }
  }, [variableList])

  useEffect(() => {
    if (customVariables && JSON.stringify(customVariables) !== "{}") {
      setVariableList(Object.keys(customVariables).map((item) => ({ key: item, value: customVariables[item] })))
      setInitialVariableList(Object.keys(customVariables).map((item) => ({ key: item, value: customVariables[item] })))
    }
  }, [customVariables])

  return (
    <>
      <Can I="update" a="Project" ability={ability}>
        <Button
          sx={{ position: "absolute", top: "1.25rem", right: "1.5rem", zIndex: 2 }}
          onClick={handleOnSubmit}
          variant="contained"
          disabled={isLoadingForm || !isDatachanged}
        >
          Save
        </Button>
      </Can>
      <section style={{ position: "relative" }}>
        <LocalLoadingBar localLoading={isLoadingForm} />
      </section>
      <div className={ActionDrawerStyles.main}>
        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          <h4>
            Custom Input Parameters ({variableList.length + 1}/{VARIABLE_LIMIT + 1})
            <BasicTooltip
              tooltip={
                <span>
                  {`By setting custom input parameters, you can introduce your own variables for the LLM pipeline to utilize. Learn more `}
                  <a
                    style={{ color: "#fff" }}
                    href="https://river-bottle-af7.notion.site/Configuring-Custom-Input-Output-Parameters-15847600424a805785e1df89513c3c76"
                    target="blank"
                  >
                    here.
                  </a>
                </span>
              }
            />
          </h4>
          <div>
            <TextField value="payload" inputProps={{ maxLength: 8 }} disabled />
          </div>
          {variableList.map((item, index) => (
            <VariableColumn
              key={index}
              cid={index + 2}
              column={item}
              handleDelete={() => variableAction.remove(item.key)}
              handleEdit={(input) => variableAction.edit(index, input)}
            />
          ))}
        </div>
        <Can I="update" a="Project" ability={ability}>
          <Button
            onClick={variableAction.add}
            className={ActionDrawerStyles.add}
            disabled={variableList.length >= VARIABLE_LIMIT}
          >
            + Add New Parameter
          </Button>
        </Can>
        <MessageBox
          type="info"
          message={
            <>
              {`If you're providing a custom value for the parameter via an API call later, you can leave it empty initially and supply the value via the API call when needed; If you enter a fixed value directly, that parameter will always use the specified value.`}
            </>
          }
        />
      </div>
    </>
  )
}

export default QueryDrawer
