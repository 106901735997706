import React, { useCallback, useState, useEffect, useContext } from "react"
import { useLocation } from "react-router-dom"
import { Button, InputAdornment, TextField, Skeleton } from "@mui/material"
import { Search as SearchIcon } from "@mui/icons-material"
import { debounce } from "lodash"

import { LoadingContext } from "../helper/LoadingContext"
import { ErrorContext, SuccessContext } from "../helper/AlertContext"
import ConfirmationDialog from "../components/items/ConfirmationDialog"
import CustomPagination from "../components/items/Pagination"
import ChunkCard from "../components/items/ChunkCard"
import { datasetService } from "../api/services"

import "../styles/DataSourceDetail.css"

const PER_PAGE = 10
const MAX_CHUNK_LENGTH = 1000

export default function DataSourceDetail() {
  const location = useLocation()
  const { isLoading, setIsLoading } = useContext(LoadingContext)
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { setSuccess, setSuccessMsg } = useContext(SuccessContext)
  const [chunks, setChunks] = useState([])
  const [chunkCount, setChunkCount] = useState(0)
  const [chunkText, setChunkText] = useState("")
  const [dialogState, setDialogState] = useState({ show: false, type: "" })
  const [isDialogLoading, setIsDialogLoading] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const [selectedChunkId, setSelectedChunkId] = useState(null)
  const [page, setPage] = useState(1)
  const contextData = location.state?.contextData

  const handleCreateChunk = async () => {
    setIsDialogLoading(true)
    try {
      await datasetService.createChunk(contextData.id, {
        text_chunk: chunkText,
      })
      fetchChunks(contextData.id)
      setSuccess(true)
      setSuccessMsg("Chunk saved")
      toggleDialog()
      setChunkText("")
    } catch (error) {
      setError(true)
      setErrorMsg("Failed to create new chunk")
    } finally {
      setIsDialogLoading(false)
    }
  }
  const handleDeleteChunk = async () => {
    setIsDialogLoading(true)
    try {
      await datasetService.removeChunk(contextData.id, selectedChunkId)
      fetchChunks(contextData.id)
      setSuccess(true)
      setSuccessMsg("Chunk deleted")
      toggleDialog()
    } catch (error) {
      setError(true)
      setErrorMsg("Failed to delete chunk")
    } finally {
      setIsDialogLoading(false)
    }
  }
  const handleUpdateChunk = async (chunkId, payload) => {
    try {
      const { data } = await datasetService.updateChunk(contextData.id, chunkId, payload)
      const { chunk_id, is_active, text_chunk } = data

      setChunks((prevValue) => {
        const newChunks = [...prevValue]
        newChunks.find((chunk) => {
          if (chunk.chunk_id === chunk_id) {
            chunk.is_active = is_active
            chunk.text_chunk = text_chunk
          }
          return chunk
        })
        return newChunks
      })
      setSuccess(true)
      setSuccessMsg("Chunk saved")
    } catch (error) {
      setError(true)
      setErrorMsg("Failed to update chunk")
      fetchChunks(contextData.id)
    }
  }
  const handleSearchQuery = (event) => {
    const query = event.target.value

    setSearchInput(query)
    debouncedGetChunks({ query })
  }
  const debouncedGetChunks = useCallback(
    debounce((params) => {
      const { query } = params

      setPage(1)
      fetchChunks(contextData.id, { query, page: 1 })
    }, 500),
    [],
  )
  const fetchChunks = async (sourceId, params = {}) => {
    setIsLoading(true)
    try {
      const { data } = await datasetService.getChunk(sourceId, { per_page: PER_PAGE, page, ...params })
      setChunks(data.results)
      setChunkCount(data.count)
    } catch (error) {
      setError(true)
      setErrorMsg("Failed to fetch source status")
    } finally {
      setIsLoading(false)
    }
  }
  const toggleDialog = (type = "") => {
    const { show } = dialogState
    setDialogState({ show: !show, type })
  }

  useEffect(() => {
    if (contextData?.id) {
      fetchChunks(contextData.id)
    } else {
      setError(true)
      setErrorMsg("Context data or source ID not available")
    }
  }, [contextData])

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "1rem", marginBottom: "1rem" }}>
        <TextField
          autoComplete="off"
          onChange={handleSearchQuery}
          placeholder="Search Chunk"
          sx={{ width: "18rem" }}
          value={searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button onClick={() => toggleDialog("createChunk")} variant="contained">
          Add Chunk
        </Button>
      </div>
      {isLoading ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Skeleton variant="rounded" animation="wave" width="100%" height={200} />
          <Skeleton variant="rounded" animation="wave" width="100%" height={200} />
        </div>
      ) : (
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {chunks.map((chunk, index) => (
              <ChunkCard
                key={index}
                chunkId={chunk.chunk_id}
                chunkText={chunk.text_chunk}
                isActive={chunk.is_active}
                index={index}
                onUpdateChunk={handleUpdateChunk}
                onClickDelete={(id) => {
                  toggleDialog("deleteChunk")
                  setSelectedChunkId(id)
                }}
              />
            ))}
            <div>
              <CustomPagination
                page={page}
                setPage={setPage}
                count={Math.ceil(chunkCount / PER_PAGE) || 1}
                setData={setChunks}
                callFunction={(params) => {
                  fetchChunks(contextData.id, params)
                }}
              />
            </div>
          </div>
          {dialogState.show && dialogState.type === "createChunk" ? (
            <ConfirmationDialog
              open={true}
              handlePrimary={handleCreateChunk}
              handleSecondary={toggleDialog}
              title="Add Chunk"
              content={
                <>
                  <TextField
                    multiline
                    rows={12}
                    onChange={(event) => setChunkText(event.target.value)}
                    value={chunkText}
                    variant="outlined"
                    inputProps={{
                      maxLength: MAX_CHUNK_LENGTH,
                    }}
                  />
                  <div style={{ marginTop: 8, textAlign: "right" }}>
                    {chunkText.length} / {MAX_CHUNK_LENGTH}
                  </div>
                </>
              }
              primaryButtonVariant="contained"
              secondaryButtonVariant="outlined"
              primaryButtonText={isDialogLoading ? "Adding..." : "Add"}
              primaryButtonDisabled={isDialogLoading || !chunkText.length}
            />
          ) : dialogState.show && dialogState.type === "deleteChunk" ? (
            <ConfirmationDialog
              open={true}
              handlePrimary={handleDeleteChunk}
              handleSecondary={toggleDialog}
              title="Confirm Delete"
              content={
                <div>
                  Are you sure you want to delete this chunk?
                  <br />
                  This action cannot be undone.
                </div>
              }
              primaryButtonText={isDialogLoading ? "Deleting..." : "Delete"}
              primaryButtonDisabled={isDialogLoading}
              primaryButtonColor="error"
            />
          ) : null}
        </>
      )}
    </>
  )
}
