// Essential Imports
import * as React from "react"

// Library Imports
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#3d3d3d",
      color: "#ffffff",
      maxWidth: 220,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
      fontSize: "0.8rem",
    },
  }),
)

export default function BasicTooltip({ tooltip }) {
  return (
    <HtmlTooltip placement="top" title={tooltip}>
      <HelpOutlineIcon style={{ fontSize: 13 }} />
    </HtmlTooltip>
  )
}
