import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardContent, IconButton } from "@mui/material"
import {
  Check as CheckIcon,
  CheckCircle as CheckCircleIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Close as CloseIcon,
} from "@mui/icons-material"

import { ErrorContext } from "../../helper/AlertContext"
import { accountService } from "../../api/services"
import ChecklistCardStyles from "../../styles/ChecklistCard.module.css"

const CheckListitem = ({ text = "", isCompleted = false, isActive = false, link = "" }) => (
  <li className={`${ChecklistCardStyles.checkListItem} ${isActive ? ChecklistCardStyles.active : ""}`}>
    <span className={ChecklistCardStyles.checkListText}>
      {isCompleted ? <CheckCircleIcon color="primary" /> : <CheckCircleOutlineIcon color="primary" />}
      {text}
    </span>
    {isActive && !isCompleted ? (
      <Link to={link}>
        <Button variant="contained" color="primary">
          Start
        </Button>
      </Link>
    ) : null}
  </li>
)
// TODO: props `name` should be removed and get from store
const ChecklistCard = ({ list, name = "", hideCardCallback = () => {} }) => {
  const [showActionHint, setShowActionHint] = useState(false)
  const errorContext = useContext(ErrorContext)
  const hideCheckList = async () => {
    try {
      await accountService.updateTaskList({ show_list: false })
      hideCardCallback()
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    }
  }
  const toggleActionHint = () => {
    setShowActionHint(!showActionHint)
  }

  return (
    <Card sx={{ maxWidth: 706 }}>
      <CardContent sx={{ p: 3 }}>
        <div className={ChecklistCardStyles.cardTitle}>Welcome{name ? `, ${name}` : ""} 👋</div>
        <p className={ChecklistCardStyles.cardDescription}>
          We’re delighted to have you here! Here’s a check list to help you get started.
        </p>
        <ul className={ChecklistCardStyles.checkList}>
          {list.map((item) => (
            <CheckListitem
              key={item.key}
              text={item.text}
              isCompleted={item.isCompleted}
              isActive={item.isActive}
              link={item.link}
            />
          ))}
        </ul>
        <div className={ChecklistCardStyles.cardFooter}>
          <div style={{ display: "flex", alignItems: "end" }}>
            {showActionHint ? (
              <div className={ChecklistCardStyles.hideListHint}>
                <span style={{ padding: "6px 0" }}>Are you sure? You will not see this checklist again.</span>
                <IconButton size="small" onClick={hideCheckList}>
                  <CheckIcon color="success" fontSize="inherit" />
                </IconButton>
                <IconButton size="small" onClick={toggleActionHint}>
                  <CloseIcon color="error" fontSize="inherit" />
                </IconButton>
              </div>
            ) : (
              <Button
                sx={{ fontSize: "0.7rem", marginBottom: "2px", padding: 0, textTransform: "none" }}
                onClick={toggleActionHint}
              >
                Hide Checklist
              </Button>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default ChecklistCard
