import React from "react"
import BasicTooltip from "../items/BasicTooltip"
import InputField from "../items/InputField"

export default function DataDetailText({ textTitle, setTextTitle, textData, setTextData }) {
  return (
    <>
      <h5>
        Text Title {""}
        <BasicTooltip tooltip="This is for internal identification purposes only." />
      </h5>
      <InputField
        value={textTitle}
        mLength={30}
        onChange={(value) => {
          setTextTitle(value)
        }}
        endAdornment={<>{textTitle.length.toLocaleString()} / 30</>}
      />
      <h5>Text Content</h5>
      <textarea
        value={textData}
        className="textarea"
        maxLength={1048576}
        onChange={(event) => {
          setTextData(event.target.value)
        }}
        style={{
          boxSizing: "border-box",
          border: "1px solid #3d3d3d80",
          borderRadius: "6px",
          padding: "0.5rem",
          resize: "none",
          width: "100%",
          height: "15rem",
          color: "#3d3d3d"
        }}
      />
      <p style={{ justifyContent: "flex-end" }}>{textData.length.toLocaleString()} / 1,048,576</p>
    </>
  )
}
