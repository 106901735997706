import React, { memo, useState } from "react"
import { Handle, Position } from "reactflow"
import { Button, IconButton } from "@mui/material"
import { MoreHorizOutlined as MoreHorizOutlinedIcon } from "@mui/icons-material"
import { ClickAwayListener } from "@mui/base"

import Chips from "./Chips"

import CustomNodeStyles from "../../styles/CustomNode.module.css"

const ComponentNode = ({ id, data }) => {
  const [openDialog, setOpenDeleteDialog] = useState(false)

  const toggleMoreDialog = (event) => {
    event.stopPropagation()
    setOpenDeleteDialog(!openDialog)
  }

  return (
    <div className={CustomNodeStyles.customNode} onClick={data.onClickNode} data-node-id={id}>
      {data.name === "query" || data.name === "output" || data.level === "0" ? null : (
        <div className={CustomNodeStyles.tab}>Action {data.level}</div>
      )}
      <div style={{ display: "flex", gap: "1rem" }}>
        <div className={CustomNodeStyles.icon}>
          <data.icon sx={{ fontSize: 40 }} />
        </div>
        <div style={{ alignItems: "center", display: "flex", flex: 1, justifyContent: "space-between" }}>
          <div className={CustomNodeStyles.title}>{data.label}</div>
          {["sf"].includes(data.name) && <Chips status="beta" />}
          {/* <div className={CustomNodeStyles.description}>{data.description}</div> */}
        </div>
        {data.name === "query" || data.name === "output" ? null : (
          <div className={CustomNodeStyles.actionContainer}>
            <IconButton onClick={toggleMoreDialog}>
              <MoreHorizOutlinedIcon className="MoreHorizOutlinedIcon" />
            </IconButton>
            {openDialog && (
              <ClickAwayListener onClickAway={toggleMoreDialog}>
                <div className={CustomNodeStyles.dialog}>
                  <Button
                    onClick={(event) => {
                      data.onDeleteNode(event)
                      toggleMoreDialog(event)
                    }}
                    style={{ justifyContent: "start", textTransform: "none", width: "100%" }}
                    data-node-id={id}
                  >
                    Delete
                  </Button>
                </div>
              </ClickAwayListener>
            )}
          </div>
        )}
      </div>
      <Handle type="target" position={Position.Top} isConnectable={false} style={{ background: "none", border: 0 }} />
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={false}
        style={{ background: "none", border: 0 }}
      />
    </div>
  )
}

export default memo(ComponentNode)
