// Essential Imports
import React, { useContext, useEffect, useRef } from "react"

// Component Imports
import { SuccessContext } from "../../helper/AlertContext"

// Library Imports
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Collapse from "@mui/material/Collapse"
import CloseIcon from "@mui/icons-material/Close"

export default function Success() {
  const { success, setSuccess, successMsg } = useContext(SuccessContext)

  const timerRef = useRef(null)

  useEffect(() => {
    // Clear previous timer if it exists
    if (timerRef.current) clearTimeout(timerRef.current)

    // Start a new timer if success is true
    if (success) {
      timerRef.current = setTimeout(() => {
        setSuccess(false)
      }, 5000)
    }

    // Clear the timer when the component is unmounted
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [success, setSuccess]) // Restart the timer every time the `success` state changes

  return (
    <Box
      sx={{
        width: "50%",
        position: "fixed",
        bottom: 0,
        left: "1rem",
        zIndex: 9999,
      }}
    >
      <Collapse in={success}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccess(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {success && `${successMsg}`}
        </Alert>
      </Collapse>
    </Box>
  )
}
