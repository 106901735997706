// Essential Imports
import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"

// Component Imports
import { AuthContext } from "./AuthProvider"
import { ErrorContext } from "./AlertContext"

function PrivateRouteWrapper({ children }) {
  const { isAuthenticated } = useContext(AuthContext)
  const errorContext = useContext(ErrorContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!document.cookie.includes("userLoggedIn=true")) {
      const isCustom = localStorage.getItem("vext_enterprise")

      errorContext.setError(true)
      errorContext.setErrorMsg("Your session has expired. please log in again.")
      navigate("/login")
      if (isCustom) {
        localStorage.removeItem("vext_enterprise")
      }
    }
  }, [isAuthenticated])

  if (!document.cookie.includes("userLoggedIn=true")) return null
  return children
}

export default PrivateRouteWrapper
