// Essential Imports
import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

// Component Imports
import { AccountProvider } from "./helper/AccountContext"
import { LoadingProvider } from "./helper/LoadingContext"
import App from "./App"

// Library Imports
import { GoogleOAuthProvider } from "@react-oauth/google"
import * as Sentry from "@sentry/react"
import TagManager from "react-gtm-module"

const root = document.getElementById("root")

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  denyUrls: [/localhost/, /https:\/\/2kkubg5zpr9ds9i\.vextapp\.com\//],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// Initialize GTM tag, no need to change ID unless we change GTM tag
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
}
TagManager.initialize(tagManagerArgs)

createRoot(root).render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}>
    <BrowserRouter>
      <LoadingProvider>
        <AccountProvider>
          <App />
        </AccountProvider>
      </LoadingProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>,
)
