// Library Imports
import React, { useContext } from "react"
import Button from "@mui/material/Button"

// Component Imports
import { ErrorContext } from "../../helper/AlertContext"
import { datasetService } from "../../api/services"

function FileDecoder({
  localLoading,
  setLocalLoading,
  onUploadSuccess,
  onUploadFailure,
  textTitle,
  textData,
  selectedFile,
  selectedSource,
  isAdding
}) {
  const errorContext = useContext(ErrorContext)

  const handleUpload = async (selectedSource) => {
    if (selectedSource === "text") {
      setLocalLoading(true)

      const textBlob = new Blob([textData], { type: "text/plain" })
      const limitedTextTitle = textTitle.substring(0, 30) + ".txt"
      const formData = new FormData()
      formData.append("file", textBlob, `${limitedTextTitle}`)
      formData.append("is_continuity", false)

      try {
        const response = await datasetService.uploadFile(formData)
        onUploadSuccess(
          {
            source: "text",
            type: "text",
            url: response.data.text.url,
            valid: true,
            fileName: `${limitedTextTitle}`,
            textTitle: `${limitedTextTitle}`,
            textData: textData,
            autoAdd: true,
          },
          "text",
        )
      } catch (error) {
        onUploadFailure(error)
      }
    } else if (selectedSource === "upload") {
      if (!selectedFile || selectedFile.length === 0) return
      const fileSizeLimit = 10 * 1024 * 1024
      const fileExceedsLimit = Array.from(selectedFile).some((file) => file.size > fileSizeLimit)

      if (fileExceedsLimit) {
        errorContext.setError(true)
        errorContext.setErrorMsg("One or more files exceed the size limit.")
        return
      }

      setLocalLoading(true)
      const uploads = []

      for (let i = 0; i < selectedFile.length; i++) {
        const file = selectedFile[i]
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)

        try {
          const evt = await new Promise((resolve, reject) => {
            reader.onload = resolve
            reader.onerror = () => reject(new Error("Failed to read file."))
          })

          const arrayBuffer = evt.target.result
          const mimeType = file.type

          const formData = new FormData()
          formData.append("file", new Blob([arrayBuffer], { type: mimeType }), file.name)
          formData.append("is_continuity", false)

          const uploadPromise = datasetService
            .uploadFile(formData)
            .then((response) =>
              onUploadSuccess(
                {
                  source: file.type.includes("csv") ? "csv" : "file",
                  type: file.type.includes("csv") ? "csv" : "file",
                  url: response.data.text.url,
                  valid: true,
                  fileName: file.name,
                  autoAdd: true,
                },
                file.type.includes("csv") ? "csv" : "file",
              ),
            )
            .catch((error) => onUploadFailure(error))

          uploads.push(uploadPromise)
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error)
          onUploadFailure(error)
        }
      }

      // Wait for all uploads to complete
      await Promise.all(uploads)
    }
  }

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => handleUpload(selectedSource)}
        disabled={
          ((!textTitle || !textData) && (!selectedFile || (selectedFile && selectedFile.length === 0))) || localLoading || isAdding
        }
      >
        {(localLoading || isAdding) ? "Processing..." : "Add Source"}
      </Button>
    </div>
  )
}

export default FileDecoder
