// Library Imports
import React, { useEffect, useState } from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

import PlanChip from "./PlanChip"

export default function SelectField({
  options = [],
  value,
  onChange,
  disabled,
  error = false,
  sx,
  label,
  divStyle,
  placeholder = "Select...",
}) {
  const [defaultValue, setDefaultValue] = useState("")

  useEffect(() => {
    setDefaultValue(value)
  }, [value])

  return (
    <div style={divStyle}>
      <FormControl variant="outlined" disabled={disabled}>
        <InputLabel>{label}</InputLabel>
        <Select value={defaultValue} onChange={onChange} displayEmpty sx={sx} label={label} error={error}>
          <MenuItem value="">
            <em style={{ color: "#3d3d3d" }}>{placeholder}</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <span>{option.label}</span>
              {option.badge && <PlanChip plan={option.badge} />}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
