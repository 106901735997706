import request from "../axios"

const requests = {
  login: (payload) => {
    return request.post("/login", payload)
  },
  logout: () => {
    return request.post("/logout")
  },
  checkAccount: (payload) => {
    return request.post("/check_account", payload, { credentials: "omit" })
  },
  createAccount: (payload) => {
    return request.post("/create_account", payload)
  },
  getAccount: (config = {}) => {
    return request.get("/account", config)
  },
  getTaskList: () => {
    return request.get("/user_trace")
  },
  updateTaskList: (payload) => {
    // warn: this PUT works as PATCH
    return request.put("/user_trace", payload)
  },
}

export default requests
