// Essential Imports
import React, { useState, useEffect, useContext } from "react"
import { useNavigate, Link } from "react-router-dom"

// Component Imports
import { AuthContext } from "../helper/AuthProvider"
import { LoadingContext } from "../helper/LoadingContext"
import { SuccessContext, ErrorContext } from "../helper/AlertContext"
import InputField from "../components/items/InputField"
import GoogleLoginComponent from "../components/items/GoogleLogin"
import { isEnterpriseUser } from "../utils"
import { CUSTOM_ERR_MSG } from "../utils/constants"
import { accountService } from "../api/services"

// Library Imports
import Button from "@mui/material/Button"
import CheckIcon from "@mui/icons-material/Check"

// Stylesheet Imports
import "../styles/Login.css"

export default function Signup({ setProfile, url }) {
  const { setAuth } = useContext(AuthContext)
  const successContext = useContext(SuccessContext)
  const errorContext = useContext(ErrorContext)
  const loadingContext = useContext(LoadingContext)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [emailTouched, setEmailTouched] = useState(false)
  const [emailIsValid, setEmailIsValid] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const navigate = useNavigate()

  // Validate email on field changes
  useEffect(() => {
    validateEmail(email)
    setButtonDisabled(!(firstName && lastName && emailIsValid))
  }, [firstName, lastName, email, emailIsValid])

  const validateEmail = (email) => {
    let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    setEmailIsValid(reg.test(email))
  }

  const handleEmailBlur = () => {
    setEmailTouched(true)
  }

  // Get country name using ipapi free api for user profiling
  async function getCountry(retries = 3) {
    while (retries > 0) {
      try {
        const response = await fetch("https://ipapi.co/json/")
        if (!response.ok) throw new Error(`HTTP status ${response.status}`)
        const data = await response.json()
        return data.country_name
      } catch (error) {
        console.error(`Failed to fetch country: ${error.message}`)
        retries--
        if (retries === 0) return null
      }
    }
  }

  const signup = async () => {
    let modified_error = ""

    loadingContext.setIsLoading(true)
    try {
      const ip_country = await getCountry()
      const createAccountData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        avatar_url: `https://ui-avatars.com/api/?bold=true&name=${encodeURIComponent(
          firstName.charAt(0),
        )}&background=random`,
        ip_country,
        time_zone: null,
        language: null,
        account_type: "email",
      }

      await accountService.createAccount(createAccountData)
      successContext.setSuccess(true)
      successContext.setSuccessMsg("Activate your account via the email sent to your inbox.")
      navigate("/signup-success")
    } catch (error) {
      if (error.response.data.text === "account is already exit , plz login with: google") {
        modified_error = CUSTOM_ERR_MSG.signup_google_exist
      } else if (error.response.data.text === "account is already exit , plz login with: email") {
        modified_error = CUSTOM_ERR_MSG.signup_email_exist
      } else {
        modified_error = error.message
      }
      errorContext.setError(true)
      errorContext.setErrorMsg(modified_error)
    } finally {
      loadingContext.setIsLoading(false)
    }
  }

  // If login is successful
  const finalizeLogin = (user) => {
    localStorage.setItem("profile", JSON.stringify(user))
    document.cookie = "userLoggedIn=true;path=/;"
    setProfile(user)
    setAuth(true)
    // navigate("/dashboard");

    const oauthUrl = sessionStorage.getItem("oauthUrl")
    if (oauthUrl) {
      navigate(oauthUrl)
      sessionStorage.removeItem("oauthUrl")
    } else {
      navigate("/dashboard")
    }

    loadingContext.setIsLoading(false)
  }

  return (
    <div className="portal-container">
      <img className="login-logo" src="/images/vext_logo.png" alt="vext logo" />
      <div className="portal-wrap">
        <div className="portal-decor-wrap">
          <div
            style={{
              boxShadow: "0px 2px 4px -1px rgba(61, 61, 61, 0.6)",
              borderRadius: "4px",
              padding: "2rem",
              backgroundColor: "#fff",
            }}
          >
            <h1>Build LLM Pipeline For Free</h1>
            <p style={{ margin: "auto", paddingTop: "0.5rem" }}>
              <CheckIcon style={{ fontSize: "0.7rem", color: "#24DD88" }} />
              No credit card required
            </p>
            <p style={{ margin: "auto", paddingTop: "0.5rem" }}>
              <CheckIcon style={{ fontSize: "0.7rem", color: "#24DD88" }} />
              Up to 100 queries per month with basic LLM models
            </p>
            <p style={{ margin: "auto", paddingTop: "0.5rem" }}>
              <CheckIcon style={{ fontSize: "0.7rem", color: "#24DD88" }} />
              Set up your LLM pipeline within minutes
            </p>
            {/* <p style={{ margin: "auto", paddingTop: "0.5rem" }}>
              <CheckIcon style={{ fontSize: "0.7rem", color: "#24DD88" }} /> GDPR Compliant
            </p>
            <p style={{ margin: "auto", paddingTop: "0.5rem" }}>
              <CheckIcon style={{ fontSize: "0.7rem", color: "#24DD88" }} /> CASA Tier 2 Certified
            </p> */}
          </div>
        </div>
        <div className="login-wrap">
          <div className="login-container">
            <p style={{ marginBottom: "1.5rem", fontSize: "1.2rem", fontWeight: "700" }}>Start For Free</p>
            {isEnterpriseUser() ? null : (
              <div className="sso-wrap signup">
                <GoogleLoginComponent url={url} finalizeLogin={finalizeLogin} getCountry={getCountry} />
                <div className="signup-title">
                  <hr />
                  <p>or signup with email</p>
                </div>
              </div>
            )}
            <div
              style={{
                width: "100%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "0.6rem",
                      fontWeight: "700",
                      marginBottom: "0.3rem",
                    }}
                  >
                    First Name
                  </p>
                  <InputField
                    placeholder={"First Name"}
                    onChange={setFirstName}
                    mLength={50}
                    disabled={loadingContext.isLoading}
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontSize: "0.6rem",
                      fontWeight: "700",
                      marginBottom: "0.3rem",
                    }}
                  >
                    Last Name
                  </p>
                  <InputField
                    placeholder={"Last Name"}
                    onChange={setLastName}
                    mLength={50}
                    disabled={loadingContext.isLoading}
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontSize: "0.6rem",
                      fontWeight: "700",
                      marginBottom: "0.3rem",
                    }}
                  >
                    Email
                  </p>
                  <InputField
                    placeholder={"Email"}
                    onChange={setEmail}
                    onBlur={handleEmailBlur}
                    type="email"
                    invalid={emailTouched && !emailIsValid}
                    disabled={loadingContext.isLoading}
                    onPressEnter={(e) => {
                      if (!buttonDisabled) {
                        signup(e)
                      }
                    }}
                  />
                </div>
                <i style={{ fontSize: "0.7rem", justifyContent: "center" }}>
                  You will set up your password upon activation.
                </i>
                <Button
                  variant="contained"
                  style={{ width: "100%" }}
                  disabled={buttonDisabled || loadingContext.isLoading}
                  onClick={signup}
                >
                  {loadingContext.isLoading ? "Loading..." : "Sign Up"}
                </Button>
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                  <p style={{ display: "inline-block", fontSize: "0.7rem" }}>
                    By signing up, you agree to our{" "}
                    <a
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: "0.7rem",
                      }}
                      onClick={(event) => {
                        event.preventDefault()
                        window.open("https://vextapp.com/terms", "_blank")
                      }}
                    >
                      Terms
                    </a>{" "}
                    and{" "}
                    <a
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: "0.7rem",
                      }}
                      onClick={(event) => {
                        event.preventDefault()
                        window.open("https://vextapp.com/privacy", "_blank")
                      }}
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                  <p style={{ display: "inline-block", fontSize: "0.7rem" }}>
                    <Link to="/login" style={{ marginTop: "1rem", fontSize: "0.7rem", textDecoration: "none" }}>
                      Already have an account? Log in.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
