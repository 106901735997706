import React from "react"

// Essential Imports
import { Link } from "react-router-dom"

// Component Imports
import Chips from "../items/Chips"

// Library Imports
import DashboardIcon from "@mui/icons-material/Dashboard"
import StorageIcon from "@mui/icons-material/Storage"
import LayersIcon from "@mui/icons-material/Layers"
import AppsIcon from "@mui/icons-material/Apps"
import HistoryIcon from "@mui/icons-material/History"
import KeyIcon from "@mui/icons-material/Key"

// Stylesheet Imports
import "../../styles/Navbar.css"

export default function Navbar() {
  return (
    <div className="navbar">
      <div className="nav-content">
        <div className="nav-logo">
          <Link to="/dashboard">
            <img className="logo" src="/images/vext_logo.png" alt="vext logo" />
          </Link>
        </div>
        <div className="navbar-links">
          <Link to="/dashboard">
            <DashboardIcon fontSize="small" /> Dashboard
          </Link>
          <Link to="/ai-projects">
            <LayersIcon fontSize="small" /> AI Projects
          </Link>
          <Link to="/data">
            <StorageIcon fontSize="small" /> Data Sets
          </Link>
          <Link className="link" to="/logs">
            <HistoryIcon fontSize="small" /> Logs
          </Link>
          <Link to="/api-keys">
            <KeyIcon fontSize="small" /> API Keys
          </Link>
          <Link className="link" to="/app-directory">
            <AppsIcon fontSize="small" /> App Directory
          </Link>
        </div>
      </div>
      <div className="navFooter">
        <Link
          to="https://docs.google.com/forms/d/e/1FAIpQLSf9zsM9uHOCchBL1bwwp8goHMw9edTIm6e6sCb4RI7EbOZrIA/viewform"
          target="_blank"
        >
          Report Bug
        </Link>
      </div>
    </div>
  )
}
