// Essential Imports
import React from "react"

// Library Imports
import LinearProgress from "@mui/material/LinearProgress"

function MyProgressBar({ count }) {
  return (
    <LinearProgress
      variant="determinate"
      value={count}
      sx={{
        color: count > 70 ? "#F44336" : "#3d3d3d",
        backgroundColor: "#3d3d3d80",
        "& .MuiLinearProgress-barColorPrimary": {
          backgroundColor: count > 70 ? "#F44336" : "#3d3d3d",
        },
      }}
    />
  )
}

export default MyProgressBar
