import React, { useState } from "react"
import { Button, IconButton } from "@mui/material"
import { MoreHorizOutlined as MoreHorizIcon } from "@mui/icons-material"
import { ClickAwayListener } from "@mui/base"

import AccountTabsStyles from "../../styles/AccountTabs.module.css"

const WorkspaceRow = ({ creditLimit, currentCredit, name, handleEdit, handleRemove }) => {
  const [showDropdown, setShowDropdown] = useState(false)

  const toggleActionDialog = () => {
    setShowDropdown(!showDropdown)
  }

  return (
    <>
      <div>{name.trim() || "-"}</div>
      <div style={{ justifyContent: "flex-end", gap: 4 }}>
        <span>{currentCredit.toLocaleString()}</span>/<span>{creditLimit ? creditLimit.toLocaleString() : "-"}</span>
      </div>
      <div style={{ position: "relative" }}>
        <IconButton onClick={toggleActionDialog}>
          <MoreHorizIcon />
        </IconButton>
        {showDropdown && (
          <ClickAwayListener onClickAway={toggleActionDialog}>
            <div className={AccountTabsStyles.actionDialog}>
              <Button onClick={handleEdit} sx={{ justifyContent: "start", textTransform: "none", width: "100%" }}>
                Edit Workspace
              </Button>
              <Button
                onClick={() => {
                  handleRemove()
                  toggleActionDialog()
                }}
                sx={{ justifyContent: "start", textTransform: "none", width: "100%" }}
                color="error"
              >
                Delete Workspace
              </Button>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </>
  )
}

export default WorkspaceRow
