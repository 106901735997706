import React, { useEffect, useState } from "react"
import { Button, IconButton } from "@mui/material"
import { Check as CheckIcon, MoreHorizOutlined as MoreHorizIcon, TimerOutlined as TimerIcon } from "@mui/icons-material"
import { ClickAwayListener } from "@mui/base"

import SelectField from "./SelectField"

import AccountTabsStyles from "../../styles/AccountTabs.module.css"

const MemberRow = ({
  userId,
  name,
  email,
  isOwner,
  ownerLabel = "Owner",
  role,
  roleOptions = [],
  status,
  handleUpdateUser = () => {},
  handleReinvite = () => {},
  handleRemoveUser,
}) => {
  const [roleInput, setRoleInput] = useState("")
  const [showDropdown, setShowDropdown] = useState(false)

  const statusMapping = {
    a: {
      text: "Accepted",
      icon: <CheckIcon style={{ color: "#24dd88", fontSize: "0.8rem" }} />,
    },
    p: {
      text: "Pending",
      icon: <TimerIcon color="warning" style={{ fontSize: "0.8rem" }} />,
    },
    active: {
      text: "Active",
      icon: <CheckIcon style={{ color: "#24dd88", fontSize: "0.8rem" }} />,
    },
    inactive: {
      text: "Inactive",
      icon: <TimerIcon color="warning" style={{ fontSize: "0.8rem" }} />,
    },
  }
  const handleOnChange = async (event) => {
    handleUpdateUser({ userId, roleId: event.target.value })
  }
  const toggleActionDialog = () => {
    setShowDropdown(!showDropdown)
  }

  useEffect(() => {
    setRoleInput(role)
  }, [role])

  return (
    <>
      <div>{name.trim() || "-"}</div>
      <div>{email}</div>
      <div style={{ gap: 8 }}>
        {statusMapping[status].icon}
        <span>{statusMapping[status].text}</span>
      </div>
      <div style={{ position: "relative" }}>
        <SelectField
          divStyle={{ width: "100%" }}
          options={isOwner ? [{ label: ownerLabel, value: "owner" }] : roleOptions}
          value={isOwner ? "owner" : roleInput}
          onChange={handleOnChange}
          disabled={status === "p" || isOwner}
        />
        <IconButton onClick={toggleActionDialog}>
          <MoreHorizIcon />
        </IconButton>
        {showDropdown && (
          <ClickAwayListener onClickAway={toggleActionDialog}>
            <div className={AccountTabsStyles.actionDialog}>
              {status === "p" && (
                <Button
                  onClick={() => {
                    handleReinvite(email, role)
                    toggleActionDialog()
                  }}
                  sx={{ justifyContent: "start", textTransform: "none", width: "100%" }}
                >
                  Re-invite
                </Button>
              )}
              <Button
                onClick={() => {
                  handleRemoveUser()
                  toggleActionDialog()
                }}
                sx={{ justifyContent: "start", textTransform: "none", width: "100%" }}
                color="error"
                disabled={isOwner}
              >
                Remove
              </Button>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </>
  )
}

export default MemberRow
