import React, { useCallback, useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Button, InputAdornment, Skeleton, TextField } from "@mui/material"
import { Search as SearchIcon } from "@mui/icons-material"
import { Can } from "@casl/react"
import { debounce } from "lodash"

import { LoadingContext } from "../helper/LoadingContext"
import { ErrorContext } from "../helper/AlertContext"
import { AbilityContext } from "../helper/AbilityContext"
import AppItem from "../components/items/AppItem"
import CustomPagination from "../components/items/Pagination"
import EmptyState from "../components/items/EmptyState"
import { appService } from "../api/services"
import TemplateDialog from "../components/items/TemplateDialog"

import "../styles/Create.css"

const PER_PAGE = 11

export default function Apps() {
  const [apps, setApps] = useState([])
  const [appResults, setAppResults] = useState([])
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const [page, setPage] = useState(1)
  const [showProjectDialog, setShowProjectDialog] = useState(false)
  const ability = useContext(AbilityContext)
  const errorContext = useContext(ErrorContext)
  const loadingContext = useContext(LoadingContext)
  const navigate = useNavigate()

  // Get all app onload, and desc sort by date
  const getApps = async (params) => {
    loadingContext.setIsLoading(true)
    try {
      const response = await appService.getProjectList({ per_page: PER_PAGE, ...params })
      const sortedApps = response.data.results
        .map((app) => {
          const created_time = parseInt(app.created_time)
          const updated_time = parseInt(app.updated_time)
          return { ...app, created_time, updated_time }
        })
        .sort((a, b) => b.updated_time - a.updated_time)

      setApps(response.data)
      setAppResults(sortedApps)
      setIsDataLoaded(true)
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      loadingContext.setIsLoading(false)
    }
  }
  const toggleProjectDialog = () => {
    setShowProjectDialog(!showProjectDialog)
  }
  const debouncedGetApps = useCallback(
    debounce((params) => {
      setSearchInput(params.query)
      setPage(1)
      getApps({ ...params, page: 1 })
    }, 500),
    [],
  )
  const handleSearchQuery = async (event) => {
    const query = event.target.value
    debouncedGetApps({ query })
  }

  useEffect(() => {
    getApps()
  }, [])

  return (
    <div>
      {appResults.length || searchInput ? (
        <div style={{ marginBottom: "1rem", textAlign: "right" }}>
          <TextField
            autoComplete="off"
            onChange={handleSearchQuery}
            placeholder="Search Project"
            sx={{ width: "18rem" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      ) : null}
      {loadingContext.isLoading ? (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
          <Skeleton variant="rounded" animation="wave" height={180} sx={{ width: "100%" }} />
          <Skeleton variant="rounded" animation="wave" height={180} sx={{ width: "100%" }} />
          <Skeleton variant="rounded" animation="wave" height={180} sx={{ width: "100%" }} />
        </div>
      ) : appResults.length > 0 || searchInput ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "1rem",
              marginBottom: "2rem",
            }}
          >
            <Can I="create" a="Project" ability={ability}>
              <div className="responsiveListCard">
                <div className="addProject">
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate("/ai-projects/create")
                      }}
                    >
                      Build Your Own
                    </Button>
                    <Button variant="outlined" sx={{ backgroundColor: "#fff" }} onClick={toggleProjectDialog}>
                      Use a Template
                    </Button>
                  </div>
                </div>
              </div>
            </Can>
            {appResults.map((app) => (
              <div className="responsiveListCard" key={app.id}>
                <AppItem app={app} toggle={!app.is_close} getApps={getApps} />
              </div>
            ))}
          </div>
          <CustomPagination
            page={page}
            setPage={setPage}
            count={Math.ceil(apps.count / PER_PAGE) || 1}
            setData={setApps}
            callFunction={(params) => getApps({ ...params, query: searchInput })}
          />
        </>
      ) : (
        isDataLoaded && <EmptyState />
      )}
      <TemplateDialog open={showProjectDialog} handleOnClose={toggleProjectDialog} />
    </div>
  )
}
