// Library Imports
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"

import { SuccessContext, ErrorContext } from "../../helper/AlertContext"
import { appService } from "../../api/services"
import LocalLoadingBar from "../../components/items/LocalLoadingBar"


const TemplateDialog = ({ open, handleOnClose }) => {
  const [templateList, setTemplateList] = useState([])
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { setSuccess, setSuccessMsg } = useContext(SuccessContext)
  const [localLoading, setLocalLoading] = useState(false)
  const navigate = useNavigate()

  const fetchTemplateList = async () => {
    setLocalLoading(true)
    const { data } = await appService.getTemplateList()
    setTemplateList(data.results)
    setLocalLoading(false)
  }
  const createTemplate = async (appId) => {
    setLocalLoading(true)
    try {
      const { data } = await appService.createTemplate(appId)

      navigate(`/ai-projects/${data.id}`)
      setLocalLoading(false)
      handleOnClose()
      setSuccess(true)
      setSuccessMsg("AI project created")
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    }
  }

  useEffect(() => {
    fetchTemplateList()
  }, [])

  return (
    <Dialog
      onClose={handleOnClose}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "42rem",
          },
        },
      }}
    >
      <div style={{ padding: "2rem", textAlign: "center" }}>
        <LocalLoadingBar localLoading={localLoading}/>
        <DialogTitle sx={{ p: "0 0 1rem" }}>
          <span style={{ fontSize: "1.3rem", fontWeight: 700 }}>Choose a template</span>
          <IconButton sx={{ position: "absolute", m: 1, top: 0, right: 0 }} color="primary" onClick={handleOnClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ maxHeight: 432, p: 0 }}>
          <p
            style={{ fontSize: "1rem", justifyContent: "center", padding: "1rem 0 2rem" }}
          >{`We've created these templates to help you get started with your own flow.`}</p>
          <div style={{ display: "grid", gap: "1rem", gridTemplateColumns: "1fr 1fr" }}>
            {templateList.map((template, index) => (
              <div
                key={index}
                style={{ 
                  border: localLoading ? "#fff 1px solid" : "#3d3d3d80 1px solid", 
                  borderRadius: "4px", 
                  cursor: localLoading ? "default" : "pointer", 
                  padding: "1rem",
                  backgroundColor: localLoading ? "#3d3d3d30" : "transparent",
                }}
                onClick={() => {
                  if (!localLoading) {
                    createTemplate(template.id)
                  }
                }}
              >
                <h4 style={{ margin: "0 0 1rem", justifyContent: "center", color: localLoading ? "#3d3d3d50" : "inherit" }}>{template.name}</h4>
                <p style={{ justifyContent: "center", color: localLoading ? "#3d3d3d50" : "inherit" }}>{template.description}</p>
              </div>
            ))}
          </div>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default TemplateDialog
