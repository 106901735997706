import React, { useEffect, useState } from "react"
import { Autocomplete, Chip, TextField } from "@mui/material"

import BasicTooltip from "../items/BasicTooltip"
import SelectField from "../items/SelectField"

const CreateMemberForm = ({ options = [], orgMemberList = [], wsMemberList = [], onChange = () => {} }) => {
  const [roleInput, setRoleInput] = useState("")
  const [userEmailInput, setUserEmailInput] = useState("")
  const [userEmails, setUserEmails] = useState([])
  const [hasOrganization, setHasOrganization] = useState(false)

  const handleOnChange = (event) => {
    setRoleInput(event.target.value)
  }

  useEffect(() => {
    setHasOrganization(orgMemberList.length > 0)
  }, [orgMemberList.length])
  useEffect(() => {
    if (hasOrganization) {
      onChange({ role: roleInput, email: userEmails.map((user) => user.email).join(",") })
    } else {
      onChange({ role: roleInput, email: userEmailInput })
    }
  }, [roleInput, userEmailInput, userEmails, hasOrganization])

  return (
    <div>
      <h4 style={{ marginTop: 0 }}>
        Email(s)
        <BasicTooltip tooltip="Invite multiple members at the same time by separating email addresses with a comma." />
      </h4>
      {hasOrganization ? (
        <Autocomplete
          multiple
          disableClearable
          getOptionLabel={(option) => option.email}
          filterOptions={(options, { inputValue }) => {
            const filterValue = inputValue.toLowerCase()
            return options.filter(
              (option) =>
                option.name.toLowerCase().includes(filterValue) || option.email.toLowerCase().includes(filterValue),
            )
          }}
          onChange={(_, value) => {
            setUserEmails(value)
          }}
          options={orgMemberList.filter((member) => !wsMemberList.some((user) => user.id === member.id))}
          renderInput={(params) => (
            <TextField {...params} placeholder={userEmails.length ? "" : "Enter name or email"} />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{ fontSize: "0.7rem" }}>
                <div>{option.name}</div>
                <div>{option.email}</div>
              </div>
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { ...tagProps } = getTagProps({ index })
              return <Chip variant="outlined" label={option.email} key={option.email} {...tagProps} />
            })
          }
          value={userEmails}
        />
      ) : (
        <TextField
          onChange={(event) => setUserEmailInput(event.target.value)}
          value={userEmailInput}
          placeholder="e.g. hello@email.com, world@email.com"
          inputProps={{ maxLength: 255 }}
        />
      )}
      <h4>Role</h4>
      <SelectField options={options} value={roleInput} onChange={handleOnChange} />
    </div>
  )
}

export default CreateMemberForm
