// Essential Imports
import React, { useState, useEffect, useContext } from "react"

// Component Imports
import { ErrorContext } from "../../helper/AlertContext"
import request from "../../api/axios"

// Library Imports
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import Skeleton from "@mui/material/Skeleton"

export default function DataDetailConfluence({
  localLoading,
  setLocalLoading,
  setIntegrationFileName,
  setIntegrationSiteId,
  setIntegrationFileId,
  setDataValid,
}) {
  const errorContext = useContext(ErrorContext)
  const [userId, setUserId] = useState(null)
  const [authenticating, setAuthenticating] = useState(true)
  const [buttonText, setButtonText] = useState("Connect Confluence")
  const [sites, setSites] = useState([])
  const [spaces, setSpaces] = useState([])
  const [pages, setPages] = useState([])
  const [nextPageToken, setNextPageToken] = useState(null)
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [inputSites, setInputSites] = useState(null)
  const [inputSpaces, setInputSpaces] = useState(null)
  const [inputPages, setInputPages] = useState(null)

  let windowObjectReference = null
  let previousUrl = null

  const stateMap = {
    sites: setSites,
    spaces: setSpaces,
    pages: setPages,
  }

  // Function to fetch Confluence Site
  const fetchConfluenceSite = async (
    nextPageToken = null,
    newQuery = "",
    loadType,
    type = "sites",
    siteId = null,
    spaceId = null,
  ) => {
    const setData = stateMap[type]

    if (loadType === "newLoad") {
      setData([])
      setNextPageToken(null)
    }

    setLocalLoading(true)

    let endpoint = `/third_party/confluence/${type}/50`

    if (siteId) {
      endpoint += `/${siteId}`
    } else if (inputSites) {
      endpoint += `/${inputSites.id}`
    } else {
      endpoint += `/1`
    }

    if (spaceId) {
      endpoint += `/${spaceId}`
    }

    let params = []

    if (nextPageToken) {
      params.push(`next_page_token=${nextPageToken}`)
    }
    // if (newQuery) {
    //   params.push(`q=${newQuery}`);
    // }
    if (params.length > 0) {
      endpoint += `?${params.join("&")}`
    }

    try {
      const response = await request.get(endpoint)

      let dataToSet = response.data.text

      if (type === "spaces" || type === "pages") {
        dataToSet = response.data.text.results
      }

      if (dataToSet) {
        if (isSearchActive) {
          setData(dataToSet)
        } else {
          setData((prevItems) => [...prevItems, ...dataToSet])
        }
        if (response.data.text._links && response.data.text._links.next) {
          const match = response.data.text._links.next.match(/cursor=([^&]*)/)
          const cursorValue = match ? match[1] : null
          setNextPageToken(cursorValue)
        } else {
          setNextPageToken(null)
        }
      }
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      setLocalLoading(false)
    }
  }

  // Infinite scroll for Autocomplete
  const handleScroll = (event, type) => {
    if (localLoading) return

    const { scrollTop, clientHeight, scrollHeight } = event.target
    const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight * 0.75
    if (isBottom && nextPageToken) {
      setIsSearchActive(false)
      fetchConfluenceSite(nextPageToken, "", "continueLoad", type)
    }
  }

  // Handle search
  // Confluence doesn't seems to have search function yet, check in the future

  // Check if users already authenticated
  useEffect(() => {
    const fetchUserId = request.get(`/account`)
    const fetchOauthStatus = request.get(`/third_party/confluence/is_need_confluence_oauth`)

    Promise.all([fetchUserId, fetchOauthStatus])
      .then(([userIdResponse, oauthStatusResponse]) => {
        setUserId(userIdResponse.data.id)
        if (!oauthStatusResponse.data.text) {
          setButtonText("Authenticated")
          fetchConfluenceSite(null, "", "newLoad")
        }
        setAuthenticating(false)
      })
      .catch((error) => {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      })

    return () => {
      clearTimeout(searchTimeout)
    }
  }, [])

  // Trigger receive message from pop up
  const receiveMessage = async (event) => {
    const { data, origin } = event

    // Verify the source and origin
    if (!data || data.source !== "Vext Authentication" || origin !== window.location.origin) {
      return
    }

    const params = new URLSearchParams(String(data.payload).slice(1))
    const code = params.get("code")
    window.removeEventListener("message", receiveMessage)

    try {
      const response = await request.post(`/third_party/confluence/oauth`, {
        code: code,
      })

      if (response.data.text === "ok") {
        setButtonText("Authenticated")
        fetchConfluenceSite(null, "", "newLoad", "sites")
      }
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    }
  }

  // Start Google authentication process
  const authenticateConfluence = () => {
    window.removeEventListener("message", receiveMessage)

    const authUrl = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${encodeURIComponent(
      process.env.REACT_APP_CONFLUENCE_CLIENT_ID,
    )}&scope=${encodeURIComponent(
      "read:content:confluence read:content-details:confluence read:space-details:confluence read:page:confluence read:blogpost:confluence read:label:confluence read:user:confluence read:content.property:confluence read:content.restriction:confluence read:content.metadata:confluence read:space.property:confluence read:space:confluence offline_access",
    )}&redirect_uri=${encodeURIComponent(
      `${window.location.origin}/oauth-redirect`,
    )}&response_type=code&state=confluence&prompt=consent`
    const name = "Confluence Authentication"
    const strWindowFeatures = "toolbar=no, menubar=no, width=980, height=720, top=100, left=100"

    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(authUrl, name, strWindowFeatures)
    } else if (previousUrl !== authUrl) {
      windowObjectReference = window.open(authUrl, name, strWindowFeatures)
      windowObjectReference.focus()
    } else {
      windowObjectReference.focus()
    }

    // add the listener for receiving a message from the popup
    window.addEventListener("message", receiveMessage, false)
    // assign the previous URL
    previousUrl = authUrl
  }

  return (
    <div style={{ display: "flex", width: "100%", gap: "1rem" }}>
      {buttonText === "Authenticated" ? (
        <div style={{ width: "100%" }}>
          {/* Site */}
          <h5>Site</h5>
          <Autocomplete
            value={inputSites}
            options={sites}
            getOptionLabel={(option) => `${option.name || ""}`}
            isOptionEqualToValue={(option, value) => sites.some((site) => site.id === value.id)}
            renderOption={(props, option) => (
              <li {...props} key={`${option.id}`}>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>{option.name}</div>
                    <small style={{ color: "#3d3d3d80" }}>{option.id}</small>
                  </div>
                </div>
              </li>
            )}
            renderInput={(params) => <TextField {...params} placeholder="Select Site..." />}
            sx={{ width: "100%" }}
            ListboxProps={{
              onScroll: (event) => handleScroll(event, "sites"),
            }}
            // onInputChange={handleSearch}
            onChange={(event, value) => {
              if (value) {
                setSpaces([])
                setPages([])
                setInputPages(null)
                setInputSpaces(null)
                fetchConfluenceSite(null, "", "newLoad", "spaces", value.id)
                setInputSites(value)
              } else {
                setInputSites(null)
                setInputSpaces(null)
                setInputPages(null)
                setDataValid(false)
                setIntegrationSiteId("")
                setIntegrationFileId("")
                setIntegrationFileName("")
              }
            }}
            noOptionsText={
              localLoading ? (
                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                  <Skeleton variant="text" animation="wave" width="100%" height={30} />
                  <Skeleton variant="text" animation="wave" width="100%" height={20} />
                </div>
              ) : (
                "No Result"
              )
            }
          />

          {/* Space */}
          <h5>Space</h5>
          <Autocomplete
            value={inputSpaces}
            options={spaces}
            getOptionLabel={(option) => `${option.name || ""}`}
            isOptionEqualToValue={(option, value) => spaces.some((space) => space.id === value.id)}
            renderOption={(props, option) => (
              <li {...props} key={`${option.id}`}>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>{option.name}</div>
                    <small style={{ color: "#3d3d3d80" }}>{option.id}</small>
                  </div>
                </div>
              </li>
            )}
            renderInput={(params) => <TextField {...params} placeholder="Select Space..." />}
            sx={{ width: "100%" }}
            ListboxProps={{
              onScroll: (event) => handleScroll(event, "spaces"),
            }}
            // onInputChange={handleSearch}
            onChange={(event, value) => {
              if (value) {
                setPages([])
                setInputPages(null)
                fetchConfluenceSite(null, "", "newLoad", "pages", null, value.id)
                setInputSpaces(value)
              } else {
                setInputSpaces(null)
                setInputPages(null)
                setDataValid(false)
                setIntegrationSiteId("")
                setIntegrationFileId("")
                setIntegrationFileName("")
              }
            }}
            noOptionsText={
              localLoading ? (
                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                  <Skeleton variant="text" animation="wave" width="100%" height={30} />
                  <Skeleton variant="text" animation="wave" width="100%" height={20} />
                </div>
              ) : (
                "No Result"
              )
            }
            disabled={!inputSites || inputSites.length === 0}
          />

          {/* Page */}
          <>
            <h5>Page</h5>
            <Autocomplete
              value={inputPages}
              options={pages}
              getOptionLabel={(option) => `${option.title || ""}`}
              isOptionEqualToValue={(option, value) => pages.some((page) => page.id === value.id)}
              renderOption={(props, option) => (
                <li {...props} key={`${option.id}`}>
                  <div
                    style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div>{option.title}</div>
                      <small
                        style={{
                          color: "#3d3d3d80",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "22rem",
                        }}
                      >
                        {option._links.webui}
                      </small>
                    </div>
                  </div>
                </li>
              )}
              renderInput={(params) => <TextField {...params} placeholder="Select Page..." />}
              sx={{ width: "100%" }}
              ListboxProps={{
                onScroll: (event) => handleScroll(event, "pages"),
              }}
              // onInputChange={handleSearch}
              onChange={(event, value) => {
                if (value) {
                  setInputPages(value)
                  setDataValid(true)
                  setIntegrationFileName(value.title)
                  setIntegrationSiteId(inputSites.id)
                  setIntegrationFileId(value.id)
                } else {
                  setDataValid(false)
                  setIntegrationSiteId("")
                  setIntegrationFileId("")
                  setIntegrationFileName("")
                  setInputSites(null)
                  setInputSpaces(null)
                  setInputPages(null)
                }
              }}
              noOptionsText={
                localLoading ? (
                  <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                    <Skeleton variant="text" animation="wave" width="100%" height={30} />
                    <Skeleton variant="text" animation="wave" width="100%" height={20} />
                  </div>
                ) : (
                  "No Result"
                )
              }
              disabled={!inputSpaces || inputSpaces.length === 0}
            />
          </>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p
              onClick={() => {
                authenticateConfluence()
                setIntegrationSiteId("")
                setIntegrationFileId("")
                setIntegrationFileName("")
              }}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                marginTop: "1rem",
                fontSize: "0.7rem",
                justifyContent: "flex-end",
              }}
            >
              Switch Account
            </p>
          </div>
        </div>
      ) : (
        <Button
          variant="contained"
          onClick={authenticateConfluence}
          disabled={authenticating}
          style={{ width: "100%", marginTop: "1rem" }}
        >
          {authenticating ? <CircularProgress size={24} /> : buttonText}
        </Button>
      )}
    </div>
  )
}
