// Essential Imports
import React, { useState, useEffect, useContext } from "react"
import { useNavigate, Link, useParams, useMatch } from "react-router-dom"

// Component Imports
import { LoadingContext } from "../helper/LoadingContext"
import { SuccessContext, ErrorContext } from "../helper/AlertContext"
import InputField from "../components/items/InputField"
import { CUSTOM_ERR_MSG } from "../utils/constants"

// Library Imports
import request from "../api/axios"
import Button from "@mui/material/Button"

// Stylesheet Imports
import "../styles/Login.css"

export default function ResetPassword() {
  const { userId } = useParams()
  const loadingContext = useContext(LoadingContext)
  const successContext = useContext(SuccessContext)
  const errorContext = useContext(ErrorContext)
  const [email, setEmail] = useState(localStorage.getItem("email") || "")
  const [newPass, setNewPass] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false)
  const [isEmailBlurred, setEmailBlurred] = useState(false)

  let matchForgotPassword = useMatch("/forgot-password")
  let matchForgotPasswordWithId = useMatch("/forgot-password/:userId")

  const navigate = useNavigate()

  let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  useEffect(() => {
    if (email === "" || reg.test(email)) {
      setIsValidEmail(true)
    } else {
      setIsValidEmail(false)
    }
  }, [email])

  useEffect(() => {
    if (newPass === confirmPassword && newPass.length >= 12) {
      setIsPasswordConfirmed(true)
    } else {
      setIsPasswordConfirmed(false)
    }

    if (newPass.length >= 12) {
      setIsValidPassword(true)
    } else {
      setIsValidPassword(false)
    }
  }, [newPass, confirmPassword])

  const handleBlur = () => {
    setEmailBlurred(true)
  }

  const resetPassword = async () => {
    loadingContext.setIsLoading(true)
    try {
      await request.post(`/resend/forgot_password/${email}`)
      successContext.setSuccess(true)
      successContext.setSuccessMsg("Request sent, please check your inbox for further instructions.")
      navigate("/login")
    } catch (error) {
      let message = error.response.data.text

      if (message === "account is not activate, cannot resend email") {
        message = CUSTOM_ERR_MSG.reset_not_activated
      }
      errorContext.setError(true)
      errorContext.setErrorMsg(message)
    } finally {
      loadingContext.setIsLoading(false)
    }
  }

  const resetPasswordConfirm = async () => {
    loadingContext.setIsLoading(true)
    try {
      let password = {
        password: newPass,
      }

      await request.post(`/changepassword/${userId}`, password)
      successContext.setSuccess(true)
      successContext.setSuccessMsg("Password has been reset. You can now log in with your new password.")
      loadingContext.setIsLoading(false)
      navigate("/login")
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
      loadingContext.setIsLoading(false)
    }
  }

  return (
    <div className="login-wrap">
      <img className="login-logo" src="/images/vext_logo.png" alt="vext logo" />
      <div className="login-container">
        <div
          style={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {matchForgotPassword ? (
            <>
              {" "}
              {/* For request  */}
              <p style={{ margin: "auto", fontSize: "1.2rem", fontWeight: "700" }}>Reset Password</p>
              <div>
                <InputField
                  placeholder={"Email"}
                  onChange={setEmail}
                  onBlur={handleBlur}
                  invalid={!isValidEmail && isEmailBlurred}
                  value={email}
                  disabled={loadingContext.isLoading}
                  onPressEnter={(e) => {
                    if (email !== "" && isValidEmail && !loadingContext.isLoading) {
                      resetPassword(e)
                    }
                  }}
                />
              </div>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                disabled={email === "" || !isValidEmail || loadingContext.isLoading}
                onClick={resetPassword}
              >
                {loadingContext.isLoading ? "Loading..." : "Reset Password"}
              </Button>
              <Link to="/login" style={{ textDecoration: "none", fontSize: "0.7rem" }}>
                Back to login
              </Link>
            </>
          ) : null}
          {matchForgotPasswordWithId ? (
            <>
              {" "}
              {/* For reset process */}
              <p style={{ margin: "auto", fontSize: "1.2rem", fontWeight: "700" }}>Reset Password</p>
              <div>
                <p style={{ fontSize: "0.6rem", fontWeight: "700", marginBottom: "0.3rem" }}>Password</p>
                <InputField
                  placeholder={"Password"}
                  type="password"
                  onChange={setNewPass}
                  invalid={!isValidPassword && newPass !== ""}
                />
                <p style={{ fontSize: "0.7rem", marginTop: "0.3rem" }}>At least 12 characters</p>
              </div>
              <div>
                <p style={{ fontSize: "0.6rem", fontWeight: "700", marginBottom: "0.3rem" }}>Confirm Password</p>
                <InputField
                  placeholder={"Confirm Password"}
                  type="password"
                  onChange={setConfirmPassword}
                  invalid={newPass !== confirmPassword && confirmPassword !== ""}
                />
              </div>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                disabled={!isPasswordConfirmed || loadingContext.isLoading}
                onClick={resetPasswordConfirm}
              >
                {loadingContext.isLoading ? "Loading..." : "Reset Password"}
              </Button>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}
