import React, { useContext, useEffect, useState } from "react"
import { Button, Stack, Skeleton } from "@mui/material"

import LocalLoadingBar from "../../items/LocalLoadingBar"
import { appService } from "../../../api/services"
import request from "../../../api/axios"
import { SuccessContext, ErrorContext } from "../../../helper/AlertContext"
import MessageBox from "../../items/MessageBox"
import SelectField from "../../items/SelectField"

import ActionDrawerStyles from "./ActionDrawer.module.css"

const DiscordConfigDrawer = () => {
  const errorContext = useContext(ErrorContext)
  const successContext = useContext(SuccessContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingForm, setIsLoadingForm] = useState(false)
  const [apps, setApps] = useState([])
  const [discordUsername, setDiscordUsername] = useState("")
  const [discordEmail, setDiscordEmail] = useState("")
  const [discordServers, setDiscordServers] = useState([])
  const [selectedProject, setSelectedProject] = useState('')
  const [selectedServer, setSelectedServer] = useState('')
  const [initialServer, setInitialServer] = useState("")
  const [initialProject, setInitialProject] = useState("")

  const authUrl = `https://discord.com/oauth2/authorize?client_id=${encodeURIComponent(
    process.env.REACT_APP_DISCORD_CLIENT_ID,
  )}&permissions=2147486720&scope=bot%20applications.commands&guild_id=${selectedServer}`

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      let serverOptions = [] 
      try {
        const appResponse = await appService.getProjectList()
        setApps(appResponse.data.results)

        const userResponse = await request.get("third_party/discord/me", {})
        setDiscordUsername(userResponse.data.text.username)
        setDiscordEmail(userResponse.data.text.email)

        const guildResponse = await request.get("third_party/discord/guilds", {})
        if (guildResponse.data && guildResponse.data.text) {
          const ownedGuilds = guildResponse.data.text.filter((guild) => guild.owner)
          serverOptions = ownedGuilds.map((guild) => ({
            id: guild.id,
            name: guild.name,
          }))
          setDiscordServers(serverOptions)

          if (!serverOptions.find((server) => server.id === selectedServer)) {
            setSelectedServer(serverOptions.length > 0 ? serverOptions[0].id : "")
          }
        } else {
          setDiscordServers([])
        }

        const bindingResponse = await request.get("third_party/discord/binding_data", {})
        if (bindingResponse.data && bindingResponse.data.text) {
          if (serverOptions.find((server) => server.id === bindingResponse.data.text.discord_server_id)) {
            setSelectedServer(bindingResponse.data.text.discord_server_id)
            setInitialServer(bindingResponse.data.text.discord_server_id)
          } else if (serverOptions.length > 0) {
            setSelectedServer("") 
          }
          if (appResponse.data.results.find((app) => app.id === bindingResponse.data.text.vext_app_id)) {
            setSelectedProject(bindingResponse.data.text.vext_app_id)
            setInitialProject(bindingResponse.data.text.vext_app_id)
          } else if (appResponse.data.results.length > 0) {
            setSelectedProject("")
          }
        }
      } catch (error) {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])
  
  

  const handleChangeProject = (event) => {
    setSelectedProject(event.target.value)
  }

  const handleChangeServer = (event) => {
    setSelectedServer(event.target.value)
    setSelectedProject("")
  }

  const handleSaveClick = async () => {
    setIsLoadingForm(true)
    try {
      const payload = {
        discord_server_id: selectedServer,
        discord_account: discordEmail,
        vext_app_id: selectedProject,
      }
      const response = await request.post("third_party/discord/binding_data", payload)

      setInitialServer(selectedServer)
      setInitialProject(selectedProject)
      successContext.setSuccess(true)
      successContext.setSuccessMsg("Your AI Project has been successfully assigned to your server.")
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      setIsLoadingForm(false)
    }
  }

  const handleAddBot = () => {
    window.open(authUrl, "_blank", "toolbar=no, menubar=no, width=500, height=700, top=100, left=100")
  }

  const canSave =
    selectedServer &&
    selectedProject &&
    discordEmail &&
    !isLoading &&
    !isLoadingForm &&
    (selectedServer !== initialServer || selectedProject !== initialProject)

  return (
    <>
      <section style={{ position: "relative" }}>
        <LocalLoadingBar localLoading={isLoading || isLoadingForm} />
      </section>
      {isLoading ? (
        <>
          <Stack spacing={2} sx={{ padding: "1.5rem 2rem" }}>
            <Skeleton variant="rounded" animation="wave" height={50} />
            <Skeleton variant="rounded" animation="wave" height={50} />
            <Skeleton variant="rounded" animation="wave" height={50} />
          </Stack>
        </>
      ) : (
        <>
          <Button
            sx={{ position: "absolute", top: "1.25rem", right: "1.5rem", zIndex: 2 }}
            variant="contained"
            disabled={!canSave}
            onClick={handleSaveClick}
          >
            Save
          </Button>
          <section style={{ position: "relative" }}>
            <LocalLoadingBar localLoading={isLoading || isLoadingForm} />
          </section>
          <div className={ActionDrawerStyles.main}>
            <div className={ActionDrawerStyles.flex}>
              <h4>Discord Username</h4>
              <span style={{ fontWeight: "700", fontStyle: "italic" }}>{discordUsername}</span>
            </div>
            <div className={ActionDrawerStyles.flex}>
              <h4>Discord Server</h4>
              <SelectField
                options={discordServers.map((server) => ({
                  value: server.id,
                  label: server.name,
                }))}
                value={selectedServer}
                onChange={handleChangeServer}
                disabled={isLoading || isLoadingForm}
              />
            </div>
            <div className={ActionDrawerStyles.flex}>
              <h4>Vext AI Project</h4>
              <div style={{ display: "flex", gap: "1rem" }}>
                <SelectField
                  options={apps.map((app) => ({
                    value: app.id,
                    label: app.name,
                  }))}
                  value={selectedProject}
                  divStyle={{ width: "100%" }}
                  onChange={handleChangeProject}
                  disabled={isLoading || isLoadingForm || !selectedServer}
                />
                <Button
                  variant="outlined"
                  style={{ backgroundColor: "#fff", whiteSpace: "nowrap", minWidth: "fit-content" }}
                  onClick={handleAddBot}
                  disabled={isLoading || isLoadingForm || !selectedServer}
                >
                  Add Bot
                </Button>
              </div>
            </div>
            <MessageBox
              message={
                "To complete the full setup process, you will need to perform two actions: First, click 'Add Bot' to add the bot to the designated server. Then, click 'Save' to confirm the binding of your project with the selected Discord server."
              }
            />
          </div>
        </>
      )}
    </>
  )
}

export default DiscordConfigDrawer
